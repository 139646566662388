// source: proto/air_hotel_reservation_search/response.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var proto_commons_flow_common_pb = require('../../proto/commons/flow_common_pb.js');
goog.object.extend(proto, proto_commons_flow_common_pb);
var proto_commons_status_pb = require('../../proto/commons/status_pb.js');
goog.object.extend(proto, proto_commons_status_pb);
var proto_commons_session_info_pb = require('../../proto/commons/session_info_pb.js');
goog.object.extend(proto, proto_commons_session_info_pb);
var proto_commons_auth_pb = require('../../proto/commons/auth_pb.js');
goog.object.extend(proto, proto_commons_auth_pb);
var proto_commons_timestamp_pb = require('../../proto/commons/timestamp_pb.js');
goog.object.extend(proto, proto_commons_timestamp_pb);
var proto_commons_enum_pb = require('../../proto/commons/enum_pb.js');
goog.object.extend(proto, proto_commons_enum_pb);
var proto_models_city_pb = require('../../proto/models/city_pb.js');
goog.object.extend(proto, proto_models_city_pb);
var proto_models_location_pb = require('../../proto/models/location_pb.js');
goog.object.extend(proto, proto_models_location_pb);
goog.exportSymbol('proto.elude.proto.AbandonTripResponse', null, global);
goog.exportSymbol('proto.elude.proto.AveragePriceResponse', null, global);
goog.exportSymbol('proto.elude.proto.BuildHotelResponse', null, global);
goog.exportSymbol('proto.elude.proto.CancelResponse', null, global);
goog.exportSymbol('proto.elude.proto.GetAbandonTripSearchResponse', null, global);
goog.exportSymbol('proto.elude.proto.GetPriceResponse', null, global);
goog.exportSymbol('proto.elude.proto.HotelDetailsResponse', null, global);
goog.exportSymbol('proto.elude.proto.ItineraryResponse', null, global);
goog.exportSymbol('proto.elude.proto.ReservationResponse', null, global);
goog.exportSymbol('proto.elude.proto.RetrieveItineraryPriceResponse', null, global);
goog.exportSymbol('proto.elude.proto.RetrieveResponse', null, global);
goog.exportSymbol('proto.elude.proto.RevalidateItineraryResponse', null, global);
goog.exportSymbol('proto.elude.proto.SetSearchCacheResponse', null, global);
goog.exportSymbol('proto.elude.proto.TicketResponse', null, global);
goog.exportSymbol('proto.elude.proto.VoidTicketResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.ItineraryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.ItineraryResponse.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.ItineraryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.ItineraryResponse.displayName = 'proto.elude.proto.ItineraryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.ReservationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.ReservationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.ReservationResponse.displayName = 'proto.elude.proto.ReservationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.CancelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.CancelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.CancelResponse.displayName = 'proto.elude.proto.CancelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.TicketResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.TicketResponse.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.TicketResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.TicketResponse.displayName = 'proto.elude.proto.TicketResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.VoidTicketResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.VoidTicketResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.VoidTicketResponse.displayName = 'proto.elude.proto.VoidTicketResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetPriceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetPriceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetPriceResponse.displayName = 'proto.elude.proto.GetPriceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.RetrieveResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.RetrieveResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.RetrieveResponse.displayName = 'proto.elude.proto.RetrieveResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetAbandonTripSearchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetAbandonTripSearchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetAbandonTripSearchResponse.displayName = 'proto.elude.proto.GetAbandonTripSearchResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.RevalidateItineraryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.RevalidateItineraryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.RevalidateItineraryResponse.displayName = 'proto.elude.proto.RevalidateItineraryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.RetrieveItineraryPriceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.RetrieveItineraryPriceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.RetrieveItineraryPriceResponse.displayName = 'proto.elude.proto.RetrieveItineraryPriceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.HotelDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.HotelDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.HotelDetailsResponse.displayName = 'proto.elude.proto.HotelDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.AbandonTripResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.AbandonTripResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.AbandonTripResponse.displayName = 'proto.elude.proto.AbandonTripResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.BuildHotelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.BuildHotelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.BuildHotelResponse.displayName = 'proto.elude.proto.BuildHotelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.SetSearchCacheResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.SetSearchCacheResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.SetSearchCacheResponse.displayName = 'proto.elude.proto.SetSearchCacheResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.AveragePriceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.AveragePriceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.AveragePriceResponse.displayName = 'proto.elude.proto.AveragePriceResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.ItineraryResponse.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.ItineraryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.ItineraryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.ItineraryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.ItineraryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    departureLocation: (f = msg.getDepartureLocation()) && proto_models_location_pb.Location.toObject(includeInstance, f),
    arrivalLocation: (f = msg.getArrivalLocation()) && proto_models_location_pb.Location.toObject(includeInstance, f),
    flightOptionsList: jspb.Message.toObjectList(msg.getFlightOptionsList(),
    proto_commons_flow_common_pb.FlightOption.toObject, includeInstance),
    hotelsList: jspb.Message.toObjectList(msg.getHotelsList(),
    proto_commons_flow_common_pb.GDSHotel.toObject, includeInstance),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f),
    hotelSearchType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    airResponse: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    isRecommendedTrip: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.ItineraryResponse}
 */
proto.elude.proto.ItineraryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.ItineraryResponse;
  return proto.elude.proto.ItineraryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.ItineraryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.ItineraryResponse}
 */
proto.elude.proto.ItineraryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_models_location_pb.Location;
      reader.readMessage(value,proto_models_location_pb.Location.deserializeBinaryFromReader);
      msg.setDepartureLocation(value);
      break;
    case 3:
      var value = new proto_models_location_pb.Location;
      reader.readMessage(value,proto_models_location_pb.Location.deserializeBinaryFromReader);
      msg.setArrivalLocation(value);
      break;
    case 4:
      var value = new proto_commons_flow_common_pb.FlightOption;
      reader.readMessage(value,proto_commons_flow_common_pb.FlightOption.deserializeBinaryFromReader);
      msg.addFlightOptions(value);
      break;
    case 5:
      var value = new proto_commons_flow_common_pb.GDSHotel;
      reader.readMessage(value,proto_commons_flow_common_pb.GDSHotel.deserializeBinaryFromReader);
      msg.addHotels(value);
      break;
    case 6:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {!proto.elude.proto.HotelSearchTypeEnum} */ (reader.readEnum());
      msg.setHotelSearchType(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAirResponse(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRecommendedTrip(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.ItineraryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.ItineraryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.ItineraryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.ItineraryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getDepartureLocation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_models_location_pb.Location.serializeBinaryToWriter
    );
  }
  f = message.getArrivalLocation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_location_pb.Location.serializeBinaryToWriter
    );
  }
  f = message.getFlightOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto_commons_flow_common_pb.FlightOption.serializeBinaryToWriter
    );
  }
  f = message.getHotelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto_commons_flow_common_pb.GDSHotel.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getHotelSearchType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getAirResponse();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getIsRecommendedTrip();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.ItineraryResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.ItineraryResponse} returns this
*/
proto.elude.proto.ItineraryResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ItineraryResponse} returns this
 */
proto.elude.proto.ItineraryResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ItineraryResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Location departure_location = 2;
 * @return {?proto.elude.proto.Location}
 */
proto.elude.proto.ItineraryResponse.prototype.getDepartureLocation = function() {
  return /** @type{?proto.elude.proto.Location} */ (
    jspb.Message.getWrapperField(this, proto_models_location_pb.Location, 2));
};


/**
 * @param {?proto.elude.proto.Location|undefined} value
 * @return {!proto.elude.proto.ItineraryResponse} returns this
*/
proto.elude.proto.ItineraryResponse.prototype.setDepartureLocation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ItineraryResponse} returns this
 */
proto.elude.proto.ItineraryResponse.prototype.clearDepartureLocation = function() {
  return this.setDepartureLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ItineraryResponse.prototype.hasDepartureLocation = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Location arrival_location = 3;
 * @return {?proto.elude.proto.Location}
 */
proto.elude.proto.ItineraryResponse.prototype.getArrivalLocation = function() {
  return /** @type{?proto.elude.proto.Location} */ (
    jspb.Message.getWrapperField(this, proto_models_location_pb.Location, 3));
};


/**
 * @param {?proto.elude.proto.Location|undefined} value
 * @return {!proto.elude.proto.ItineraryResponse} returns this
*/
proto.elude.proto.ItineraryResponse.prototype.setArrivalLocation = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ItineraryResponse} returns this
 */
proto.elude.proto.ItineraryResponse.prototype.clearArrivalLocation = function() {
  return this.setArrivalLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ItineraryResponse.prototype.hasArrivalLocation = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated FlightOption flight_options = 4;
 * @return {!Array<!proto.elude.proto.FlightOption>}
 */
proto.elude.proto.ItineraryResponse.prototype.getFlightOptionsList = function() {
  return /** @type{!Array<!proto.elude.proto.FlightOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_commons_flow_common_pb.FlightOption, 4));
};


/**
 * @param {!Array<!proto.elude.proto.FlightOption>} value
 * @return {!proto.elude.proto.ItineraryResponse} returns this
*/
proto.elude.proto.ItineraryResponse.prototype.setFlightOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.elude.proto.FlightOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.FlightOption}
 */
proto.elude.proto.ItineraryResponse.prototype.addFlightOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.elude.proto.FlightOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.ItineraryResponse} returns this
 */
proto.elude.proto.ItineraryResponse.prototype.clearFlightOptionsList = function() {
  return this.setFlightOptionsList([]);
};


/**
 * repeated GDSHotel hotels = 5;
 * @return {!Array<!proto.elude.proto.GDSHotel>}
 */
proto.elude.proto.ItineraryResponse.prototype.getHotelsList = function() {
  return /** @type{!Array<!proto.elude.proto.GDSHotel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_commons_flow_common_pb.GDSHotel, 5));
};


/**
 * @param {!Array<!proto.elude.proto.GDSHotel>} value
 * @return {!proto.elude.proto.ItineraryResponse} returns this
*/
proto.elude.proto.ItineraryResponse.prototype.setHotelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.elude.proto.GDSHotel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.GDSHotel}
 */
proto.elude.proto.ItineraryResponse.prototype.addHotels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.elude.proto.GDSHotel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.ItineraryResponse} returns this
 */
proto.elude.proto.ItineraryResponse.prototype.clearHotelsList = function() {
  return this.setHotelsList([]);
};


/**
 * optional Status status = 6;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.ItineraryResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 6));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.ItineraryResponse} returns this
*/
proto.elude.proto.ItineraryResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ItineraryResponse} returns this
 */
proto.elude.proto.ItineraryResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ItineraryResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional HotelSearchTypeEnum hotel_search_type = 7;
 * @return {!proto.elude.proto.HotelSearchTypeEnum}
 */
proto.elude.proto.ItineraryResponse.prototype.getHotelSearchType = function() {
  return /** @type {!proto.elude.proto.HotelSearchTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.elude.proto.HotelSearchTypeEnum} value
 * @return {!proto.elude.proto.ItineraryResponse} returns this
 */
proto.elude.proto.ItineraryResponse.prototype.setHotelSearchType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional bool air_response = 8;
 * @return {boolean}
 */
proto.elude.proto.ItineraryResponse.prototype.getAirResponse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.elude.proto.ItineraryResponse} returns this
 */
proto.elude.proto.ItineraryResponse.prototype.setAirResponse = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool is_recommended_trip = 9;
 * @return {boolean}
 */
proto.elude.proto.ItineraryResponse.prototype.getIsRecommendedTrip = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.elude.proto.ItineraryResponse} returns this
 */
proto.elude.proto.ItineraryResponse.prototype.setIsRecommendedTrip = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.ReservationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.ReservationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.ReservationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.ReservationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    bookingId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    hotelReservationIdentifier: jspb.Message.getFieldWithDefault(msg, 3, ""),
    airReservationIdentifier: jspb.Message.getFieldWithDefault(msg, 4, ""),
    itinerary: (f = msg.getItinerary()) && proto_commons_flow_common_pb.GDSItinerary.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f),
    eludeReference: jspb.Message.getFieldWithDefault(msg, 7, ""),
    airlineReservationIdentifier: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.ReservationResponse}
 */
proto.elude.proto.ReservationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.ReservationResponse;
  return proto.elude.proto.ReservationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.ReservationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.ReservationResponse}
 */
proto.elude.proto.ReservationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBookingId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelReservationIdentifier(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAirReservationIdentifier(value);
      break;
    case 5:
      var value = new proto_commons_flow_common_pb.GDSItinerary;
      reader.readMessage(value,proto_commons_flow_common_pb.GDSItinerary.deserializeBinaryFromReader);
      msg.setItinerary(value);
      break;
    case 6:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEludeReference(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAirlineReservationIdentifier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.ReservationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.ReservationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.ReservationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.ReservationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getBookingId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getHotelReservationIdentifier();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAirReservationIdentifier();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getItinerary();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_commons_flow_common_pb.GDSItinerary.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getEludeReference();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAirlineReservationIdentifier();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.ReservationResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.ReservationResponse} returns this
*/
proto.elude.proto.ReservationResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ReservationResponse} returns this
 */
proto.elude.proto.ReservationResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ReservationResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 booking_id = 2;
 * @return {number}
 */
proto.elude.proto.ReservationResponse.prototype.getBookingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.ReservationResponse} returns this
 */
proto.elude.proto.ReservationResponse.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string hotel_reservation_identifier = 3;
 * @return {string}
 */
proto.elude.proto.ReservationResponse.prototype.getHotelReservationIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.ReservationResponse} returns this
 */
proto.elude.proto.ReservationResponse.prototype.setHotelReservationIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string air_reservation_identifier = 4;
 * @return {string}
 */
proto.elude.proto.ReservationResponse.prototype.getAirReservationIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.ReservationResponse} returns this
 */
proto.elude.proto.ReservationResponse.prototype.setAirReservationIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional GDSItinerary itinerary = 5;
 * @return {?proto.elude.proto.GDSItinerary}
 */
proto.elude.proto.ReservationResponse.prototype.getItinerary = function() {
  return /** @type{?proto.elude.proto.GDSItinerary} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.GDSItinerary, 5));
};


/**
 * @param {?proto.elude.proto.GDSItinerary|undefined} value
 * @return {!proto.elude.proto.ReservationResponse} returns this
*/
proto.elude.proto.ReservationResponse.prototype.setItinerary = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ReservationResponse} returns this
 */
proto.elude.proto.ReservationResponse.prototype.clearItinerary = function() {
  return this.setItinerary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ReservationResponse.prototype.hasItinerary = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Status status = 6;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.ReservationResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 6));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.ReservationResponse} returns this
*/
proto.elude.proto.ReservationResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ReservationResponse} returns this
 */
proto.elude.proto.ReservationResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ReservationResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string elude_reference = 7;
 * @return {string}
 */
proto.elude.proto.ReservationResponse.prototype.getEludeReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.ReservationResponse} returns this
 */
proto.elude.proto.ReservationResponse.prototype.setEludeReference = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string airline_reservation_identifier = 8;
 * @return {string}
 */
proto.elude.proto.ReservationResponse.prototype.getAirlineReservationIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.ReservationResponse} returns this
 */
proto.elude.proto.ReservationResponse.prototype.setAirlineReservationIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.CancelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.CancelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.CancelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CancelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    bookingId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.CancelResponse}
 */
proto.elude.proto.CancelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.CancelResponse;
  return proto.elude.proto.CancelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.CancelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.CancelResponse}
 */
proto.elude.proto.CancelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBookingId(value);
      break;
    case 5:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.CancelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.CancelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.CancelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CancelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getBookingId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.CancelResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.CancelResponse} returns this
*/
proto.elude.proto.CancelResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CancelResponse} returns this
 */
proto.elude.proto.CancelResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CancelResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 booking_id = 2;
 * @return {number}
 */
proto.elude.proto.CancelResponse.prototype.getBookingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.CancelResponse} returns this
 */
proto.elude.proto.CancelResponse.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Status status = 5;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.CancelResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 5));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.CancelResponse} returns this
*/
proto.elude.proto.CancelResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CancelResponse} returns this
 */
proto.elude.proto.CancelResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CancelResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.TicketResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.TicketResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.TicketResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.TicketResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.TicketResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    price: (f = msg.getPrice()) && proto_commons_flow_common_pb.Price.toObject(includeInstance, f),
    issuedAtDatetime: (f = msg.getIssuedAtDatetime()) && proto_commons_timestamp_pb.Timestamp.toObject(includeInstance, f),
    ticketInfosList: jspb.Message.toObjectList(msg.getTicketInfosList(),
    proto_commons_flow_common_pb.TicketInfo.toObject, includeInstance),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.TicketResponse}
 */
proto.elude.proto.TicketResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.TicketResponse;
  return proto.elude.proto.TicketResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.TicketResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.TicketResponse}
 */
proto.elude.proto.TicketResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_commons_flow_common_pb.Price;
      reader.readMessage(value,proto_commons_flow_common_pb.Price.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    case 3:
      var value = new proto_commons_timestamp_pb.Timestamp;
      reader.readMessage(value,proto_commons_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setIssuedAtDatetime(value);
      break;
    case 4:
      var value = new proto_commons_flow_common_pb.TicketInfo;
      reader.readMessage(value,proto_commons_flow_common_pb.TicketInfo.deserializeBinaryFromReader);
      msg.addTicketInfos(value);
      break;
    case 5:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.TicketResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.TicketResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.TicketResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.TicketResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_flow_common_pb.Price.serializeBinaryToWriter
    );
  }
  f = message.getIssuedAtDatetime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTicketInfosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto_commons_flow_common_pb.TicketInfo.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.TicketResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.TicketResponse} returns this
*/
proto.elude.proto.TicketResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.TicketResponse} returns this
 */
proto.elude.proto.TicketResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.TicketResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Price price = 2;
 * @return {?proto.elude.proto.Price}
 */
proto.elude.proto.TicketResponse.prototype.getPrice = function() {
  return /** @type{?proto.elude.proto.Price} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.Price, 2));
};


/**
 * @param {?proto.elude.proto.Price|undefined} value
 * @return {!proto.elude.proto.TicketResponse} returns this
*/
proto.elude.proto.TicketResponse.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.TicketResponse} returns this
 */
proto.elude.proto.TicketResponse.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.TicketResponse.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Timestamp issued_at_datetime = 3;
 * @return {?proto.elude.proto.Timestamp}
 */
proto.elude.proto.TicketResponse.prototype.getIssuedAtDatetime = function() {
  return /** @type{?proto.elude.proto.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto_commons_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.elude.proto.Timestamp|undefined} value
 * @return {!proto.elude.proto.TicketResponse} returns this
*/
proto.elude.proto.TicketResponse.prototype.setIssuedAtDatetime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.TicketResponse} returns this
 */
proto.elude.proto.TicketResponse.prototype.clearIssuedAtDatetime = function() {
  return this.setIssuedAtDatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.TicketResponse.prototype.hasIssuedAtDatetime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated TicketInfo ticket_infos = 4;
 * @return {!Array<!proto.elude.proto.TicketInfo>}
 */
proto.elude.proto.TicketResponse.prototype.getTicketInfosList = function() {
  return /** @type{!Array<!proto.elude.proto.TicketInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_commons_flow_common_pb.TicketInfo, 4));
};


/**
 * @param {!Array<!proto.elude.proto.TicketInfo>} value
 * @return {!proto.elude.proto.TicketResponse} returns this
*/
proto.elude.proto.TicketResponse.prototype.setTicketInfosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.elude.proto.TicketInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.TicketInfo}
 */
proto.elude.proto.TicketResponse.prototype.addTicketInfos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.elude.proto.TicketInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.TicketResponse} returns this
 */
proto.elude.proto.TicketResponse.prototype.clearTicketInfosList = function() {
  return this.setTicketInfosList([]);
};


/**
 * optional Status status = 5;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.TicketResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 5));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.TicketResponse} returns this
*/
proto.elude.proto.TicketResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.TicketResponse} returns this
 */
proto.elude.proto.TicketResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.TicketResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.VoidTicketResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.VoidTicketResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.VoidTicketResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.VoidTicketResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    cancelAtDatetime: (f = msg.getCancelAtDatetime()) && proto_commons_timestamp_pb.Timestamp.toObject(includeInstance, f),
    documentNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.VoidTicketResponse}
 */
proto.elude.proto.VoidTicketResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.VoidTicketResponse;
  return proto.elude.proto.VoidTicketResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.VoidTicketResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.VoidTicketResponse}
 */
proto.elude.proto.VoidTicketResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_commons_timestamp_pb.Timestamp;
      reader.readMessage(value,proto_commons_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCancelAtDatetime(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentNumber(value);
      break;
    case 4:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.VoidTicketResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.VoidTicketResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.VoidTicketResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.VoidTicketResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getCancelAtDatetime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDocumentNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.VoidTicketResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.VoidTicketResponse} returns this
*/
proto.elude.proto.VoidTicketResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.VoidTicketResponse} returns this
 */
proto.elude.proto.VoidTicketResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.VoidTicketResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Timestamp cancel_at_datetime = 2;
 * @return {?proto.elude.proto.Timestamp}
 */
proto.elude.proto.VoidTicketResponse.prototype.getCancelAtDatetime = function() {
  return /** @type{?proto.elude.proto.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto_commons_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.elude.proto.Timestamp|undefined} value
 * @return {!proto.elude.proto.VoidTicketResponse} returns this
*/
proto.elude.proto.VoidTicketResponse.prototype.setCancelAtDatetime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.VoidTicketResponse} returns this
 */
proto.elude.proto.VoidTicketResponse.prototype.clearCancelAtDatetime = function() {
  return this.setCancelAtDatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.VoidTicketResponse.prototype.hasCancelAtDatetime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string document_number = 3;
 * @return {string}
 */
proto.elude.proto.VoidTicketResponse.prototype.getDocumentNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.VoidTicketResponse} returns this
 */
proto.elude.proto.VoidTicketResponse.prototype.setDocumentNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Status status = 4;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.VoidTicketResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 4));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.VoidTicketResponse} returns this
*/
proto.elude.proto.VoidTicketResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.VoidTicketResponse} returns this
 */
proto.elude.proto.VoidTicketResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.VoidTicketResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetPriceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetPriceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetPriceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetPriceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    hotelPrice: (f = msg.getHotelPrice()) && proto_commons_flow_common_pb.Price.toObject(includeInstance, f),
    flightPrice: (f = msg.getFlightPrice()) && proto_commons_flow_common_pb.Price.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetPriceResponse}
 */
proto.elude.proto.GetPriceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetPriceResponse;
  return proto.elude.proto.GetPriceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetPriceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetPriceResponse}
 */
proto.elude.proto.GetPriceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_flow_common_pb.Price;
      reader.readMessage(value,proto_commons_flow_common_pb.Price.deserializeBinaryFromReader);
      msg.setHotelPrice(value);
      break;
    case 4:
      var value = new proto_commons_flow_common_pb.Price;
      reader.readMessage(value,proto_commons_flow_common_pb.Price.deserializeBinaryFromReader);
      msg.setFlightPrice(value);
      break;
    case 5:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetPriceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetPriceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetPriceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetPriceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getHotelPrice();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_flow_common_pb.Price.serializeBinaryToWriter
    );
  }
  f = message.getFlightPrice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_flow_common_pb.Price.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetPriceResponse.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetPriceResponse} returns this
*/
proto.elude.proto.GetPriceResponse.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetPriceResponse} returns this
 */
proto.elude.proto.GetPriceResponse.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetPriceResponse.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetPriceResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetPriceResponse} returns this
*/
proto.elude.proto.GetPriceResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetPriceResponse} returns this
 */
proto.elude.proto.GetPriceResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetPriceResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Price hotel_price = 3;
 * @return {?proto.elude.proto.Price}
 */
proto.elude.proto.GetPriceResponse.prototype.getHotelPrice = function() {
  return /** @type{?proto.elude.proto.Price} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.Price, 3));
};


/**
 * @param {?proto.elude.proto.Price|undefined} value
 * @return {!proto.elude.proto.GetPriceResponse} returns this
*/
proto.elude.proto.GetPriceResponse.prototype.setHotelPrice = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetPriceResponse} returns this
 */
proto.elude.proto.GetPriceResponse.prototype.clearHotelPrice = function() {
  return this.setHotelPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetPriceResponse.prototype.hasHotelPrice = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Price flight_price = 4;
 * @return {?proto.elude.proto.Price}
 */
proto.elude.proto.GetPriceResponse.prototype.getFlightPrice = function() {
  return /** @type{?proto.elude.proto.Price} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.Price, 4));
};


/**
 * @param {?proto.elude.proto.Price|undefined} value
 * @return {!proto.elude.proto.GetPriceResponse} returns this
*/
proto.elude.proto.GetPriceResponse.prototype.setFlightPrice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetPriceResponse} returns this
 */
proto.elude.proto.GetPriceResponse.prototype.clearFlightPrice = function() {
  return this.setFlightPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetPriceResponse.prototype.hasFlightPrice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Status status = 5;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.GetPriceResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 5));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.GetPriceResponse} returns this
*/
proto.elude.proto.GetPriceResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetPriceResponse} returns this
 */
proto.elude.proto.GetPriceResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetPriceResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.RetrieveResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.RetrieveResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.RetrieveResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.RetrieveResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    bookingId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    itinerary: (f = msg.getItinerary()) && proto_commons_flow_common_pb.GDSItinerary.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.RetrieveResponse}
 */
proto.elude.proto.RetrieveResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.RetrieveResponse;
  return proto.elude.proto.RetrieveResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.RetrieveResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.RetrieveResponse}
 */
proto.elude.proto.RetrieveResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBookingId(value);
      break;
    case 3:
      var value = new proto_commons_flow_common_pb.GDSItinerary;
      reader.readMessage(value,proto_commons_flow_common_pb.GDSItinerary.deserializeBinaryFromReader);
      msg.setItinerary(value);
      break;
    case 4:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.RetrieveResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.RetrieveResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.RetrieveResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.RetrieveResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getBookingId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getItinerary();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_flow_common_pb.GDSItinerary.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.RetrieveResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.RetrieveResponse} returns this
*/
proto.elude.proto.RetrieveResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RetrieveResponse} returns this
 */
proto.elude.proto.RetrieveResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RetrieveResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 booking_id = 2;
 * @return {number}
 */
proto.elude.proto.RetrieveResponse.prototype.getBookingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.RetrieveResponse} returns this
 */
proto.elude.proto.RetrieveResponse.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional GDSItinerary itinerary = 3;
 * @return {?proto.elude.proto.GDSItinerary}
 */
proto.elude.proto.RetrieveResponse.prototype.getItinerary = function() {
  return /** @type{?proto.elude.proto.GDSItinerary} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.GDSItinerary, 3));
};


/**
 * @param {?proto.elude.proto.GDSItinerary|undefined} value
 * @return {!proto.elude.proto.RetrieveResponse} returns this
*/
proto.elude.proto.RetrieveResponse.prototype.setItinerary = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RetrieveResponse} returns this
 */
proto.elude.proto.RetrieveResponse.prototype.clearItinerary = function() {
  return this.setItinerary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RetrieveResponse.prototype.hasItinerary = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Status status = 4;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.RetrieveResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 4));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.RetrieveResponse} returns this
*/
proto.elude.proto.RetrieveResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RetrieveResponse} returns this
 */
proto.elude.proto.RetrieveResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RetrieveResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetAbandonTripSearchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetAbandonTripSearchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetAbandonTripSearchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetAbandonTripSearchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    isCached: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    itineraryResponse: (f = msg.getItineraryResponse()) && proto.elude.proto.ItineraryResponse.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetAbandonTripSearchResponse}
 */
proto.elude.proto.GetAbandonTripSearchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetAbandonTripSearchResponse;
  return proto.elude.proto.GetAbandonTripSearchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetAbandonTripSearchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetAbandonTripSearchResponse}
 */
proto.elude.proto.GetAbandonTripSearchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCached(value);
      break;
    case 3:
      var value = new proto.elude.proto.ItineraryResponse;
      reader.readMessage(value,proto.elude.proto.ItineraryResponse.deserializeBinaryFromReader);
      msg.setItineraryResponse(value);
      break;
    case 4:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetAbandonTripSearchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetAbandonTripSearchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetAbandonTripSearchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetAbandonTripSearchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getIsCached();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getItineraryResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.elude.proto.ItineraryResponse.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetAbandonTripSearchResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetAbandonTripSearchResponse} returns this
*/
proto.elude.proto.GetAbandonTripSearchResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAbandonTripSearchResponse} returns this
 */
proto.elude.proto.GetAbandonTripSearchResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAbandonTripSearchResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool is_cached = 2;
 * @return {boolean}
 */
proto.elude.proto.GetAbandonTripSearchResponse.prototype.getIsCached = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.elude.proto.GetAbandonTripSearchResponse} returns this
 */
proto.elude.proto.GetAbandonTripSearchResponse.prototype.setIsCached = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional ItineraryResponse itinerary_response = 3;
 * @return {?proto.elude.proto.ItineraryResponse}
 */
proto.elude.proto.GetAbandonTripSearchResponse.prototype.getItineraryResponse = function() {
  return /** @type{?proto.elude.proto.ItineraryResponse} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.ItineraryResponse, 3));
};


/**
 * @param {?proto.elude.proto.ItineraryResponse|undefined} value
 * @return {!proto.elude.proto.GetAbandonTripSearchResponse} returns this
*/
proto.elude.proto.GetAbandonTripSearchResponse.prototype.setItineraryResponse = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAbandonTripSearchResponse} returns this
 */
proto.elude.proto.GetAbandonTripSearchResponse.prototype.clearItineraryResponse = function() {
  return this.setItineraryResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAbandonTripSearchResponse.prototype.hasItineraryResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Status status = 4;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.GetAbandonTripSearchResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 4));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.GetAbandonTripSearchResponse} returns this
*/
proto.elude.proto.GetAbandonTripSearchResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAbandonTripSearchResponse} returns this
 */
proto.elude.proto.GetAbandonTripSearchResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAbandonTripSearchResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.RevalidateItineraryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.RevalidateItineraryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.RevalidateItineraryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.RevalidateItineraryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    flightPriceDifference: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    hotelPriceDifference: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f),
    price: (f = msg.getPrice()) && proto_commons_flow_common_pb.Price.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.RevalidateItineraryResponse}
 */
proto.elude.proto.RevalidateItineraryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.RevalidateItineraryResponse;
  return proto.elude.proto.RevalidateItineraryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.RevalidateItineraryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.RevalidateItineraryResponse}
 */
proto.elude.proto.RevalidateItineraryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFlightPriceDifference(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHotelPriceDifference(value);
      break;
    case 4:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 5:
      var value = new proto_commons_flow_common_pb.Price;
      reader.readMessage(value,proto_commons_flow_common_pb.Price.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.RevalidateItineraryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.RevalidateItineraryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.RevalidateItineraryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.RevalidateItineraryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getFlightPriceDifference();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getHotelPriceDifference();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_commons_flow_common_pb.Price.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.RevalidateItineraryResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.RevalidateItineraryResponse} returns this
*/
proto.elude.proto.RevalidateItineraryResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RevalidateItineraryResponse} returns this
 */
proto.elude.proto.RevalidateItineraryResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RevalidateItineraryResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional float flight_price_difference = 2;
 * @return {number}
 */
proto.elude.proto.RevalidateItineraryResponse.prototype.getFlightPriceDifference = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.RevalidateItineraryResponse} returns this
 */
proto.elude.proto.RevalidateItineraryResponse.prototype.setFlightPriceDifference = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float hotel_price_difference = 3;
 * @return {number}
 */
proto.elude.proto.RevalidateItineraryResponse.prototype.getHotelPriceDifference = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.RevalidateItineraryResponse} returns this
 */
proto.elude.proto.RevalidateItineraryResponse.prototype.setHotelPriceDifference = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional Status status = 4;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.RevalidateItineraryResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 4));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.RevalidateItineraryResponse} returns this
*/
proto.elude.proto.RevalidateItineraryResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RevalidateItineraryResponse} returns this
 */
proto.elude.proto.RevalidateItineraryResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RevalidateItineraryResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Price price = 5;
 * @return {?proto.elude.proto.Price}
 */
proto.elude.proto.RevalidateItineraryResponse.prototype.getPrice = function() {
  return /** @type{?proto.elude.proto.Price} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.Price, 5));
};


/**
 * @param {?proto.elude.proto.Price|undefined} value
 * @return {!proto.elude.proto.RevalidateItineraryResponse} returns this
*/
proto.elude.proto.RevalidateItineraryResponse.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RevalidateItineraryResponse} returns this
 */
proto.elude.proto.RevalidateItineraryResponse.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RevalidateItineraryResponse.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.RetrieveItineraryPriceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.RetrieveItineraryPriceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.RetrieveItineraryPriceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.RetrieveItineraryPriceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f),
    price: (f = msg.getPrice()) && proto_commons_flow_common_pb.Price.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.RetrieveItineraryPriceResponse}
 */
proto.elude.proto.RetrieveItineraryPriceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.RetrieveItineraryPriceResponse;
  return proto.elude.proto.RetrieveItineraryPriceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.RetrieveItineraryPriceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.RetrieveItineraryPriceResponse}
 */
proto.elude.proto.RetrieveItineraryPriceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 3:
      var value = new proto_commons_flow_common_pb.Price;
      reader.readMessage(value,proto_commons_flow_common_pb.Price.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.RetrieveItineraryPriceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.RetrieveItineraryPriceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.RetrieveItineraryPriceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.RetrieveItineraryPriceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_flow_common_pb.Price.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.RetrieveItineraryPriceResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.RetrieveItineraryPriceResponse} returns this
*/
proto.elude.proto.RetrieveItineraryPriceResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RetrieveItineraryPriceResponse} returns this
 */
proto.elude.proto.RetrieveItineraryPriceResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RetrieveItineraryPriceResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Status status = 2;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.RetrieveItineraryPriceResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 2));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.RetrieveItineraryPriceResponse} returns this
*/
proto.elude.proto.RetrieveItineraryPriceResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RetrieveItineraryPriceResponse} returns this
 */
proto.elude.proto.RetrieveItineraryPriceResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RetrieveItineraryPriceResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Price price = 3;
 * @return {?proto.elude.proto.Price}
 */
proto.elude.proto.RetrieveItineraryPriceResponse.prototype.getPrice = function() {
  return /** @type{?proto.elude.proto.Price} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.Price, 3));
};


/**
 * @param {?proto.elude.proto.Price|undefined} value
 * @return {!proto.elude.proto.RetrieveItineraryPriceResponse} returns this
*/
proto.elude.proto.RetrieveItineraryPriceResponse.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RetrieveItineraryPriceResponse} returns this
 */
proto.elude.proto.RetrieveItineraryPriceResponse.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RetrieveItineraryPriceResponse.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.HotelDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.HotelDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.HotelDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.HotelDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    hotel: (f = msg.getHotel()) && proto_commons_flow_common_pb.GDSHotel.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.HotelDetailsResponse}
 */
proto.elude.proto.HotelDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.HotelDetailsResponse;
  return proto.elude.proto.HotelDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.HotelDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.HotelDetailsResponse}
 */
proto.elude.proto.HotelDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_commons_flow_common_pb.GDSHotel;
      reader.readMessage(value,proto_commons_flow_common_pb.GDSHotel.deserializeBinaryFromReader);
      msg.setHotel(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.HotelDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.HotelDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.HotelDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.HotelDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getHotel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_flow_common_pb.GDSHotel.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.HotelDetailsResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.HotelDetailsResponse} returns this
*/
proto.elude.proto.HotelDetailsResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.HotelDetailsResponse} returns this
 */
proto.elude.proto.HotelDetailsResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.HotelDetailsResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GDSHotel hotel = 2;
 * @return {?proto.elude.proto.GDSHotel}
 */
proto.elude.proto.HotelDetailsResponse.prototype.getHotel = function() {
  return /** @type{?proto.elude.proto.GDSHotel} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.GDSHotel, 2));
};


/**
 * @param {?proto.elude.proto.GDSHotel|undefined} value
 * @return {!proto.elude.proto.HotelDetailsResponse} returns this
*/
proto.elude.proto.HotelDetailsResponse.prototype.setHotel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.HotelDetailsResponse} returns this
 */
proto.elude.proto.HotelDetailsResponse.prototype.clearHotel = function() {
  return this.setHotel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.HotelDetailsResponse.prototype.hasHotel = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.HotelDetailsResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.HotelDetailsResponse} returns this
*/
proto.elude.proto.HotelDetailsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.HotelDetailsResponse} returns this
 */
proto.elude.proto.HotelDetailsResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.HotelDetailsResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.AbandonTripResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.AbandonTripResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.AbandonTripResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.AbandonTripResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.AbandonTripResponse}
 */
proto.elude.proto.AbandonTripResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.AbandonTripResponse;
  return proto.elude.proto.AbandonTripResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.AbandonTripResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.AbandonTripResponse}
 */
proto.elude.proto.AbandonTripResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.AbandonTripResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.AbandonTripResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.AbandonTripResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.AbandonTripResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.AbandonTripResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.AbandonTripResponse} returns this
*/
proto.elude.proto.AbandonTripResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.AbandonTripResponse} returns this
 */
proto.elude.proto.AbandonTripResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.AbandonTripResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Status status = 2;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.AbandonTripResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 2));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.AbandonTripResponse} returns this
*/
proto.elude.proto.AbandonTripResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.AbandonTripResponse} returns this
 */
proto.elude.proto.AbandonTripResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.AbandonTripResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.BuildHotelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.BuildHotelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.BuildHotelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.BuildHotelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    hotel: (f = msg.getHotel()) && proto_commons_flow_common_pb.GDSHotel.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.BuildHotelResponse}
 */
proto.elude.proto.BuildHotelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.BuildHotelResponse;
  return proto.elude.proto.BuildHotelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.BuildHotelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.BuildHotelResponse}
 */
proto.elude.proto.BuildHotelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_commons_flow_common_pb.GDSHotel;
      reader.readMessage(value,proto_commons_flow_common_pb.GDSHotel.deserializeBinaryFromReader);
      msg.setHotel(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.BuildHotelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.BuildHotelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.BuildHotelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.BuildHotelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getHotel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_flow_common_pb.GDSHotel.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.BuildHotelResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.BuildHotelResponse} returns this
*/
proto.elude.proto.BuildHotelResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.BuildHotelResponse} returns this
 */
proto.elude.proto.BuildHotelResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.BuildHotelResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GDSHotel hotel = 2;
 * @return {?proto.elude.proto.GDSHotel}
 */
proto.elude.proto.BuildHotelResponse.prototype.getHotel = function() {
  return /** @type{?proto.elude.proto.GDSHotel} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.GDSHotel, 2));
};


/**
 * @param {?proto.elude.proto.GDSHotel|undefined} value
 * @return {!proto.elude.proto.BuildHotelResponse} returns this
*/
proto.elude.proto.BuildHotelResponse.prototype.setHotel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.BuildHotelResponse} returns this
 */
proto.elude.proto.BuildHotelResponse.prototype.clearHotel = function() {
  return this.setHotel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.BuildHotelResponse.prototype.hasHotel = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.BuildHotelResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.BuildHotelResponse} returns this
*/
proto.elude.proto.BuildHotelResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.BuildHotelResponse} returns this
 */
proto.elude.proto.BuildHotelResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.BuildHotelResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.SetSearchCacheResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.SetSearchCacheResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.SetSearchCacheResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.SetSearchCacheResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.SetSearchCacheResponse}
 */
proto.elude.proto.SetSearchCacheResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.SetSearchCacheResponse;
  return proto.elude.proto.SetSearchCacheResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.SetSearchCacheResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.SetSearchCacheResponse}
 */
proto.elude.proto.SetSearchCacheResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.SetSearchCacheResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.SetSearchCacheResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.SetSearchCacheResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.SetSearchCacheResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.SetSearchCacheResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.SetSearchCacheResponse} returns this
*/
proto.elude.proto.SetSearchCacheResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.SetSearchCacheResponse} returns this
 */
proto.elude.proto.SetSearchCacheResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.SetSearchCacheResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Status status = 2;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.SetSearchCacheResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 2));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.SetSearchCacheResponse} returns this
*/
proto.elude.proto.SetSearchCacheResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.SetSearchCacheResponse} returns this
 */
proto.elude.proto.SetSearchCacheResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.SetSearchCacheResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.AveragePriceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.AveragePriceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.AveragePriceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.AveragePriceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    averageFlightPrice: jspb.Message.getFieldWithDefault(msg, 2, 0),
    medianHotelPrice: jspb.Message.getFieldWithDefault(msg, 3, 0),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.AveragePriceResponse}
 */
proto.elude.proto.AveragePriceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.AveragePriceResponse;
  return proto.elude.proto.AveragePriceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.AveragePriceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.AveragePriceResponse}
 */
proto.elude.proto.AveragePriceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAverageFlightPrice(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMedianHotelPrice(value);
      break;
    case 4:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.AveragePriceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.AveragePriceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.AveragePriceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.AveragePriceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getAverageFlightPrice();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMedianHotelPrice();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.AveragePriceResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.AveragePriceResponse} returns this
*/
proto.elude.proto.AveragePriceResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.AveragePriceResponse} returns this
 */
proto.elude.proto.AveragePriceResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.AveragePriceResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 average_flight_price = 2;
 * @return {number}
 */
proto.elude.proto.AveragePriceResponse.prototype.getAverageFlightPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.AveragePriceResponse} returns this
 */
proto.elude.proto.AveragePriceResponse.prototype.setAverageFlightPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 median_hotel_price = 3;
 * @return {number}
 */
proto.elude.proto.AveragePriceResponse.prototype.getMedianHotelPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.AveragePriceResponse} returns this
 */
proto.elude.proto.AveragePriceResponse.prototype.setMedianHotelPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Status status = 4;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.AveragePriceResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 4));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.AveragePriceResponse} returns this
*/
proto.elude.proto.AveragePriceResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.AveragePriceResponse} returns this
 */
proto.elude.proto.AveragePriceResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.AveragePriceResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 4) != null;
};


goog.object.extend(exports, proto.elude.proto);
