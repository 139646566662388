// source: proto/commons/enum.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.elude.proto.AbandonedStageEnum', null, global);
goog.exportSymbol('proto.elude.proto.AuthProviderEnum', null, global);
goog.exportSymbol('proto.elude.proto.BookingClassEnum', null, global);
goog.exportSymbol('proto.elude.proto.BudgetTierEnum', null, global);
goog.exportSymbol('proto.elude.proto.DeviceTypeEnum', null, global);
goog.exportSymbol('proto.elude.proto.FlightTypeEnum', null, global);
goog.exportSymbol('proto.elude.proto.GDSEnum', null, global);
goog.exportSymbol('proto.elude.proto.HotelSearchTypeEnum', null, global);
goog.exportSymbol('proto.elude.proto.LocationTypeEnum', null, global);
goog.exportSymbol('proto.elude.proto.PaymentProvidersEnum', null, global);
goog.exportSymbol('proto.elude.proto.PriorityEnum', null, global);
goog.exportSymbol('proto.elude.proto.SearchTypeEnum', null, global);
goog.exportSymbol('proto.elude.proto.TravellerTypeEnum', null, global);
/**
 * @enum {number}
 */
proto.elude.proto.PriorityEnum = {
  HIGH: 0,
  MEDIUM: 1,
  LOW: 2
};

/**
 * @enum {number}
 */
proto.elude.proto.BudgetTierEnum = {
  NONE: 0,
  PLATINUM: 1,
  GOLD: 2,
  SILVER: 3,
  BRONZE: 4
};

/**
 * @enum {number}
 */
proto.elude.proto.GDSEnum = {
  NOT_SET: 0,
  TRAVELPORT: 1,
  SABRE: 2,
  AMADEUS: 3
};

/**
 * @enum {number}
 */
proto.elude.proto.TravellerTypeEnum = {
  ADULT: 0,
  CHILD: 1,
  INFANT: 2
};

/**
 * @enum {number}
 */
proto.elude.proto.BookingClassEnum = {
  ECONOMY: 0,
  BUSINESS: 1,
  FIRST: 2
};

/**
 * @enum {number}
 */
proto.elude.proto.AuthProviderEnum = {
  EMAIL: 0,
  FACEBOOK: 1,
  GOOGLE: 2,
  TWITTER: 3,
  APPLE: 4
};

/**
 * @enum {number}
 */
proto.elude.proto.PaymentProvidersEnum = {
  STRIPE: 0,
  PAYPAL: 1
};

/**
 * @enum {number}
 */
proto.elude.proto.AbandonedStageEnum = {
  DEPARTURE_FLIGHT: 0,
  RETURN_FLIGHT: 1,
  HOTEL: 2,
  ROOM: 3,
  SUMMARY: 4
};

/**
 * @enum {number}
 */
proto.elude.proto.SearchTypeEnum = {
  FLIGHT_SEARCH: 0,
  LOCATION_SEARCH: 1,
  COMBINED_SEARCH: 2,
  AIRPORT_SEARCH: 3
};

/**
 * @enum {number}
 */
proto.elude.proto.LocationTypeEnum = {
  METROPOLITAN_AREA: 0,
  CITY: 1,
  AIRPORT: 2,
  REGION: 3,
  COUNTRY: 4,
  LOCATION: 5
};

/**
 * @enum {number}
 */
proto.elude.proto.HotelSearchTypeEnum = {
  REGULAR_SEARCH: 0,
  TIMEZONE_SEARCH: 1,
  PRIORITY_SEARCH: 2,
  PRIORITY_TIMEZONE_SEARCH: 3
};

/**
 * @enum {number}
 */
proto.elude.proto.DeviceTypeEnum = {
  DEVICE_UNKNOWN: 0,
  IOS: 1,
  WEB: 2,
  ANDROID: 3,
  CLI: 4
};

/**
 * @enum {number}
 */
proto.elude.proto.FlightTypeEnum = {
  FLIGHT_TYPE_UNSPECIFIED: 0,
  FLIGHT_TYPE_ONE_WAY: 1,
  FLIGHT_TYPE_RETURN: 2,
  FLIGHT_TYPE_CIRCLE: 3,
  FLIGHT_TYPE_OPEN_JAW: 4,
  FLIGHT_TYPE_OTHER: 5
};

goog.object.extend(exports, proto.elude.proto);
