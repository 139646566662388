// source: proto/commons/flow_common.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
var proto_commons_enum_pb = require('../../proto/commons/enum_pb.js');
goog.object.extend(proto, proto_commons_enum_pb);
var proto_commons_timestamp_pb = require('../../proto/commons/timestamp_pb.js');
goog.object.extend(proto, proto_commons_timestamp_pb);
var proto_commons_coordinates_pb = require('../../proto/commons/coordinates_pb.js');
goog.object.extend(proto, proto_commons_coordinates_pb);
var proto_commons_metadata_pb = require('../../proto/commons/metadata_pb.js');
goog.object.extend(proto, proto_commons_metadata_pb);
var proto_models_airline_pb = require('../../proto/models/airline_pb.js');
goog.object.extend(proto, proto_models_airline_pb);
var proto_models_city_pb = require('../../proto/models/city_pb.js');
goog.object.extend(proto, proto_models_city_pb);
var proto_models_traveller_pb = require('../../proto/models/traveller_pb.js');
goog.object.extend(proto, proto_models_traveller_pb);
var proto_models_user_pb = require('../../proto/models/user_pb.js');
goog.object.extend(proto, proto_models_user_pb);
var proto_models_region_pb = require('../../proto/models/region_pb.js');
goog.object.extend(proto, proto_models_region_pb);
goog.exportSymbol('proto.elude.proto.AirRequirements', null, global);
goog.exportSymbol('proto.elude.proto.Budget', null, global);
goog.exportSymbol('proto.elude.proto.Date', null, global);
goog.exportSymbol('proto.elude.proto.Dates', null, global);
goog.exportSymbol('proto.elude.proto.FlightOption', null, global);
goog.exportSymbol('proto.elude.proto.GDSAmenity', null, global);
goog.exportSymbol('proto.elude.proto.GDSBackend', null, global);
goog.exportSymbol('proto.elude.proto.GDSFlight', null, global);
goog.exportSymbol('proto.elude.proto.GDSFlightSegment', null, global);
goog.exportSymbol('proto.elude.proto.GDSHotel', null, global);
goog.exportSymbol('proto.elude.proto.GDSItinerary', null, global);
goog.exportSymbol('proto.elude.proto.GDSOptions', null, global);
goog.exportSymbol('proto.elude.proto.GDSPaymentProvider', null, global);
goog.exportSymbol('proto.elude.proto.GDSRoom', null, global);
goog.exportSymbol('proto.elude.proto.GDSTraveller', null, global);
goog.exportSymbol('proto.elude.proto.GDSUser', null, global);
goog.exportSymbol('proto.elude.proto.HotelRequirements', null, global);
goog.exportSymbol('proto.elude.proto.LocationIdentifier', null, global);
goog.exportSymbol('proto.elude.proto.LocationsRequirements', null, global);
goog.exportSymbol('proto.elude.proto.Price', null, global);
goog.exportSymbol('proto.elude.proto.PriceQuote', null, global);
goog.exportSymbol('proto.elude.proto.SearchLocation', null, global);
goog.exportSymbol('proto.elude.proto.Telephone', null, global);
goog.exportSymbol('proto.elude.proto.TicketInfo', null, global);
goog.exportSymbol('proto.elude.proto.TravellerInfo', null, global);
goog.exportSymbol('proto.elude.proto.Travellers', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.Budget = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.Budget, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.Budget.displayName = 'proto.elude.proto.Budget';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.Date = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.Date, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.Date.displayName = 'proto.elude.proto.Date';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.Dates = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.Dates, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.Dates.displayName = 'proto.elude.proto.Dates';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GDSBackend = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GDSBackend, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GDSBackend.displayName = 'proto.elude.proto.GDSBackend';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GDSPaymentProvider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GDSPaymentProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GDSPaymentProvider.displayName = 'proto.elude.proto.GDSPaymentProvider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GDSOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.GDSOptions.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.GDSOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GDSOptions.displayName = 'proto.elude.proto.GDSOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GDSHotel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.GDSHotel.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.GDSHotel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GDSHotel.displayName = 'proto.elude.proto.GDSHotel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.Telephone = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.Telephone, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.Telephone.displayName = 'proto.elude.proto.Telephone';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GDSRoom = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.GDSRoom.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.GDSRoom, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GDSRoom.displayName = 'proto.elude.proto.GDSRoom';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GDSAmenity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GDSAmenity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GDSAmenity.displayName = 'proto.elude.proto.GDSAmenity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.TravellerInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.TravellerInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.TravellerInfo.displayName = 'proto.elude.proto.TravellerInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.Travellers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.Travellers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.Travellers.displayName = 'proto.elude.proto.Travellers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GDSFlight = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.GDSFlight.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.GDSFlight, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GDSFlight.displayName = 'proto.elude.proto.GDSFlight';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GDSFlightSegment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GDSFlightSegment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GDSFlightSegment.displayName = 'proto.elude.proto.GDSFlightSegment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.FlightOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.FlightOption.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.FlightOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.FlightOption.displayName = 'proto.elude.proto.FlightOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.HotelRequirements = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.HotelRequirements, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.HotelRequirements.displayName = 'proto.elude.proto.HotelRequirements';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.AirRequirements = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.AirRequirements.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.AirRequirements, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.AirRequirements.displayName = 'proto.elude.proto.AirRequirements';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.LocationIdentifier = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.LocationIdentifier.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.LocationIdentifier, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.LocationIdentifier.displayName = 'proto.elude.proto.LocationIdentifier';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.LocationsRequirements = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.LocationsRequirements.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.LocationsRequirements, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.LocationsRequirements.displayName = 'proto.elude.proto.LocationsRequirements';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.PriceQuote = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.PriceQuote, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.PriceQuote.displayName = 'proto.elude.proto.PriceQuote';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.Price = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.Price, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.Price.displayName = 'proto.elude.proto.Price';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GDSItinerary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.GDSItinerary.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.GDSItinerary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GDSItinerary.displayName = 'proto.elude.proto.GDSItinerary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GDSTraveller = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GDSTraveller, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GDSTraveller.displayName = 'proto.elude.proto.GDSTraveller';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GDSUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GDSUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GDSUser.displayName = 'proto.elude.proto.GDSUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.TicketInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.TicketInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.TicketInfo.displayName = 'proto.elude.proto.TicketInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.SearchLocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.SearchLocation.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.SearchLocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.SearchLocation.displayName = 'proto.elude.proto.SearchLocation';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.Budget.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.Budget.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.Budget} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.Budget.toObject = function(includeInstance, msg) {
  var f, obj = {
    minPrice: jspb.Message.getFieldWithDefault(msg, 1, 0),
    maxPrice: jspb.Message.getFieldWithDefault(msg, 2, 0),
    absoluteMaxPrice: jspb.Message.getFieldWithDefault(msg, 3, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.Budget}
 */
proto.elude.proto.Budget.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.Budget;
  return proto.elude.proto.Budget.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.Budget} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.Budget}
 */
proto.elude.proto.Budget.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinPrice(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxPrice(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAbsoluteMaxPrice(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.Budget.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.Budget.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.Budget} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.Budget.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMinPrice();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMaxPrice();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAbsoluteMaxPrice();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 min_price = 1;
 * @return {number}
 */
proto.elude.proto.Budget.prototype.getMinPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Budget} returns this
 */
proto.elude.proto.Budget.prototype.setMinPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 max_price = 2;
 * @return {number}
 */
proto.elude.proto.Budget.prototype.getMaxPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Budget} returns this
 */
proto.elude.proto.Budget.prototype.setMaxPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 absolute_max_price = 3;
 * @return {number}
 */
proto.elude.proto.Budget.prototype.getAbsoluteMaxPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Budget} returns this
 */
proto.elude.proto.Budget.prototype.setAbsoluteMaxPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string currency = 4;
 * @return {string}
 */
proto.elude.proto.Budget.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Budget} returns this
 */
proto.elude.proto.Budget.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.Date.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.Date.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.Date} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.Date.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0),
    month: jspb.Message.getFieldWithDefault(msg, 2, 0),
    day: jspb.Message.getFieldWithDefault(msg, 3, 0),
    timezone: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.Date}
 */
proto.elude.proto.Date.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.Date;
  return proto.elude.proto.Date.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.Date} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.Date}
 */
proto.elude.proto.Date.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDay(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.Date.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.Date.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.Date} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.Date.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDay();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 year = 1;
 * @return {number}
 */
proto.elude.proto.Date.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Date} returns this
 */
proto.elude.proto.Date.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 month = 2;
 * @return {number}
 */
proto.elude.proto.Date.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Date} returns this
 */
proto.elude.proto.Date.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 day = 3;
 * @return {number}
 */
proto.elude.proto.Date.prototype.getDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Date} returns this
 */
proto.elude.proto.Date.prototype.setDay = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string timezone = 4;
 * @return {string}
 */
proto.elude.proto.Date.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Date} returns this
 */
proto.elude.proto.Date.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.Dates.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.Dates.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.Dates} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.Dates.toObject = function(includeInstance, msg) {
  var f, obj = {
    departure: (f = msg.getDeparture()) && proto.elude.proto.Date.toObject(includeInstance, f),
    pb_return: (f = msg.getReturn()) && proto.elude.proto.Date.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.Dates}
 */
proto.elude.proto.Dates.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.Dates;
  return proto.elude.proto.Dates.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.Dates} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.Dates}
 */
proto.elude.proto.Dates.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.elude.proto.Date;
      reader.readMessage(value,proto.elude.proto.Date.deserializeBinaryFromReader);
      msg.setDeparture(value);
      break;
    case 2:
      var value = new proto.elude.proto.Date;
      reader.readMessage(value,proto.elude.proto.Date.deserializeBinaryFromReader);
      msg.setReturn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.Dates.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.Dates.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.Dates} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.Dates.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeparture();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.elude.proto.Date.serializeBinaryToWriter
    );
  }
  f = message.getReturn();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.elude.proto.Date.serializeBinaryToWriter
    );
  }
};


/**
 * optional Date departure = 1;
 * @return {?proto.elude.proto.Date}
 */
proto.elude.proto.Dates.prototype.getDeparture = function() {
  return /** @type{?proto.elude.proto.Date} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.Date, 1));
};


/**
 * @param {?proto.elude.proto.Date|undefined} value
 * @return {!proto.elude.proto.Dates} returns this
*/
proto.elude.proto.Dates.prototype.setDeparture = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Dates} returns this
 */
proto.elude.proto.Dates.prototype.clearDeparture = function() {
  return this.setDeparture(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Dates.prototype.hasDeparture = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Date return = 2;
 * @return {?proto.elude.proto.Date}
 */
proto.elude.proto.Dates.prototype.getReturn = function() {
  return /** @type{?proto.elude.proto.Date} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.Date, 2));
};


/**
 * @param {?proto.elude.proto.Date|undefined} value
 * @return {!proto.elude.proto.Dates} returns this
*/
proto.elude.proto.Dates.prototype.setReturn = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Dates} returns this
 */
proto.elude.proto.Dates.prototype.clearReturn = function() {
  return this.setReturn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Dates.prototype.hasReturn = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GDSBackend.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GDSBackend.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GDSBackend} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GDSBackend.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, 0),
    priority: jspb.Message.getFieldWithDefault(msg, 2, 0),
    timeoutMs: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GDSBackend}
 */
proto.elude.proto.GDSBackend.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GDSBackend;
  return proto.elude.proto.GDSBackend.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GDSBackend} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GDSBackend}
 */
proto.elude.proto.GDSBackend.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.elude.proto.GDSEnum} */ (reader.readEnum());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriority(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTimeoutMs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GDSBackend.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GDSBackend.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GDSBackend} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GDSBackend.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPriority();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTimeoutMs();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional GDSEnum name = 1;
 * @return {!proto.elude.proto.GDSEnum}
 */
proto.elude.proto.GDSBackend.prototype.getName = function() {
  return /** @type {!proto.elude.proto.GDSEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.elude.proto.GDSEnum} value
 * @return {!proto.elude.proto.GDSBackend} returns this
 */
proto.elude.proto.GDSBackend.prototype.setName = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 priority = 2;
 * @return {number}
 */
proto.elude.proto.GDSBackend.prototype.getPriority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GDSBackend} returns this
 */
proto.elude.proto.GDSBackend.prototype.setPriority = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 timeout_ms = 3;
 * @return {number}
 */
proto.elude.proto.GDSBackend.prototype.getTimeoutMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GDSBackend} returns this
 */
proto.elude.proto.GDSBackend.prototype.setTimeoutMs = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GDSPaymentProvider.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GDSPaymentProvider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GDSPaymentProvider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GDSPaymentProvider.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GDSPaymentProvider}
 */
proto.elude.proto.GDSPaymentProvider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GDSPaymentProvider;
  return proto.elude.proto.GDSPaymentProvider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GDSPaymentProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GDSPaymentProvider}
 */
proto.elude.proto.GDSPaymentProvider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.elude.proto.PaymentProvidersEnum} */ (reader.readEnum());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GDSPaymentProvider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GDSPaymentProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GDSPaymentProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GDSPaymentProvider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional PaymentProvidersEnum name = 1;
 * @return {!proto.elude.proto.PaymentProvidersEnum}
 */
proto.elude.proto.GDSPaymentProvider.prototype.getName = function() {
  return /** @type {!proto.elude.proto.PaymentProvidersEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.elude.proto.PaymentProvidersEnum} value
 * @return {!proto.elude.proto.GDSPaymentProvider} returns this
 */
proto.elude.proto.GDSPaymentProvider.prototype.setName = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.GDSOptions.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GDSOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GDSOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GDSOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GDSOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    airBackendsList: jspb.Message.toObjectList(msg.getAirBackendsList(),
    proto.elude.proto.GDSBackend.toObject, includeInstance),
    hotelBackendsList: jspb.Message.toObjectList(msg.getHotelBackendsList(),
    proto.elude.proto.GDSBackend.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GDSOptions}
 */
proto.elude.proto.GDSOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GDSOptions;
  return proto.elude.proto.GDSOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GDSOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GDSOptions}
 */
proto.elude.proto.GDSOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.elude.proto.GDSBackend;
      reader.readMessage(value,proto.elude.proto.GDSBackend.deserializeBinaryFromReader);
      msg.addAirBackends(value);
      break;
    case 2:
      var value = new proto.elude.proto.GDSBackend;
      reader.readMessage(value,proto.elude.proto.GDSBackend.deserializeBinaryFromReader);
      msg.addHotelBackends(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GDSOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GDSOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GDSOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GDSOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAirBackendsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.elude.proto.GDSBackend.serializeBinaryToWriter
    );
  }
  f = message.getHotelBackendsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.elude.proto.GDSBackend.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GDSBackend air_backends = 1;
 * @return {!Array<!proto.elude.proto.GDSBackend>}
 */
proto.elude.proto.GDSOptions.prototype.getAirBackendsList = function() {
  return /** @type{!Array<!proto.elude.proto.GDSBackend>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.elude.proto.GDSBackend, 1));
};


/**
 * @param {!Array<!proto.elude.proto.GDSBackend>} value
 * @return {!proto.elude.proto.GDSOptions} returns this
*/
proto.elude.proto.GDSOptions.prototype.setAirBackendsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.elude.proto.GDSBackend=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.GDSBackend}
 */
proto.elude.proto.GDSOptions.prototype.addAirBackends = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.elude.proto.GDSBackend, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.GDSOptions} returns this
 */
proto.elude.proto.GDSOptions.prototype.clearAirBackendsList = function() {
  return this.setAirBackendsList([]);
};


/**
 * repeated GDSBackend hotel_backends = 2;
 * @return {!Array<!proto.elude.proto.GDSBackend>}
 */
proto.elude.proto.GDSOptions.prototype.getHotelBackendsList = function() {
  return /** @type{!Array<!proto.elude.proto.GDSBackend>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.elude.proto.GDSBackend, 2));
};


/**
 * @param {!Array<!proto.elude.proto.GDSBackend>} value
 * @return {!proto.elude.proto.GDSOptions} returns this
*/
proto.elude.proto.GDSOptions.prototype.setHotelBackendsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.elude.proto.GDSBackend=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.GDSBackend}
 */
proto.elude.proto.GDSOptions.prototype.addHotelBackends = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.elude.proto.GDSBackend, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.GDSOptions} returns this
 */
proto.elude.proto.GDSOptions.prototype.clearHotelBackendsList = function() {
  return this.setHotelBackendsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.GDSHotel.repeatedFields_ = [12,13,14,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GDSHotel.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GDSHotel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GDSHotel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GDSHotel.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    address: jspb.Message.getFieldWithDefault(msg, 4, ""),
    message: jspb.Message.getFieldWithDefault(msg, 5, ""),
    code: jspb.Message.getFieldWithDefault(msg, 6, ""),
    chain: jspb.Message.getFieldWithDefault(msg, 7, ""),
    rating: jspb.Message.getFieldWithDefault(msg, 8, 0),
    checkInDatetime: (f = msg.getCheckInDatetime()) && proto_commons_timestamp_pb.Timestamp.toObject(includeInstance, f),
    checkOutDatetime: (f = msg.getCheckOutDatetime()) && proto_commons_timestamp_pb.Timestamp.toObject(includeInstance, f),
    coordinates: (f = msg.getCoordinates()) && proto_commons_coordinates_pb.Coordinates.toObject(includeInstance, f),
    telephonesList: jspb.Message.toObjectList(msg.getTelephonesList(),
    proto.elude.proto.Telephone.toObject, includeInstance),
    amenitiesList: jspb.Message.toObjectList(msg.getAmenitiesList(),
    proto.elude.proto.GDSAmenity.toObject, includeInstance),
    mediaList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
    roomsList: jspb.Message.toObjectList(msg.getRoomsList(),
    proto.elude.proto.GDSRoom.toObject, includeInstance),
    gds: (f = msg.getGds()) && proto.elude.proto.GDSBackend.toObject(includeInstance, f),
    confirmationCode: jspb.Message.getFieldWithDefault(msg, 17, ""),
    rateSource: jspb.Message.getFieldWithDefault(msg, 18, ""),
    priority: jspb.Message.getFieldWithDefault(msg, 19, 0),
    hotelSearchType: jspb.Message.getFieldWithDefault(msg, 20, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GDSHotel}
 */
proto.elude.proto.GDSHotel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GDSHotel;
  return proto.elude.proto.GDSHotel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GDSHotel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GDSHotel}
 */
proto.elude.proto.GDSHotel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setChain(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRating(value);
      break;
    case 9:
      var value = new proto_commons_timestamp_pb.Timestamp;
      reader.readMessage(value,proto_commons_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCheckInDatetime(value);
      break;
    case 10:
      var value = new proto_commons_timestamp_pb.Timestamp;
      reader.readMessage(value,proto_commons_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCheckOutDatetime(value);
      break;
    case 11:
      var value = new proto_commons_coordinates_pb.Coordinates;
      reader.readMessage(value,proto_commons_coordinates_pb.Coordinates.deserializeBinaryFromReader);
      msg.setCoordinates(value);
      break;
    case 12:
      var value = new proto.elude.proto.Telephone;
      reader.readMessage(value,proto.elude.proto.Telephone.deserializeBinaryFromReader);
      msg.addTelephones(value);
      break;
    case 13:
      var value = new proto.elude.proto.GDSAmenity;
      reader.readMessage(value,proto.elude.proto.GDSAmenity.deserializeBinaryFromReader);
      msg.addAmenities(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.addMedia(value);
      break;
    case 15:
      var value = new proto.elude.proto.GDSRoom;
      reader.readMessage(value,proto.elude.proto.GDSRoom.deserializeBinaryFromReader);
      msg.addRooms(value);
      break;
    case 16:
      var value = new proto.elude.proto.GDSBackend;
      reader.readMessage(value,proto.elude.proto.GDSBackend.deserializeBinaryFromReader);
      msg.setGds(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfirmationCode(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setRateSource(value);
      break;
    case 19:
      var value = /** @type {!proto.elude.proto.PriorityEnum} */ (reader.readEnum());
      msg.setPriority(value);
      break;
    case 20:
      var value = /** @type {!proto.elude.proto.HotelSearchTypeEnum} */ (reader.readEnum());
      msg.setHotelSearchType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GDSHotel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GDSHotel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GDSHotel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GDSHotel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getChain();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRating();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getCheckInDatetime();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_commons_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCheckOutDatetime();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto_commons_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCoordinates();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto_commons_coordinates_pb.Coordinates.serializeBinaryToWriter
    );
  }
  f = message.getTelephonesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.elude.proto.Telephone.serializeBinaryToWriter
    );
  }
  f = message.getAmenitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.elude.proto.GDSAmenity.serializeBinaryToWriter
    );
  }
  f = message.getMediaList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      14,
      f
    );
  }
  f = message.getRoomsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.elude.proto.GDSRoom.serializeBinaryToWriter
    );
  }
  f = message.getGds();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.elude.proto.GDSBackend.serializeBinaryToWriter
    );
  }
  f = message.getConfirmationCode();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getRateSource();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getPriority();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
  f = message.getHotelSearchType();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.elude.proto.GDSHotel.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GDSHotel} returns this
 */
proto.elude.proto.GDSHotel.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.elude.proto.GDSHotel.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSHotel} returns this
 */
proto.elude.proto.GDSHotel.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.elude.proto.GDSHotel.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSHotel} returns this
 */
proto.elude.proto.GDSHotel.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string address = 4;
 * @return {string}
 */
proto.elude.proto.GDSHotel.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSHotel} returns this
 */
proto.elude.proto.GDSHotel.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string message = 5;
 * @return {string}
 */
proto.elude.proto.GDSHotel.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSHotel} returns this
 */
proto.elude.proto.GDSHotel.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string code = 6;
 * @return {string}
 */
proto.elude.proto.GDSHotel.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSHotel} returns this
 */
proto.elude.proto.GDSHotel.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string chain = 7;
 * @return {string}
 */
proto.elude.proto.GDSHotel.prototype.getChain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSHotel} returns this
 */
proto.elude.proto.GDSHotel.prototype.setChain = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 rating = 8;
 * @return {number}
 */
proto.elude.proto.GDSHotel.prototype.getRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GDSHotel} returns this
 */
proto.elude.proto.GDSHotel.prototype.setRating = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional Timestamp check_in_datetime = 9;
 * @return {?proto.elude.proto.Timestamp}
 */
proto.elude.proto.GDSHotel.prototype.getCheckInDatetime = function() {
  return /** @type{?proto.elude.proto.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto_commons_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.elude.proto.Timestamp|undefined} value
 * @return {!proto.elude.proto.GDSHotel} returns this
*/
proto.elude.proto.GDSHotel.prototype.setCheckInDatetime = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GDSHotel} returns this
 */
proto.elude.proto.GDSHotel.prototype.clearCheckInDatetime = function() {
  return this.setCheckInDatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GDSHotel.prototype.hasCheckInDatetime = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Timestamp check_out_datetime = 10;
 * @return {?proto.elude.proto.Timestamp}
 */
proto.elude.proto.GDSHotel.prototype.getCheckOutDatetime = function() {
  return /** @type{?proto.elude.proto.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto_commons_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.elude.proto.Timestamp|undefined} value
 * @return {!proto.elude.proto.GDSHotel} returns this
*/
proto.elude.proto.GDSHotel.prototype.setCheckOutDatetime = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GDSHotel} returns this
 */
proto.elude.proto.GDSHotel.prototype.clearCheckOutDatetime = function() {
  return this.setCheckOutDatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GDSHotel.prototype.hasCheckOutDatetime = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Coordinates coordinates = 11;
 * @return {?proto.elude.proto.Coordinates}
 */
proto.elude.proto.GDSHotel.prototype.getCoordinates = function() {
  return /** @type{?proto.elude.proto.Coordinates} */ (
    jspb.Message.getWrapperField(this, proto_commons_coordinates_pb.Coordinates, 11));
};


/**
 * @param {?proto.elude.proto.Coordinates|undefined} value
 * @return {!proto.elude.proto.GDSHotel} returns this
*/
proto.elude.proto.GDSHotel.prototype.setCoordinates = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GDSHotel} returns this
 */
proto.elude.proto.GDSHotel.prototype.clearCoordinates = function() {
  return this.setCoordinates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GDSHotel.prototype.hasCoordinates = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated Telephone telephones = 12;
 * @return {!Array<!proto.elude.proto.Telephone>}
 */
proto.elude.proto.GDSHotel.prototype.getTelephonesList = function() {
  return /** @type{!Array<!proto.elude.proto.Telephone>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.elude.proto.Telephone, 12));
};


/**
 * @param {!Array<!proto.elude.proto.Telephone>} value
 * @return {!proto.elude.proto.GDSHotel} returns this
*/
proto.elude.proto.GDSHotel.prototype.setTelephonesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.elude.proto.Telephone=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.Telephone}
 */
proto.elude.proto.GDSHotel.prototype.addTelephones = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.elude.proto.Telephone, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.GDSHotel} returns this
 */
proto.elude.proto.GDSHotel.prototype.clearTelephonesList = function() {
  return this.setTelephonesList([]);
};


/**
 * repeated GDSAmenity amenities = 13;
 * @return {!Array<!proto.elude.proto.GDSAmenity>}
 */
proto.elude.proto.GDSHotel.prototype.getAmenitiesList = function() {
  return /** @type{!Array<!proto.elude.proto.GDSAmenity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.elude.proto.GDSAmenity, 13));
};


/**
 * @param {!Array<!proto.elude.proto.GDSAmenity>} value
 * @return {!proto.elude.proto.GDSHotel} returns this
*/
proto.elude.proto.GDSHotel.prototype.setAmenitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.elude.proto.GDSAmenity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.GDSAmenity}
 */
proto.elude.proto.GDSHotel.prototype.addAmenities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.elude.proto.GDSAmenity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.GDSHotel} returns this
 */
proto.elude.proto.GDSHotel.prototype.clearAmenitiesList = function() {
  return this.setAmenitiesList([]);
};


/**
 * repeated string media = 14;
 * @return {!Array<string>}
 */
proto.elude.proto.GDSHotel.prototype.getMediaList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.elude.proto.GDSHotel} returns this
 */
proto.elude.proto.GDSHotel.prototype.setMediaList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.GDSHotel} returns this
 */
proto.elude.proto.GDSHotel.prototype.addMedia = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.GDSHotel} returns this
 */
proto.elude.proto.GDSHotel.prototype.clearMediaList = function() {
  return this.setMediaList([]);
};


/**
 * repeated GDSRoom rooms = 15;
 * @return {!Array<!proto.elude.proto.GDSRoom>}
 */
proto.elude.proto.GDSHotel.prototype.getRoomsList = function() {
  return /** @type{!Array<!proto.elude.proto.GDSRoom>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.elude.proto.GDSRoom, 15));
};


/**
 * @param {!Array<!proto.elude.proto.GDSRoom>} value
 * @return {!proto.elude.proto.GDSHotel} returns this
*/
proto.elude.proto.GDSHotel.prototype.setRoomsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.elude.proto.GDSRoom=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.GDSRoom}
 */
proto.elude.proto.GDSHotel.prototype.addRooms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.elude.proto.GDSRoom, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.GDSHotel} returns this
 */
proto.elude.proto.GDSHotel.prototype.clearRoomsList = function() {
  return this.setRoomsList([]);
};


/**
 * optional GDSBackend gds = 16;
 * @return {?proto.elude.proto.GDSBackend}
 */
proto.elude.proto.GDSHotel.prototype.getGds = function() {
  return /** @type{?proto.elude.proto.GDSBackend} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.GDSBackend, 16));
};


/**
 * @param {?proto.elude.proto.GDSBackend|undefined} value
 * @return {!proto.elude.proto.GDSHotel} returns this
*/
proto.elude.proto.GDSHotel.prototype.setGds = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GDSHotel} returns this
 */
proto.elude.proto.GDSHotel.prototype.clearGds = function() {
  return this.setGds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GDSHotel.prototype.hasGds = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string confirmation_code = 17;
 * @return {string}
 */
proto.elude.proto.GDSHotel.prototype.getConfirmationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSHotel} returns this
 */
proto.elude.proto.GDSHotel.prototype.setConfirmationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string rate_source = 18;
 * @return {string}
 */
proto.elude.proto.GDSHotel.prototype.getRateSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSHotel} returns this
 */
proto.elude.proto.GDSHotel.prototype.setRateSource = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional PriorityEnum priority = 19;
 * @return {!proto.elude.proto.PriorityEnum}
 */
proto.elude.proto.GDSHotel.prototype.getPriority = function() {
  return /** @type {!proto.elude.proto.PriorityEnum} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.elude.proto.PriorityEnum} value
 * @return {!proto.elude.proto.GDSHotel} returns this
 */
proto.elude.proto.GDSHotel.prototype.setPriority = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};


/**
 * optional HotelSearchTypeEnum hotel_search_type = 20;
 * @return {!proto.elude.proto.HotelSearchTypeEnum}
 */
proto.elude.proto.GDSHotel.prototype.getHotelSearchType = function() {
  return /** @type {!proto.elude.proto.HotelSearchTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.elude.proto.HotelSearchTypeEnum} value
 * @return {!proto.elude.proto.GDSHotel} returns this
 */
proto.elude.proto.GDSHotel.prototype.setHotelSearchType = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.Telephone.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.Telephone.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.Telephone} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.Telephone.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    number: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.Telephone}
 */
proto.elude.proto.Telephone.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.Telephone;
  return proto.elude.proto.Telephone.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.Telephone} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.Telephone}
 */
proto.elude.proto.Telephone.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.Telephone.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.Telephone.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.Telephone} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.Telephone.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.elude.proto.Telephone.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Telephone} returns this
 */
proto.elude.proto.Telephone.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string number = 2;
 * @return {string}
 */
proto.elude.proto.Telephone.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Telephone} returns this
 */
proto.elude.proto.Telephone.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.GDSRoom.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GDSRoom.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GDSRoom.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GDSRoom} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GDSRoom.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isRefundable: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    breakfastIncluded: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    paymentType: jspb.Message.getFieldWithDefault(msg, 6, ""),
    amenitiesList: jspb.Message.toObjectList(msg.getAmenitiesList(),
    proto.elude.proto.GDSAmenity.toObject, includeInstance),
    price: (f = msg.getPrice()) && proto.elude.proto.Price.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GDSRoom}
 */
proto.elude.proto.GDSRoom.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GDSRoom;
  return proto.elude.proto.GDSRoom.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GDSRoom} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GDSRoom}
 */
proto.elude.proto.GDSRoom.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRefundable(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBreakfastIncluded(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentType(value);
      break;
    case 7:
      var value = new proto.elude.proto.GDSAmenity;
      reader.readMessage(value,proto.elude.proto.GDSAmenity.deserializeBinaryFromReader);
      msg.addAmenities(value);
      break;
    case 8:
      var value = new proto.elude.proto.Price;
      reader.readMessage(value,proto.elude.proto.Price.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GDSRoom.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GDSRoom.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GDSRoom} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GDSRoom.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsRefundable();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getBreakfastIncluded();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPaymentType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAmenitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.elude.proto.GDSAmenity.serializeBinaryToWriter
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.elude.proto.Price.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.elude.proto.GDSRoom.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GDSRoom} returns this
 */
proto.elude.proto.GDSRoom.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.elude.proto.GDSRoom.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSRoom} returns this
 */
proto.elude.proto.GDSRoom.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.elude.proto.GDSRoom.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSRoom} returns this
 */
proto.elude.proto.GDSRoom.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_refundable = 4;
 * @return {boolean}
 */
proto.elude.proto.GDSRoom.prototype.getIsRefundable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.elude.proto.GDSRoom} returns this
 */
proto.elude.proto.GDSRoom.prototype.setIsRefundable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool breakfast_included = 5;
 * @return {boolean}
 */
proto.elude.proto.GDSRoom.prototype.getBreakfastIncluded = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.elude.proto.GDSRoom} returns this
 */
proto.elude.proto.GDSRoom.prototype.setBreakfastIncluded = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string payment_type = 6;
 * @return {string}
 */
proto.elude.proto.GDSRoom.prototype.getPaymentType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSRoom} returns this
 */
proto.elude.proto.GDSRoom.prototype.setPaymentType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated GDSAmenity amenities = 7;
 * @return {!Array<!proto.elude.proto.GDSAmenity>}
 */
proto.elude.proto.GDSRoom.prototype.getAmenitiesList = function() {
  return /** @type{!Array<!proto.elude.proto.GDSAmenity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.elude.proto.GDSAmenity, 7));
};


/**
 * @param {!Array<!proto.elude.proto.GDSAmenity>} value
 * @return {!proto.elude.proto.GDSRoom} returns this
*/
proto.elude.proto.GDSRoom.prototype.setAmenitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.elude.proto.GDSAmenity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.GDSAmenity}
 */
proto.elude.proto.GDSRoom.prototype.addAmenities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.elude.proto.GDSAmenity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.GDSRoom} returns this
 */
proto.elude.proto.GDSRoom.prototype.clearAmenitiesList = function() {
  return this.setAmenitiesList([]);
};


/**
 * optional Price price = 8;
 * @return {?proto.elude.proto.Price}
 */
proto.elude.proto.GDSRoom.prototype.getPrice = function() {
  return /** @type{?proto.elude.proto.Price} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.Price, 8));
};


/**
 * @param {?proto.elude.proto.Price|undefined} value
 * @return {!proto.elude.proto.GDSRoom} returns this
*/
proto.elude.proto.GDSRoom.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GDSRoom} returns this
 */
proto.elude.proto.GDSRoom.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GDSRoom.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GDSAmenity.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GDSAmenity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GDSAmenity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GDSAmenity.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GDSAmenity}
 */
proto.elude.proto.GDSAmenity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GDSAmenity;
  return proto.elude.proto.GDSAmenity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GDSAmenity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GDSAmenity}
 */
proto.elude.proto.GDSAmenity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GDSAmenity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GDSAmenity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GDSAmenity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GDSAmenity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.elude.proto.GDSAmenity.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSAmenity} returns this
 */
proto.elude.proto.GDSAmenity.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string image_url = 2;
 * @return {string}
 */
proto.elude.proto.GDSAmenity.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSAmenity} returns this
 */
proto.elude.proto.GDSAmenity.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 code = 3;
 * @return {number}
 */
proto.elude.proto.GDSAmenity.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GDSAmenity} returns this
 */
proto.elude.proto.GDSAmenity.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.TravellerInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.TravellerInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.TravellerInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.TravellerInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    price: (f = msg.getPrice()) && proto.elude.proto.Price.toObject(includeInstance, f),
    travellerType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    ticketNumber: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.TravellerInfo}
 */
proto.elude.proto.TravellerInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.TravellerInfo;
  return proto.elude.proto.TravellerInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.TravellerInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.TravellerInfo}
 */
proto.elude.proto.TravellerInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 3:
      var value = new proto.elude.proto.Price;
      reader.readMessage(value,proto.elude.proto.Price.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    case 2:
      var value = /** @type {!proto.elude.proto.TravellerTypeEnum} */ (reader.readEnum());
      msg.setTravellerType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTicketNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.TravellerInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.TravellerInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.TravellerInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.TravellerInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.elude.proto.Price.serializeBinaryToWriter
    );
  }
  f = message.getTravellerType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTicketNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.elude.proto.TravellerInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.TravellerInfo} returns this
 */
proto.elude.proto.TravellerInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Price price = 3;
 * @return {?proto.elude.proto.Price}
 */
proto.elude.proto.TravellerInfo.prototype.getPrice = function() {
  return /** @type{?proto.elude.proto.Price} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.Price, 3));
};


/**
 * @param {?proto.elude.proto.Price|undefined} value
 * @return {!proto.elude.proto.TravellerInfo} returns this
*/
proto.elude.proto.TravellerInfo.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.TravellerInfo} returns this
 */
proto.elude.proto.TravellerInfo.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.TravellerInfo.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TravellerTypeEnum traveller_type = 2;
 * @return {!proto.elude.proto.TravellerTypeEnum}
 */
proto.elude.proto.TravellerInfo.prototype.getTravellerType = function() {
  return /** @type {!proto.elude.proto.TravellerTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.elude.proto.TravellerTypeEnum} value
 * @return {!proto.elude.proto.TravellerInfo} returns this
 */
proto.elude.proto.TravellerInfo.prototype.setTravellerType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string ticket_number = 4;
 * @return {string}
 */
proto.elude.proto.TravellerInfo.prototype.getTicketNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.TravellerInfo} returns this
 */
proto.elude.proto.TravellerInfo.prototype.setTicketNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.Travellers.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.Travellers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.Travellers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.Travellers.toObject = function(includeInstance, msg) {
  var f, obj = {
    adults: jspb.Message.getFieldWithDefault(msg, 1, 0),
    children: jspb.Message.getFieldWithDefault(msg, 2, 0),
    infants: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.Travellers}
 */
proto.elude.proto.Travellers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.Travellers;
  return proto.elude.proto.Travellers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.Travellers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.Travellers}
 */
proto.elude.proto.Travellers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdults(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChildren(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInfants(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.Travellers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.Travellers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.Travellers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.Travellers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdults();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getChildren();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getInfants();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 adults = 1;
 * @return {number}
 */
proto.elude.proto.Travellers.prototype.getAdults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Travellers} returns this
 */
proto.elude.proto.Travellers.prototype.setAdults = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 children = 2;
 * @return {number}
 */
proto.elude.proto.Travellers.prototype.getChildren = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Travellers} returns this
 */
proto.elude.proto.Travellers.prototype.setChildren = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 infants = 3;
 * @return {number}
 */
proto.elude.proto.Travellers.prototype.getInfants = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Travellers} returns this
 */
proto.elude.proto.Travellers.prototype.setInfants = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.GDSFlight.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GDSFlight.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GDSFlight.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GDSFlight} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GDSFlight.toObject = function(includeInstance, msg) {
  var f, obj = {
    duration: jspb.Message.getFieldWithDefault(msg, 1, ""),
    flightSegmentsList: jspb.Message.toObjectList(msg.getFlightSegmentsList(),
    proto.elude.proto.GDSFlightSegment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GDSFlight}
 */
proto.elude.proto.GDSFlight.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GDSFlight;
  return proto.elude.proto.GDSFlight.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GDSFlight} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GDSFlight}
 */
proto.elude.proto.GDSFlight.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDuration(value);
      break;
    case 2:
      var value = new proto.elude.proto.GDSFlightSegment;
      reader.readMessage(value,proto.elude.proto.GDSFlightSegment.deserializeBinaryFromReader);
      msg.addFlightSegments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GDSFlight.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GDSFlight.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GDSFlight} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GDSFlight.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDuration();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFlightSegmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.elude.proto.GDSFlightSegment.serializeBinaryToWriter
    );
  }
};


/**
 * optional string duration = 1;
 * @return {string}
 */
proto.elude.proto.GDSFlight.prototype.getDuration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSFlight} returns this
 */
proto.elude.proto.GDSFlight.prototype.setDuration = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated GDSFlightSegment flight_segments = 2;
 * @return {!Array<!proto.elude.proto.GDSFlightSegment>}
 */
proto.elude.proto.GDSFlight.prototype.getFlightSegmentsList = function() {
  return /** @type{!Array<!proto.elude.proto.GDSFlightSegment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.elude.proto.GDSFlightSegment, 2));
};


/**
 * @param {!Array<!proto.elude.proto.GDSFlightSegment>} value
 * @return {!proto.elude.proto.GDSFlight} returns this
*/
proto.elude.proto.GDSFlight.prototype.setFlightSegmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.elude.proto.GDSFlightSegment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.GDSFlightSegment}
 */
proto.elude.proto.GDSFlight.prototype.addFlightSegments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.elude.proto.GDSFlightSegment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.GDSFlight} returns this
 */
proto.elude.proto.GDSFlight.prototype.clearFlightSegmentsList = function() {
  return this.setFlightSegmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GDSFlightSegment.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GDSFlightSegment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GDSFlightSegment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GDSFlightSegment.toObject = function(includeInstance, msg) {
  var f, obj = {
    airline: (f = msg.getAirline()) && proto_models_airline_pb.Airline.toObject(includeInstance, f),
    duration: jspb.Message.getFieldWithDefault(msg, 2, ""),
    flightNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    departureDatetime: (f = msg.getDepartureDatetime()) && proto_commons_timestamp_pb.Timestamp.toObject(includeInstance, f),
    arrivalDatetime: (f = msg.getArrivalDatetime()) && proto_commons_timestamp_pb.Timestamp.toObject(includeInstance, f),
    departureAirports: (f = msg.getDepartureAirports()) && proto_models_city_pb.Airport.toObject(includeInstance, f),
    arrivalAirports: (f = msg.getArrivalAirports()) && proto_models_city_pb.Airport.toObject(includeInstance, f),
    departureUtcOffset: jspb.Message.getFieldWithDefault(msg, 8, ""),
    arrivalUtcOffset: jspb.Message.getFieldWithDefault(msg, 9, ""),
    departureTerminal: jspb.Message.getFieldWithDefault(msg, 10, ""),
    arrivalTerminal: jspb.Message.getFieldWithDefault(msg, 11, ""),
    operatingAirlineIataCode: jspb.Message.getFieldWithDefault(msg, 12, ""),
    gate: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GDSFlightSegment}
 */
proto.elude.proto.GDSFlightSegment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GDSFlightSegment;
  return proto.elude.proto.GDSFlightSegment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GDSFlightSegment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GDSFlightSegment}
 */
proto.elude.proto.GDSFlightSegment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_models_airline_pb.Airline;
      reader.readMessage(value,proto_models_airline_pb.Airline.deserializeBinaryFromReader);
      msg.setAirline(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDuration(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightNumber(value);
      break;
    case 4:
      var value = new proto_commons_timestamp_pb.Timestamp;
      reader.readMessage(value,proto_commons_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDepartureDatetime(value);
      break;
    case 5:
      var value = new proto_commons_timestamp_pb.Timestamp;
      reader.readMessage(value,proto_commons_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setArrivalDatetime(value);
      break;
    case 6:
      var value = new proto_models_city_pb.Airport;
      reader.readMessage(value,proto_models_city_pb.Airport.deserializeBinaryFromReader);
      msg.setDepartureAirports(value);
      break;
    case 7:
      var value = new proto_models_city_pb.Airport;
      reader.readMessage(value,proto_models_city_pb.Airport.deserializeBinaryFromReader);
      msg.setArrivalAirports(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartureUtcOffset(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivalUtcOffset(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartureTerminal(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivalTerminal(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperatingAirlineIataCode(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setGate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GDSFlightSegment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GDSFlightSegment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GDSFlightSegment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GDSFlightSegment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAirline();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_models_airline_pb.Airline.serializeBinaryToWriter
    );
  }
  f = message.getDuration();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFlightNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDepartureDatetime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getArrivalDatetime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_commons_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDepartureAirports();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_models_city_pb.Airport.serializeBinaryToWriter
    );
  }
  f = message.getArrivalAirports();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_models_city_pb.Airport.serializeBinaryToWriter
    );
  }
  f = message.getDepartureUtcOffset();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getArrivalUtcOffset();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDepartureTerminal();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getArrivalTerminal();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getOperatingAirlineIataCode();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getGate();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional Airline airline = 1;
 * @return {?proto.elude.proto.Airline}
 */
proto.elude.proto.GDSFlightSegment.prototype.getAirline = function() {
  return /** @type{?proto.elude.proto.Airline} */ (
    jspb.Message.getWrapperField(this, proto_models_airline_pb.Airline, 1));
};


/**
 * @param {?proto.elude.proto.Airline|undefined} value
 * @return {!proto.elude.proto.GDSFlightSegment} returns this
*/
proto.elude.proto.GDSFlightSegment.prototype.setAirline = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GDSFlightSegment} returns this
 */
proto.elude.proto.GDSFlightSegment.prototype.clearAirline = function() {
  return this.setAirline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GDSFlightSegment.prototype.hasAirline = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string duration = 2;
 * @return {string}
 */
proto.elude.proto.GDSFlightSegment.prototype.getDuration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSFlightSegment} returns this
 */
proto.elude.proto.GDSFlightSegment.prototype.setDuration = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string flight_number = 3;
 * @return {string}
 */
proto.elude.proto.GDSFlightSegment.prototype.getFlightNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSFlightSegment} returns this
 */
proto.elude.proto.GDSFlightSegment.prototype.setFlightNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Timestamp departure_datetime = 4;
 * @return {?proto.elude.proto.Timestamp}
 */
proto.elude.proto.GDSFlightSegment.prototype.getDepartureDatetime = function() {
  return /** @type{?proto.elude.proto.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto_commons_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.elude.proto.Timestamp|undefined} value
 * @return {!proto.elude.proto.GDSFlightSegment} returns this
*/
proto.elude.proto.GDSFlightSegment.prototype.setDepartureDatetime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GDSFlightSegment} returns this
 */
proto.elude.proto.GDSFlightSegment.prototype.clearDepartureDatetime = function() {
  return this.setDepartureDatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GDSFlightSegment.prototype.hasDepartureDatetime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Timestamp arrival_datetime = 5;
 * @return {?proto.elude.proto.Timestamp}
 */
proto.elude.proto.GDSFlightSegment.prototype.getArrivalDatetime = function() {
  return /** @type{?proto.elude.proto.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto_commons_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.elude.proto.Timestamp|undefined} value
 * @return {!proto.elude.proto.GDSFlightSegment} returns this
*/
proto.elude.proto.GDSFlightSegment.prototype.setArrivalDatetime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GDSFlightSegment} returns this
 */
proto.elude.proto.GDSFlightSegment.prototype.clearArrivalDatetime = function() {
  return this.setArrivalDatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GDSFlightSegment.prototype.hasArrivalDatetime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Airport departure_airports = 6;
 * @return {?proto.elude.proto.Airport}
 */
proto.elude.proto.GDSFlightSegment.prototype.getDepartureAirports = function() {
  return /** @type{?proto.elude.proto.Airport} */ (
    jspb.Message.getWrapperField(this, proto_models_city_pb.Airport, 6));
};


/**
 * @param {?proto.elude.proto.Airport|undefined} value
 * @return {!proto.elude.proto.GDSFlightSegment} returns this
*/
proto.elude.proto.GDSFlightSegment.prototype.setDepartureAirports = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GDSFlightSegment} returns this
 */
proto.elude.proto.GDSFlightSegment.prototype.clearDepartureAirports = function() {
  return this.setDepartureAirports(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GDSFlightSegment.prototype.hasDepartureAirports = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Airport arrival_airports = 7;
 * @return {?proto.elude.proto.Airport}
 */
proto.elude.proto.GDSFlightSegment.prototype.getArrivalAirports = function() {
  return /** @type{?proto.elude.proto.Airport} */ (
    jspb.Message.getWrapperField(this, proto_models_city_pb.Airport, 7));
};


/**
 * @param {?proto.elude.proto.Airport|undefined} value
 * @return {!proto.elude.proto.GDSFlightSegment} returns this
*/
proto.elude.proto.GDSFlightSegment.prototype.setArrivalAirports = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GDSFlightSegment} returns this
 */
proto.elude.proto.GDSFlightSegment.prototype.clearArrivalAirports = function() {
  return this.setArrivalAirports(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GDSFlightSegment.prototype.hasArrivalAirports = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string departure_utc_offset = 8;
 * @return {string}
 */
proto.elude.proto.GDSFlightSegment.prototype.getDepartureUtcOffset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSFlightSegment} returns this
 */
proto.elude.proto.GDSFlightSegment.prototype.setDepartureUtcOffset = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string arrival_utc_offset = 9;
 * @return {string}
 */
proto.elude.proto.GDSFlightSegment.prototype.getArrivalUtcOffset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSFlightSegment} returns this
 */
proto.elude.proto.GDSFlightSegment.prototype.setArrivalUtcOffset = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string departure_terminal = 10;
 * @return {string}
 */
proto.elude.proto.GDSFlightSegment.prototype.getDepartureTerminal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSFlightSegment} returns this
 */
proto.elude.proto.GDSFlightSegment.prototype.setDepartureTerminal = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string arrival_terminal = 11;
 * @return {string}
 */
proto.elude.proto.GDSFlightSegment.prototype.getArrivalTerminal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSFlightSegment} returns this
 */
proto.elude.proto.GDSFlightSegment.prototype.setArrivalTerminal = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string operating_airline_iata_code = 12;
 * @return {string}
 */
proto.elude.proto.GDSFlightSegment.prototype.getOperatingAirlineIataCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSFlightSegment} returns this
 */
proto.elude.proto.GDSFlightSegment.prototype.setOperatingAirlineIataCode = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string gate = 13;
 * @return {string}
 */
proto.elude.proto.GDSFlightSegment.prototype.getGate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSFlightSegment} returns this
 */
proto.elude.proto.GDSFlightSegment.prototype.setGate = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.FlightOption.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.FlightOption.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.FlightOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.FlightOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.FlightOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dates: (f = msg.getDates()) && proto.elude.proto.Dates.toObject(includeInstance, f),
    departureFlight: (f = msg.getDepartureFlight()) && proto.elude.proto.GDSFlight.toObject(includeInstance, f),
    returnFlight: (f = msg.getReturnFlight()) && proto.elude.proto.GDSFlight.toObject(includeInstance, f),
    price: (f = msg.getPrice()) && proto.elude.proto.Price.toObject(includeInstance, f),
    travellerInfoList: jspb.Message.toObjectList(msg.getTravellerInfoList(),
    proto.elude.proto.TravellerInfo.toObject, includeInstance),
    gds: (f = msg.getGds()) && proto.elude.proto.GDSBackend.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.FlightOption}
 */
proto.elude.proto.FlightOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.FlightOption;
  return proto.elude.proto.FlightOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.FlightOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.FlightOption}
 */
proto.elude.proto.FlightOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.elude.proto.Dates;
      reader.readMessage(value,proto.elude.proto.Dates.deserializeBinaryFromReader);
      msg.setDates(value);
      break;
    case 3:
      var value = new proto.elude.proto.GDSFlight;
      reader.readMessage(value,proto.elude.proto.GDSFlight.deserializeBinaryFromReader);
      msg.setDepartureFlight(value);
      break;
    case 4:
      var value = new proto.elude.proto.GDSFlight;
      reader.readMessage(value,proto.elude.proto.GDSFlight.deserializeBinaryFromReader);
      msg.setReturnFlight(value);
      break;
    case 5:
      var value = new proto.elude.proto.Price;
      reader.readMessage(value,proto.elude.proto.Price.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    case 6:
      var value = new proto.elude.proto.TravellerInfo;
      reader.readMessage(value,proto.elude.proto.TravellerInfo.deserializeBinaryFromReader);
      msg.addTravellerInfo(value);
      break;
    case 7:
      var value = new proto.elude.proto.GDSBackend;
      reader.readMessage(value,proto.elude.proto.GDSBackend.deserializeBinaryFromReader);
      msg.setGds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.FlightOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.FlightOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.FlightOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.FlightOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDates();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.elude.proto.Dates.serializeBinaryToWriter
    );
  }
  f = message.getDepartureFlight();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.elude.proto.GDSFlight.serializeBinaryToWriter
    );
  }
  f = message.getReturnFlight();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.elude.proto.GDSFlight.serializeBinaryToWriter
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.elude.proto.Price.serializeBinaryToWriter
    );
  }
  f = message.getTravellerInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.elude.proto.TravellerInfo.serializeBinaryToWriter
    );
  }
  f = message.getGds();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.elude.proto.GDSBackend.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.elude.proto.FlightOption.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.FlightOption} returns this
 */
proto.elude.proto.FlightOption.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Dates dates = 2;
 * @return {?proto.elude.proto.Dates}
 */
proto.elude.proto.FlightOption.prototype.getDates = function() {
  return /** @type{?proto.elude.proto.Dates} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.Dates, 2));
};


/**
 * @param {?proto.elude.proto.Dates|undefined} value
 * @return {!proto.elude.proto.FlightOption} returns this
*/
proto.elude.proto.FlightOption.prototype.setDates = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.FlightOption} returns this
 */
proto.elude.proto.FlightOption.prototype.clearDates = function() {
  return this.setDates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.FlightOption.prototype.hasDates = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional GDSFlight departure_flight = 3;
 * @return {?proto.elude.proto.GDSFlight}
 */
proto.elude.proto.FlightOption.prototype.getDepartureFlight = function() {
  return /** @type{?proto.elude.proto.GDSFlight} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.GDSFlight, 3));
};


/**
 * @param {?proto.elude.proto.GDSFlight|undefined} value
 * @return {!proto.elude.proto.FlightOption} returns this
*/
proto.elude.proto.FlightOption.prototype.setDepartureFlight = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.FlightOption} returns this
 */
proto.elude.proto.FlightOption.prototype.clearDepartureFlight = function() {
  return this.setDepartureFlight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.FlightOption.prototype.hasDepartureFlight = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional GDSFlight return_flight = 4;
 * @return {?proto.elude.proto.GDSFlight}
 */
proto.elude.proto.FlightOption.prototype.getReturnFlight = function() {
  return /** @type{?proto.elude.proto.GDSFlight} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.GDSFlight, 4));
};


/**
 * @param {?proto.elude.proto.GDSFlight|undefined} value
 * @return {!proto.elude.proto.FlightOption} returns this
*/
proto.elude.proto.FlightOption.prototype.setReturnFlight = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.FlightOption} returns this
 */
proto.elude.proto.FlightOption.prototype.clearReturnFlight = function() {
  return this.setReturnFlight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.FlightOption.prototype.hasReturnFlight = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Price price = 5;
 * @return {?proto.elude.proto.Price}
 */
proto.elude.proto.FlightOption.prototype.getPrice = function() {
  return /** @type{?proto.elude.proto.Price} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.Price, 5));
};


/**
 * @param {?proto.elude.proto.Price|undefined} value
 * @return {!proto.elude.proto.FlightOption} returns this
*/
proto.elude.proto.FlightOption.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.FlightOption} returns this
 */
proto.elude.proto.FlightOption.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.FlightOption.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated TravellerInfo traveller_info = 6;
 * @return {!Array<!proto.elude.proto.TravellerInfo>}
 */
proto.elude.proto.FlightOption.prototype.getTravellerInfoList = function() {
  return /** @type{!Array<!proto.elude.proto.TravellerInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.elude.proto.TravellerInfo, 6));
};


/**
 * @param {!Array<!proto.elude.proto.TravellerInfo>} value
 * @return {!proto.elude.proto.FlightOption} returns this
*/
proto.elude.proto.FlightOption.prototype.setTravellerInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.elude.proto.TravellerInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.TravellerInfo}
 */
proto.elude.proto.FlightOption.prototype.addTravellerInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.elude.proto.TravellerInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.FlightOption} returns this
 */
proto.elude.proto.FlightOption.prototype.clearTravellerInfoList = function() {
  return this.setTravellerInfoList([]);
};


/**
 * optional GDSBackend gds = 7;
 * @return {?proto.elude.proto.GDSBackend}
 */
proto.elude.proto.FlightOption.prototype.getGds = function() {
  return /** @type{?proto.elude.proto.GDSBackend} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.GDSBackend, 7));
};


/**
 * @param {?proto.elude.proto.GDSBackend|undefined} value
 * @return {!proto.elude.proto.FlightOption} returns this
*/
proto.elude.proto.FlightOption.prototype.setGds = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.FlightOption} returns this
 */
proto.elude.proto.FlightOption.prototype.clearGds = function() {
  return this.setGds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.FlightOption.prototype.hasGds = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.HotelRequirements.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.HotelRequirements.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.HotelRequirements} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.HotelRequirements.toObject = function(includeInstance, msg) {
  var f, obj = {
    minStars: jspb.Message.getFieldWithDefault(msg, 1, 0),
    amenities: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    disable: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    maxStars: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.HotelRequirements}
 */
proto.elude.proto.HotelRequirements.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.HotelRequirements;
  return proto.elude.proto.HotelRequirements.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.HotelRequirements} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.HotelRequirements}
 */
proto.elude.proto.HotelRequirements.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinStars(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAmenities(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisable(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxStars(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.HotelRequirements.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.HotelRequirements.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.HotelRequirements} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.HotelRequirements.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMinStars();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAmenities();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getDisable();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getMaxStars();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 min_stars = 1;
 * @return {number}
 */
proto.elude.proto.HotelRequirements.prototype.getMinStars = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.HotelRequirements} returns this
 */
proto.elude.proto.HotelRequirements.prototype.setMinStars = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool amenities = 2;
 * @return {boolean}
 */
proto.elude.proto.HotelRequirements.prototype.getAmenities = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.elude.proto.HotelRequirements} returns this
 */
proto.elude.proto.HotelRequirements.prototype.setAmenities = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool disable = 3;
 * @return {boolean}
 */
proto.elude.proto.HotelRequirements.prototype.getDisable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.elude.proto.HotelRequirements} returns this
 */
proto.elude.proto.HotelRequirements.prototype.setDisable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int32 max_stars = 4;
 * @return {number}
 */
proto.elude.proto.HotelRequirements.prototype.getMaxStars = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.HotelRequirements} returns this
 */
proto.elude.proto.HotelRequirements.prototype.setMaxStars = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.AirRequirements.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.AirRequirements.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.AirRequirements.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.AirRequirements} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.AirRequirements.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingClass: jspb.Message.getFieldWithDefault(msg, 1, 0),
    disabledAirlinesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    disable: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    maxStops: jspb.Message.getFieldWithDefault(msg, 4, 0),
    flightType: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.AirRequirements}
 */
proto.elude.proto.AirRequirements.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.AirRequirements;
  return proto.elude.proto.AirRequirements.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.AirRequirements} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.AirRequirements}
 */
proto.elude.proto.AirRequirements.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.elude.proto.BookingClassEnum} */ (reader.readEnum());
      msg.setBookingClass(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addDisabledAirlines(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisable(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxStops(value);
      break;
    case 5:
      var value = /** @type {!proto.elude.proto.FlightTypeEnum} */ (reader.readEnum());
      msg.setFlightType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.AirRequirements.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.AirRequirements.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.AirRequirements} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.AirRequirements.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingClass();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDisabledAirlinesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getDisable();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getFlightType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional BookingClassEnum booking_class = 1;
 * @return {!proto.elude.proto.BookingClassEnum}
 */
proto.elude.proto.AirRequirements.prototype.getBookingClass = function() {
  return /** @type {!proto.elude.proto.BookingClassEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.elude.proto.BookingClassEnum} value
 * @return {!proto.elude.proto.AirRequirements} returns this
 */
proto.elude.proto.AirRequirements.prototype.setBookingClass = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated string disabled_airlines = 2;
 * @return {!Array<string>}
 */
proto.elude.proto.AirRequirements.prototype.getDisabledAirlinesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.elude.proto.AirRequirements} returns this
 */
proto.elude.proto.AirRequirements.prototype.setDisabledAirlinesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.AirRequirements} returns this
 */
proto.elude.proto.AirRequirements.prototype.addDisabledAirlines = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.AirRequirements} returns this
 */
proto.elude.proto.AirRequirements.prototype.clearDisabledAirlinesList = function() {
  return this.setDisabledAirlinesList([]);
};


/**
 * optional bool disable = 3;
 * @return {boolean}
 */
proto.elude.proto.AirRequirements.prototype.getDisable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.elude.proto.AirRequirements} returns this
 */
proto.elude.proto.AirRequirements.prototype.setDisable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int32 max_stops = 4;
 * @return {number}
 */
proto.elude.proto.AirRequirements.prototype.getMaxStops = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.AirRequirements} returns this
 */
proto.elude.proto.AirRequirements.prototype.setMaxStops = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.elude.proto.AirRequirements} returns this
 */
proto.elude.proto.AirRequirements.prototype.clearMaxStops = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.AirRequirements.prototype.hasMaxStops = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional FlightTypeEnum flight_type = 5;
 * @return {!proto.elude.proto.FlightTypeEnum}
 */
proto.elude.proto.AirRequirements.prototype.getFlightType = function() {
  return /** @type {!proto.elude.proto.FlightTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.elude.proto.FlightTypeEnum} value
 * @return {!proto.elude.proto.AirRequirements} returns this
 */
proto.elude.proto.AirRequirements.prototype.setFlightType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.LocationIdentifier.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.LocationIdentifier.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.LocationIdentifier.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.LocationIdentifier} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.LocationIdentifier.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    locationType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    iataCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    locationName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    priorityHotelsSabreList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    imageUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    description: jspb.Message.getFieldWithDefault(msg, 7, ""),
    hotelAddressSearch: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    metadata: (f = msg.getMetadata()) && proto_commons_metadata_pb.LocationMetadata.toObject(includeInstance, f),
    shardId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    isRecommendedLocation: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.LocationIdentifier}
 */
proto.elude.proto.LocationIdentifier.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.LocationIdentifier;
  return proto.elude.proto.LocationIdentifier.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.LocationIdentifier} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.LocationIdentifier}
 */
proto.elude.proto.LocationIdentifier.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.elude.proto.LocationTypeEnum} */ (reader.readEnum());
      msg.setLocationType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIataCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addPriorityHotelsSabre(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHotelAddressSearch(value);
      break;
    case 9:
      var value = new proto_commons_metadata_pb.LocationMetadata;
      reader.readMessage(value,proto_commons_metadata_pb.LocationMetadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setShardId(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRecommendedLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.LocationIdentifier.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.LocationIdentifier.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.LocationIdentifier} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.LocationIdentifier.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLocationType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getIataCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLocationName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPriorityHotelsSabreList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getHotelAddressSearch();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_commons_metadata_pb.LocationMetadata.serializeBinaryToWriter
    );
  }
  f = message.getShardId();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getIsRecommendedLocation();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.elude.proto.LocationIdentifier.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.LocationIdentifier} returns this
 */
proto.elude.proto.LocationIdentifier.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional LocationTypeEnum location_type = 2;
 * @return {!proto.elude.proto.LocationTypeEnum}
 */
proto.elude.proto.LocationIdentifier.prototype.getLocationType = function() {
  return /** @type {!proto.elude.proto.LocationTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.elude.proto.LocationTypeEnum} value
 * @return {!proto.elude.proto.LocationIdentifier} returns this
 */
proto.elude.proto.LocationIdentifier.prototype.setLocationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string iata_code = 3;
 * @return {string}
 */
proto.elude.proto.LocationIdentifier.prototype.getIataCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.LocationIdentifier} returns this
 */
proto.elude.proto.LocationIdentifier.prototype.setIataCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string location_name = 4;
 * @return {string}
 */
proto.elude.proto.LocationIdentifier.prototype.getLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.LocationIdentifier} returns this
 */
proto.elude.proto.LocationIdentifier.prototype.setLocationName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string priority_hotels_sabre = 5;
 * @return {!Array<string>}
 */
proto.elude.proto.LocationIdentifier.prototype.getPriorityHotelsSabreList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.elude.proto.LocationIdentifier} returns this
 */
proto.elude.proto.LocationIdentifier.prototype.setPriorityHotelsSabreList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.LocationIdentifier} returns this
 */
proto.elude.proto.LocationIdentifier.prototype.addPriorityHotelsSabre = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.LocationIdentifier} returns this
 */
proto.elude.proto.LocationIdentifier.prototype.clearPriorityHotelsSabreList = function() {
  return this.setPriorityHotelsSabreList([]);
};


/**
 * optional string image_url = 6;
 * @return {string}
 */
proto.elude.proto.LocationIdentifier.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.LocationIdentifier} returns this
 */
proto.elude.proto.LocationIdentifier.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string description = 7;
 * @return {string}
 */
proto.elude.proto.LocationIdentifier.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.LocationIdentifier} returns this
 */
proto.elude.proto.LocationIdentifier.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool hotel_address_search = 8;
 * @return {boolean}
 */
proto.elude.proto.LocationIdentifier.prototype.getHotelAddressSearch = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.elude.proto.LocationIdentifier} returns this
 */
proto.elude.proto.LocationIdentifier.prototype.setHotelAddressSearch = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional LocationMetadata metadata = 9;
 * @return {?proto.elude.proto.LocationMetadata}
 */
proto.elude.proto.LocationIdentifier.prototype.getMetadata = function() {
  return /** @type{?proto.elude.proto.LocationMetadata} */ (
    jspb.Message.getWrapperField(this, proto_commons_metadata_pb.LocationMetadata, 9));
};


/**
 * @param {?proto.elude.proto.LocationMetadata|undefined} value
 * @return {!proto.elude.proto.LocationIdentifier} returns this
*/
proto.elude.proto.LocationIdentifier.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.LocationIdentifier} returns this
 */
proto.elude.proto.LocationIdentifier.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.LocationIdentifier.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int32 shard_id = 10;
 * @return {number}
 */
proto.elude.proto.LocationIdentifier.prototype.getShardId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.LocationIdentifier} returns this
 */
proto.elude.proto.LocationIdentifier.prototype.setShardId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional bool is_recommended_location = 11;
 * @return {boolean}
 */
proto.elude.proto.LocationIdentifier.prototype.getIsRecommendedLocation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.elude.proto.LocationIdentifier} returns this
 */
proto.elude.proto.LocationIdentifier.prototype.setIsRecommendedLocation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.LocationsRequirements.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.LocationsRequirements.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.LocationsRequirements.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.LocationsRequirements} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.LocationsRequirements.toObject = function(includeInstance, msg) {
  var f, obj = {
    departure: (f = msg.getDeparture()) && proto.elude.proto.LocationIdentifier.toObject(includeInstance, f),
    destinationsList: jspb.Message.toObjectList(msg.getDestinationsList(),
    proto.elude.proto.LocationIdentifier.toObject, includeInstance),
    blacklistedCitiesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.LocationsRequirements}
 */
proto.elude.proto.LocationsRequirements.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.LocationsRequirements;
  return proto.elude.proto.LocationsRequirements.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.LocationsRequirements} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.LocationsRequirements}
 */
proto.elude.proto.LocationsRequirements.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.elude.proto.LocationIdentifier;
      reader.readMessage(value,proto.elude.proto.LocationIdentifier.deserializeBinaryFromReader);
      msg.setDeparture(value);
      break;
    case 2:
      var value = new proto.elude.proto.LocationIdentifier;
      reader.readMessage(value,proto.elude.proto.LocationIdentifier.deserializeBinaryFromReader);
      msg.addDestinations(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addBlacklistedCities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.LocationsRequirements.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.LocationsRequirements.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.LocationsRequirements} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.LocationsRequirements.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeparture();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.elude.proto.LocationIdentifier.serializeBinaryToWriter
    );
  }
  f = message.getDestinationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.elude.proto.LocationIdentifier.serializeBinaryToWriter
    );
  }
  f = message.getBlacklistedCitiesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional LocationIdentifier departure = 1;
 * @return {?proto.elude.proto.LocationIdentifier}
 */
proto.elude.proto.LocationsRequirements.prototype.getDeparture = function() {
  return /** @type{?proto.elude.proto.LocationIdentifier} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.LocationIdentifier, 1));
};


/**
 * @param {?proto.elude.proto.LocationIdentifier|undefined} value
 * @return {!proto.elude.proto.LocationsRequirements} returns this
*/
proto.elude.proto.LocationsRequirements.prototype.setDeparture = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.LocationsRequirements} returns this
 */
proto.elude.proto.LocationsRequirements.prototype.clearDeparture = function() {
  return this.setDeparture(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.LocationsRequirements.prototype.hasDeparture = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated LocationIdentifier destinations = 2;
 * @return {!Array<!proto.elude.proto.LocationIdentifier>}
 */
proto.elude.proto.LocationsRequirements.prototype.getDestinationsList = function() {
  return /** @type{!Array<!proto.elude.proto.LocationIdentifier>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.elude.proto.LocationIdentifier, 2));
};


/**
 * @param {!Array<!proto.elude.proto.LocationIdentifier>} value
 * @return {!proto.elude.proto.LocationsRequirements} returns this
*/
proto.elude.proto.LocationsRequirements.prototype.setDestinationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.elude.proto.LocationIdentifier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.LocationIdentifier}
 */
proto.elude.proto.LocationsRequirements.prototype.addDestinations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.elude.proto.LocationIdentifier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.LocationsRequirements} returns this
 */
proto.elude.proto.LocationsRequirements.prototype.clearDestinationsList = function() {
  return this.setDestinationsList([]);
};


/**
 * repeated string blacklisted_cities = 3;
 * @return {!Array<string>}
 */
proto.elude.proto.LocationsRequirements.prototype.getBlacklistedCitiesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.elude.proto.LocationsRequirements} returns this
 */
proto.elude.proto.LocationsRequirements.prototype.setBlacklistedCitiesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.LocationsRequirements} returns this
 */
proto.elude.proto.LocationsRequirements.prototype.addBlacklistedCities = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.LocationsRequirements} returns this
 */
proto.elude.proto.LocationsRequirements.prototype.clearBlacklistedCitiesList = function() {
  return this.setBlacklistedCitiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.PriceQuote.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.PriceQuote.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.PriceQuote} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.PriceQuote.toObject = function(includeInstance, msg) {
  var f, obj = {
    identifier: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.PriceQuote}
 */
proto.elude.proto.PriceQuote.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.PriceQuote;
  return proto.elude.proto.PriceQuote.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.PriceQuote} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.PriceQuote}
 */
proto.elude.proto.PriceQuote.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIdentifier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.PriceQuote.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.PriceQuote.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.PriceQuote} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.PriceQuote.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentifier();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 identifier = 1;
 * @return {number}
 */
proto.elude.proto.PriceQuote.prototype.getIdentifier = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.PriceQuote} returns this
 */
proto.elude.proto.PriceQuote.prototype.setIdentifier = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.Price.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.Price.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.Price} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.Price.toObject = function(includeInstance, msg) {
  var f, obj = {
    currency: jspb.Message.getFieldWithDefault(msg, 1, ""),
    total: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    base: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    tax: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    agentFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    basePriceDifference: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    totalRounded: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.Price}
 */
proto.elude.proto.Price.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.Price;
  return proto.elude.proto.Price.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.Price} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.Price}
 */
proto.elude.proto.Price.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotal(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBase(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTax(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAgentFee(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBasePriceDifference(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalRounded(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.Price.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.Price.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.Price} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.Price.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getBase();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getTax();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getAgentFee();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getBasePriceDifference();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getTotalRounded();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
};


/**
 * optional string currency = 1;
 * @return {string}
 */
proto.elude.proto.Price.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Price} returns this
 */
proto.elude.proto.Price.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float total = 2;
 * @return {number}
 */
proto.elude.proto.Price.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Price} returns this
 */
proto.elude.proto.Price.prototype.setTotal = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float base = 3;
 * @return {number}
 */
proto.elude.proto.Price.prototype.getBase = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Price} returns this
 */
proto.elude.proto.Price.prototype.setBase = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float tax = 4;
 * @return {number}
 */
proto.elude.proto.Price.prototype.getTax = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Price} returns this
 */
proto.elude.proto.Price.prototype.setTax = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float agent_fee = 5;
 * @return {number}
 */
proto.elude.proto.Price.prototype.getAgentFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Price} returns this
 */
proto.elude.proto.Price.prototype.setAgentFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float base_price_difference = 6;
 * @return {number}
 */
proto.elude.proto.Price.prototype.getBasePriceDifference = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Price} returns this
 */
proto.elude.proto.Price.prototype.setBasePriceDifference = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float total_rounded = 7;
 * @return {number}
 */
proto.elude.proto.Price.prototype.getTotalRounded = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Price} returns this
 */
proto.elude.proto.Price.prototype.setTotalRounded = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.GDSItinerary.repeatedFields_ = [4,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GDSItinerary.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GDSItinerary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GDSItinerary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GDSItinerary.toObject = function(includeInstance, msg) {
  var f, obj = {
    flightOption: (f = msg.getFlightOption()) && proto.elude.proto.FlightOption.toObject(includeInstance, f),
    hotel: (f = msg.getHotel()) && proto.elude.proto.GDSHotel.toObject(includeInstance, f),
    price: (f = msg.getPrice()) && proto.elude.proto.Price.toObject(includeInstance, f),
    travellersList: jspb.Message.toObjectList(msg.getTravellersList(),
    proto.elude.proto.GDSTraveller.toObject, includeInstance),
    user: (f = msg.getUser()) && proto.elude.proto.GDSUser.toObject(includeInstance, f),
    dates: (f = msg.getDates()) && proto.elude.proto.Dates.toObject(includeInstance, f),
    departureLocation: (f = msg.getDepartureLocation()) && proto.elude.proto.LocationIdentifier.toObject(includeInstance, f),
    destinationLocation: (f = msg.getDestinationLocation()) && proto.elude.proto.LocationIdentifier.toObject(includeInstance, f),
    priceQuotesList: jspb.Message.toObjectList(msg.getPriceQuotesList(),
    proto.elude.proto.PriceQuote.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GDSItinerary}
 */
proto.elude.proto.GDSItinerary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GDSItinerary;
  return proto.elude.proto.GDSItinerary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GDSItinerary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GDSItinerary}
 */
proto.elude.proto.GDSItinerary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.elude.proto.FlightOption;
      reader.readMessage(value,proto.elude.proto.FlightOption.deserializeBinaryFromReader);
      msg.setFlightOption(value);
      break;
    case 2:
      var value = new proto.elude.proto.GDSHotel;
      reader.readMessage(value,proto.elude.proto.GDSHotel.deserializeBinaryFromReader);
      msg.setHotel(value);
      break;
    case 3:
      var value = new proto.elude.proto.Price;
      reader.readMessage(value,proto.elude.proto.Price.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    case 4:
      var value = new proto.elude.proto.GDSTraveller;
      reader.readMessage(value,proto.elude.proto.GDSTraveller.deserializeBinaryFromReader);
      msg.addTravellers(value);
      break;
    case 5:
      var value = new proto.elude.proto.GDSUser;
      reader.readMessage(value,proto.elude.proto.GDSUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 6:
      var value = new proto.elude.proto.Dates;
      reader.readMessage(value,proto.elude.proto.Dates.deserializeBinaryFromReader);
      msg.setDates(value);
      break;
    case 7:
      var value = new proto.elude.proto.LocationIdentifier;
      reader.readMessage(value,proto.elude.proto.LocationIdentifier.deserializeBinaryFromReader);
      msg.setDepartureLocation(value);
      break;
    case 8:
      var value = new proto.elude.proto.LocationIdentifier;
      reader.readMessage(value,proto.elude.proto.LocationIdentifier.deserializeBinaryFromReader);
      msg.setDestinationLocation(value);
      break;
    case 9:
      var value = new proto.elude.proto.PriceQuote;
      reader.readMessage(value,proto.elude.proto.PriceQuote.deserializeBinaryFromReader);
      msg.addPriceQuotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GDSItinerary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GDSItinerary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GDSItinerary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GDSItinerary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightOption();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.elude.proto.FlightOption.serializeBinaryToWriter
    );
  }
  f = message.getHotel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.elude.proto.GDSHotel.serializeBinaryToWriter
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.elude.proto.Price.serializeBinaryToWriter
    );
  }
  f = message.getTravellersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.elude.proto.GDSTraveller.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.elude.proto.GDSUser.serializeBinaryToWriter
    );
  }
  f = message.getDates();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.elude.proto.Dates.serializeBinaryToWriter
    );
  }
  f = message.getDepartureLocation();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.elude.proto.LocationIdentifier.serializeBinaryToWriter
    );
  }
  f = message.getDestinationLocation();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.elude.proto.LocationIdentifier.serializeBinaryToWriter
    );
  }
  f = message.getPriceQuotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.elude.proto.PriceQuote.serializeBinaryToWriter
    );
  }
};


/**
 * optional FlightOption flight_option = 1;
 * @return {?proto.elude.proto.FlightOption}
 */
proto.elude.proto.GDSItinerary.prototype.getFlightOption = function() {
  return /** @type{?proto.elude.proto.FlightOption} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.FlightOption, 1));
};


/**
 * @param {?proto.elude.proto.FlightOption|undefined} value
 * @return {!proto.elude.proto.GDSItinerary} returns this
*/
proto.elude.proto.GDSItinerary.prototype.setFlightOption = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GDSItinerary} returns this
 */
proto.elude.proto.GDSItinerary.prototype.clearFlightOption = function() {
  return this.setFlightOption(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GDSItinerary.prototype.hasFlightOption = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GDSHotel hotel = 2;
 * @return {?proto.elude.proto.GDSHotel}
 */
proto.elude.proto.GDSItinerary.prototype.getHotel = function() {
  return /** @type{?proto.elude.proto.GDSHotel} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.GDSHotel, 2));
};


/**
 * @param {?proto.elude.proto.GDSHotel|undefined} value
 * @return {!proto.elude.proto.GDSItinerary} returns this
*/
proto.elude.proto.GDSItinerary.prototype.setHotel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GDSItinerary} returns this
 */
proto.elude.proto.GDSItinerary.prototype.clearHotel = function() {
  return this.setHotel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GDSItinerary.prototype.hasHotel = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Price price = 3;
 * @return {?proto.elude.proto.Price}
 */
proto.elude.proto.GDSItinerary.prototype.getPrice = function() {
  return /** @type{?proto.elude.proto.Price} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.Price, 3));
};


/**
 * @param {?proto.elude.proto.Price|undefined} value
 * @return {!proto.elude.proto.GDSItinerary} returns this
*/
proto.elude.proto.GDSItinerary.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GDSItinerary} returns this
 */
proto.elude.proto.GDSItinerary.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GDSItinerary.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated GDSTraveller travellers = 4;
 * @return {!Array<!proto.elude.proto.GDSTraveller>}
 */
proto.elude.proto.GDSItinerary.prototype.getTravellersList = function() {
  return /** @type{!Array<!proto.elude.proto.GDSTraveller>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.elude.proto.GDSTraveller, 4));
};


/**
 * @param {!Array<!proto.elude.proto.GDSTraveller>} value
 * @return {!proto.elude.proto.GDSItinerary} returns this
*/
proto.elude.proto.GDSItinerary.prototype.setTravellersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.elude.proto.GDSTraveller=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.GDSTraveller}
 */
proto.elude.proto.GDSItinerary.prototype.addTravellers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.elude.proto.GDSTraveller, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.GDSItinerary} returns this
 */
proto.elude.proto.GDSItinerary.prototype.clearTravellersList = function() {
  return this.setTravellersList([]);
};


/**
 * optional GDSUser user = 5;
 * @return {?proto.elude.proto.GDSUser}
 */
proto.elude.proto.GDSItinerary.prototype.getUser = function() {
  return /** @type{?proto.elude.proto.GDSUser} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.GDSUser, 5));
};


/**
 * @param {?proto.elude.proto.GDSUser|undefined} value
 * @return {!proto.elude.proto.GDSItinerary} returns this
*/
proto.elude.proto.GDSItinerary.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GDSItinerary} returns this
 */
proto.elude.proto.GDSItinerary.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GDSItinerary.prototype.hasUser = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Dates dates = 6;
 * @return {?proto.elude.proto.Dates}
 */
proto.elude.proto.GDSItinerary.prototype.getDates = function() {
  return /** @type{?proto.elude.proto.Dates} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.Dates, 6));
};


/**
 * @param {?proto.elude.proto.Dates|undefined} value
 * @return {!proto.elude.proto.GDSItinerary} returns this
*/
proto.elude.proto.GDSItinerary.prototype.setDates = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GDSItinerary} returns this
 */
proto.elude.proto.GDSItinerary.prototype.clearDates = function() {
  return this.setDates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GDSItinerary.prototype.hasDates = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional LocationIdentifier departure_location = 7;
 * @return {?proto.elude.proto.LocationIdentifier}
 */
proto.elude.proto.GDSItinerary.prototype.getDepartureLocation = function() {
  return /** @type{?proto.elude.proto.LocationIdentifier} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.LocationIdentifier, 7));
};


/**
 * @param {?proto.elude.proto.LocationIdentifier|undefined} value
 * @return {!proto.elude.proto.GDSItinerary} returns this
*/
proto.elude.proto.GDSItinerary.prototype.setDepartureLocation = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GDSItinerary} returns this
 */
proto.elude.proto.GDSItinerary.prototype.clearDepartureLocation = function() {
  return this.setDepartureLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GDSItinerary.prototype.hasDepartureLocation = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional LocationIdentifier destination_location = 8;
 * @return {?proto.elude.proto.LocationIdentifier}
 */
proto.elude.proto.GDSItinerary.prototype.getDestinationLocation = function() {
  return /** @type{?proto.elude.proto.LocationIdentifier} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.LocationIdentifier, 8));
};


/**
 * @param {?proto.elude.proto.LocationIdentifier|undefined} value
 * @return {!proto.elude.proto.GDSItinerary} returns this
*/
proto.elude.proto.GDSItinerary.prototype.setDestinationLocation = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GDSItinerary} returns this
 */
proto.elude.proto.GDSItinerary.prototype.clearDestinationLocation = function() {
  return this.setDestinationLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GDSItinerary.prototype.hasDestinationLocation = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated PriceQuote price_quotes = 9;
 * @return {!Array<!proto.elude.proto.PriceQuote>}
 */
proto.elude.proto.GDSItinerary.prototype.getPriceQuotesList = function() {
  return /** @type{!Array<!proto.elude.proto.PriceQuote>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.elude.proto.PriceQuote, 9));
};


/**
 * @param {!Array<!proto.elude.proto.PriceQuote>} value
 * @return {!proto.elude.proto.GDSItinerary} returns this
*/
proto.elude.proto.GDSItinerary.prototype.setPriceQuotesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.elude.proto.PriceQuote=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.PriceQuote}
 */
proto.elude.proto.GDSItinerary.prototype.addPriceQuotes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.elude.proto.PriceQuote, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.GDSItinerary} returns this
 */
proto.elude.proto.GDSItinerary.prototype.clearPriceQuotesList = function() {
  return this.setPriceQuotesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GDSTraveller.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GDSTraveller.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GDSTraveller} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GDSTraveller.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dateOfBirth: jspb.Message.getFieldWithDefault(msg, 4, ""),
    email: jspb.Message.getFieldWithDefault(msg, 6, ""),
    title: jspb.Message.getFieldWithDefault(msg, 7, ""),
    travelerType: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GDSTraveller}
 */
proto.elude.proto.GDSTraveller.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GDSTraveller;
  return proto.elude.proto.GDSTraveller.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GDSTraveller} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GDSTraveller}
 */
proto.elude.proto.GDSTraveller.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateOfBirth(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTravelerType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GDSTraveller.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GDSTraveller.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GDSTraveller} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GDSTraveller.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDateOfBirth();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTravelerType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.elude.proto.GDSTraveller.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GDSTraveller} returns this
 */
proto.elude.proto.GDSTraveller.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.elude.proto.GDSTraveller.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSTraveller} returns this
 */
proto.elude.proto.GDSTraveller.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.elude.proto.GDSTraveller.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSTraveller} returns this
 */
proto.elude.proto.GDSTraveller.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string date_of_birth = 4;
 * @return {string}
 */
proto.elude.proto.GDSTraveller.prototype.getDateOfBirth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSTraveller} returns this
 */
proto.elude.proto.GDSTraveller.prototype.setDateOfBirth = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string email = 6;
 * @return {string}
 */
proto.elude.proto.GDSTraveller.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSTraveller} returns this
 */
proto.elude.proto.GDSTraveller.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string title = 7;
 * @return {string}
 */
proto.elude.proto.GDSTraveller.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSTraveller} returns this
 */
proto.elude.proto.GDSTraveller.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string traveler_type = 8;
 * @return {string}
 */
proto.elude.proto.GDSTraveller.prototype.getTravelerType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSTraveller} returns this
 */
proto.elude.proto.GDSTraveller.prototype.setTravelerType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GDSUser.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GDSUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GDSUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GDSUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dateOfBirth: jspb.Message.getFieldWithDefault(msg, 4, ""),
    email: jspb.Message.getFieldWithDefault(msg, 6, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 7, ""),
    address: jspb.Message.getFieldWithDefault(msg, 8, ""),
    title: jspb.Message.getFieldWithDefault(msg, 9, ""),
    countryCode: jspb.Message.getFieldWithDefault(msg, 10, ""),
    countryIso: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GDSUser}
 */
proto.elude.proto.GDSUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GDSUser;
  return proto.elude.proto.GDSUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GDSUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GDSUser}
 */
proto.elude.proto.GDSUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateOfBirth(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryIso(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GDSUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GDSUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GDSUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GDSUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDateOfBirth();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCountryCode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCountryIso();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.elude.proto.GDSUser.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GDSUser} returns this
 */
proto.elude.proto.GDSUser.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.elude.proto.GDSUser.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSUser} returns this
 */
proto.elude.proto.GDSUser.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.elude.proto.GDSUser.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSUser} returns this
 */
proto.elude.proto.GDSUser.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string date_of_birth = 4;
 * @return {string}
 */
proto.elude.proto.GDSUser.prototype.getDateOfBirth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSUser} returns this
 */
proto.elude.proto.GDSUser.prototype.setDateOfBirth = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string email = 6;
 * @return {string}
 */
proto.elude.proto.GDSUser.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSUser} returns this
 */
proto.elude.proto.GDSUser.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string phone = 7;
 * @return {string}
 */
proto.elude.proto.GDSUser.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSUser} returns this
 */
proto.elude.proto.GDSUser.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string address = 8;
 * @return {string}
 */
proto.elude.proto.GDSUser.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSUser} returns this
 */
proto.elude.proto.GDSUser.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string title = 9;
 * @return {string}
 */
proto.elude.proto.GDSUser.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSUser} returns this
 */
proto.elude.proto.GDSUser.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string country_code = 10;
 * @return {string}
 */
proto.elude.proto.GDSUser.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSUser} returns this
 */
proto.elude.proto.GDSUser.prototype.setCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string country_iso = 11;
 * @return {string}
 */
proto.elude.proto.GDSUser.prototype.getCountryIso = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GDSUser} returns this
 */
proto.elude.proto.GDSUser.prototype.setCountryIso = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.TicketInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.TicketInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.TicketInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.TicketInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    travellerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    documentNumber: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.TicketInfo}
 */
proto.elude.proto.TicketInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.TicketInfo;
  return proto.elude.proto.TicketInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.TicketInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.TicketInfo}
 */
proto.elude.proto.TicketInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTravellerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.TicketInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.TicketInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.TicketInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.TicketInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTravellerId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDocumentNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 traveller_id = 1;
 * @return {number}
 */
proto.elude.proto.TicketInfo.prototype.getTravellerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.TicketInfo} returns this
 */
proto.elude.proto.TicketInfo.prototype.setTravellerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string document_number = 2;
 * @return {string}
 */
proto.elude.proto.TicketInfo.prototype.getDocumentNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.TicketInfo} returns this
 */
proto.elude.proto.TicketInfo.prototype.setDocumentNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.SearchLocation.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.SearchLocation.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.SearchLocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.SearchLocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.SearchLocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    iataCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    locationType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    propertiesList: jspb.Message.toObjectList(msg.getPropertiesList(),
    proto_models_city_pb.Property.toObject, includeInstance),
    sublocationsList: jspb.Message.toObjectList(msg.getSublocationsList(),
    proto.elude.proto.SearchLocation.toObject, includeInstance),
    connectedCityIataCode: jspb.Message.getFieldWithDefault(msg, 8, ""),
    id: jspb.Message.getFieldWithDefault(msg, 9, 0),
    metadata: (f = msg.getMetadata()) && proto_commons_metadata_pb.LocationMetadata.toObject(includeInstance, f),
    connectedLocation: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    medianHotelPrice: jspb.Message.getFieldWithDefault(msg, 12, 0),
    shardId: jspb.Message.getFieldWithDefault(msg, 13, 0),
    isRecommendedLocation: jspb.Message.getBooleanFieldWithDefault(msg, 14, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.SearchLocation}
 */
proto.elude.proto.SearchLocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.SearchLocation;
  return proto.elude.proto.SearchLocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.SearchLocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.SearchLocation}
 */
proto.elude.proto.SearchLocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIataCode(value);
      break;
    case 5:
      var value = /** @type {!proto.elude.proto.LocationTypeEnum} */ (reader.readEnum());
      msg.setLocationType(value);
      break;
    case 6:
      var value = new proto_models_city_pb.Property;
      reader.readMessage(value,proto_models_city_pb.Property.deserializeBinaryFromReader);
      msg.addProperties(value);
      break;
    case 7:
      var value = new proto.elude.proto.SearchLocation;
      reader.readMessage(value,proto.elude.proto.SearchLocation.deserializeBinaryFromReader);
      msg.addSublocations(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setConnectedCityIataCode(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 10:
      var value = new proto_commons_metadata_pb.LocationMetadata;
      reader.readMessage(value,proto_commons_metadata_pb.LocationMetadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConnectedLocation(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMedianHotelPrice(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setShardId(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRecommendedLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.SearchLocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.SearchLocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.SearchLocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.SearchLocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIataCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLocationType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getPropertiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto_models_city_pb.Property.serializeBinaryToWriter
    );
  }
  f = message.getSublocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.elude.proto.SearchLocation.serializeBinaryToWriter
    );
  }
  f = message.getConnectedCityIataCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto_commons_metadata_pb.LocationMetadata.serializeBinaryToWriter
    );
  }
  f = message.getConnectedLocation();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getMedianHotelPrice();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getShardId();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getIsRecommendedLocation();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.elude.proto.SearchLocation.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.SearchLocation} returns this
 */
proto.elude.proto.SearchLocation.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.elude.proto.SearchLocation.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.SearchLocation} returns this
 */
proto.elude.proto.SearchLocation.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string image_url = 3;
 * @return {string}
 */
proto.elude.proto.SearchLocation.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.SearchLocation} returns this
 */
proto.elude.proto.SearchLocation.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string iata_code = 4;
 * @return {string}
 */
proto.elude.proto.SearchLocation.prototype.getIataCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.SearchLocation} returns this
 */
proto.elude.proto.SearchLocation.prototype.setIataCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional LocationTypeEnum location_type = 5;
 * @return {!proto.elude.proto.LocationTypeEnum}
 */
proto.elude.proto.SearchLocation.prototype.getLocationType = function() {
  return /** @type {!proto.elude.proto.LocationTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.elude.proto.LocationTypeEnum} value
 * @return {!proto.elude.proto.SearchLocation} returns this
 */
proto.elude.proto.SearchLocation.prototype.setLocationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * repeated Property properties = 6;
 * @return {!Array<!proto.elude.proto.Property>}
 */
proto.elude.proto.SearchLocation.prototype.getPropertiesList = function() {
  return /** @type{!Array<!proto.elude.proto.Property>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_models_city_pb.Property, 6));
};


/**
 * @param {!Array<!proto.elude.proto.Property>} value
 * @return {!proto.elude.proto.SearchLocation} returns this
*/
proto.elude.proto.SearchLocation.prototype.setPropertiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.elude.proto.Property=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.Property}
 */
proto.elude.proto.SearchLocation.prototype.addProperties = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.elude.proto.Property, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.SearchLocation} returns this
 */
proto.elude.proto.SearchLocation.prototype.clearPropertiesList = function() {
  return this.setPropertiesList([]);
};


/**
 * repeated SearchLocation sublocations = 7;
 * @return {!Array<!proto.elude.proto.SearchLocation>}
 */
proto.elude.proto.SearchLocation.prototype.getSublocationsList = function() {
  return /** @type{!Array<!proto.elude.proto.SearchLocation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.elude.proto.SearchLocation, 7));
};


/**
 * @param {!Array<!proto.elude.proto.SearchLocation>} value
 * @return {!proto.elude.proto.SearchLocation} returns this
*/
proto.elude.proto.SearchLocation.prototype.setSublocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.elude.proto.SearchLocation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.SearchLocation}
 */
proto.elude.proto.SearchLocation.prototype.addSublocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.elude.proto.SearchLocation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.SearchLocation} returns this
 */
proto.elude.proto.SearchLocation.prototype.clearSublocationsList = function() {
  return this.setSublocationsList([]);
};


/**
 * optional string connected_city_iata_code = 8;
 * @return {string}
 */
proto.elude.proto.SearchLocation.prototype.getConnectedCityIataCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.SearchLocation} returns this
 */
proto.elude.proto.SearchLocation.prototype.setConnectedCityIataCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 id = 9;
 * @return {number}
 */
proto.elude.proto.SearchLocation.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.SearchLocation} returns this
 */
proto.elude.proto.SearchLocation.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional LocationMetadata metadata = 10;
 * @return {?proto.elude.proto.LocationMetadata}
 */
proto.elude.proto.SearchLocation.prototype.getMetadata = function() {
  return /** @type{?proto.elude.proto.LocationMetadata} */ (
    jspb.Message.getWrapperField(this, proto_commons_metadata_pb.LocationMetadata, 10));
};


/**
 * @param {?proto.elude.proto.LocationMetadata|undefined} value
 * @return {!proto.elude.proto.SearchLocation} returns this
*/
proto.elude.proto.SearchLocation.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.SearchLocation} returns this
 */
proto.elude.proto.SearchLocation.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.SearchLocation.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool connected_location = 11;
 * @return {boolean}
 */
proto.elude.proto.SearchLocation.prototype.getConnectedLocation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.elude.proto.SearchLocation} returns this
 */
proto.elude.proto.SearchLocation.prototype.setConnectedLocation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional int32 median_hotel_price = 12;
 * @return {number}
 */
proto.elude.proto.SearchLocation.prototype.getMedianHotelPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.SearchLocation} returns this
 */
proto.elude.proto.SearchLocation.prototype.setMedianHotelPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 shard_id = 13;
 * @return {number}
 */
proto.elude.proto.SearchLocation.prototype.getShardId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.SearchLocation} returns this
 */
proto.elude.proto.SearchLocation.prototype.setShardId = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional bool is_recommended_location = 14;
 * @return {boolean}
 */
proto.elude.proto.SearchLocation.prototype.getIsRecommendedLocation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.elude.proto.SearchLocation} returns this
 */
proto.elude.proto.SearchLocation.prototype.setIsRecommendedLocation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


goog.object.extend(exports, proto.elude.proto);
