// source: proto/air_hotel_reservation_search/request.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
var proto_commons_flow_common_pb = require('../../proto/commons/flow_common_pb.js');
goog.object.extend(proto, proto_commons_flow_common_pb);
var proto_commons_status_pb = require('../../proto/commons/status_pb.js');
goog.object.extend(proto, proto_commons_status_pb);
var proto_commons_auth_pb = require('../../proto/commons/auth_pb.js');
goog.object.extend(proto, proto_commons_auth_pb);
var proto_commons_session_info_pb = require('../../proto/commons/session_info_pb.js');
goog.object.extend(proto, proto_commons_session_info_pb);
var proto_commons_options_pb = require('../../proto/commons/options_pb.js');
goog.object.extend(proto, proto_commons_options_pb);
var proto_commons_enum_pb = require('../../proto/commons/enum_pb.js');
goog.object.extend(proto, proto_commons_enum_pb);
var proto_models_traveller_pb = require('../../proto/models/traveller_pb.js');
goog.object.extend(proto, proto_models_traveller_pb);
var proto_models_user_pb = require('../../proto/models/user_pb.js');
goog.object.extend(proto, proto_models_user_pb);
goog.exportSymbol('proto.elude.proto.AbandonTripRequest', null, global);
goog.exportSymbol('proto.elude.proto.BuildHotelRequest', null, global);
goog.exportSymbol('proto.elude.proto.CancelRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetAbandonTripSearchRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetPriceRequest', null, global);
goog.exportSymbol('proto.elude.proto.HotelDetailsRequest', null, global);
goog.exportSymbol('proto.elude.proto.ItineraryRequest', null, global);
goog.exportSymbol('proto.elude.proto.ReservationRequest', null, global);
goog.exportSymbol('proto.elude.proto.RetrieveItineraryPriceRequest', null, global);
goog.exportSymbol('proto.elude.proto.RetrieveRequest', null, global);
goog.exportSymbol('proto.elude.proto.RevalidateItineraryRequest', null, global);
goog.exportSymbol('proto.elude.proto.SearchReturnFlightsRequest', null, global);
goog.exportSymbol('proto.elude.proto.SetSearchCacheRequest', null, global);
goog.exportSymbol('proto.elude.proto.TicketRequest', null, global);
goog.exportSymbol('proto.elude.proto.VoidTicketRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.ItineraryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.ItineraryRequest.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.ItineraryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.ItineraryRequest.displayName = 'proto.elude.proto.ItineraryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.ReservationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.ReservationRequest.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.ReservationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.ReservationRequest.displayName = 'proto.elude.proto.ReservationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.CancelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.CancelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.CancelRequest.displayName = 'proto.elude.proto.CancelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.TicketRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.TicketRequest.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.TicketRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.TicketRequest.displayName = 'proto.elude.proto.TicketRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.VoidTicketRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.VoidTicketRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.VoidTicketRequest.displayName = 'proto.elude.proto.VoidTicketRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetPriceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetPriceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetPriceRequest.displayName = 'proto.elude.proto.GetPriceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.RetrieveRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.RetrieveRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.RetrieveRequest.displayName = 'proto.elude.proto.RetrieveRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetAbandonTripSearchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetAbandonTripSearchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetAbandonTripSearchRequest.displayName = 'proto.elude.proto.GetAbandonTripSearchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.RevalidateItineraryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.RevalidateItineraryRequest.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.RevalidateItineraryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.RevalidateItineraryRequest.displayName = 'proto.elude.proto.RevalidateItineraryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.RetrieveItineraryPriceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.RetrieveItineraryPriceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.RetrieveItineraryPriceRequest.displayName = 'proto.elude.proto.RetrieveItineraryPriceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.HotelDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.HotelDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.HotelDetailsRequest.displayName = 'proto.elude.proto.HotelDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.AbandonTripRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.AbandonTripRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.AbandonTripRequest.displayName = 'proto.elude.proto.AbandonTripRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.BuildHotelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.BuildHotelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.BuildHotelRequest.displayName = 'proto.elude.proto.BuildHotelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.SetSearchCacheRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.SetSearchCacheRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.SetSearchCacheRequest.displayName = 'proto.elude.proto.SetSearchCacheRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.SearchReturnFlightsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.SearchReturnFlightsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.SearchReturnFlightsRequest.displayName = 'proto.elude.proto.SearchReturnFlightsRequest';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.ItineraryRequest.repeatedFields_ = [17,21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.ItineraryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.ItineraryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.ItineraryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.ItineraryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    numResults: jspb.Message.getFieldWithDefault(msg, 2, 0),
    travellers: (f = msg.getTravellers()) && proto_commons_flow_common_pb.Travellers.toObject(includeInstance, f),
    budget: (f = msg.getBudget()) && proto_commons_flow_common_pb.Budget.toObject(includeInstance, f),
    hotelRequirements: (f = msg.getHotelRequirements()) && proto_commons_flow_common_pb.HotelRequirements.toObject(includeInstance, f),
    airRequirements: (f = msg.getAirRequirements()) && proto_commons_flow_common_pb.AirRequirements.toObject(includeInstance, f),
    dates: (f = msg.getDates()) && proto_commons_flow_common_pb.Dates.toObject(includeInstance, f),
    locationsRequirements: (f = msg.getLocationsRequirements()) && proto_commons_flow_common_pb.LocationsRequirements.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    prioritySearch: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    budgetTier: jspb.Message.getFieldWithDefault(msg, 12, 0),
    additionalDateSearch: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    additionalDateSearchFlightId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    searchIdPrefix: jspb.Message.getFieldWithDefault(msg, 15, 0),
    hotelSearchType: jspb.Message.getFieldWithDefault(msg, 16, 0),
    locationIataCodesList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f,
    rerun: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    airBudget: (f = msg.getAirBudget()) && proto_commons_flow_common_pb.Budget.toObject(includeInstance, f),
    hotelBudget: (f = msg.getHotelBudget()) && proto_commons_flow_common_pb.Budget.toObject(includeInstance, f),
    preferenceIdsList: (f = jspb.Message.getRepeatedField(msg, 21)) == null ? undefined : f,
    flightId: jspb.Message.getFieldWithDefault(msg, 22, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.ItineraryRequest}
 */
proto.elude.proto.ItineraryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.ItineraryRequest;
  return proto.elude.proto.ItineraryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.ItineraryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.ItineraryRequest}
 */
proto.elude.proto.ItineraryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumResults(value);
      break;
    case 3:
      var value = new proto_commons_flow_common_pb.Travellers;
      reader.readMessage(value,proto_commons_flow_common_pb.Travellers.deserializeBinaryFromReader);
      msg.setTravellers(value);
      break;
    case 4:
      var value = new proto_commons_flow_common_pb.Budget;
      reader.readMessage(value,proto_commons_flow_common_pb.Budget.deserializeBinaryFromReader);
      msg.setBudget(value);
      break;
    case 5:
      var value = new proto_commons_flow_common_pb.HotelRequirements;
      reader.readMessage(value,proto_commons_flow_common_pb.HotelRequirements.deserializeBinaryFromReader);
      msg.setHotelRequirements(value);
      break;
    case 6:
      var value = new proto_commons_flow_common_pb.AirRequirements;
      reader.readMessage(value,proto_commons_flow_common_pb.AirRequirements.deserializeBinaryFromReader);
      msg.setAirRequirements(value);
      break;
    case 7:
      var value = new proto_commons_flow_common_pb.Dates;
      reader.readMessage(value,proto_commons_flow_common_pb.Dates.deserializeBinaryFromReader);
      msg.setDates(value);
      break;
    case 8:
      var value = new proto_commons_flow_common_pb.LocationsRequirements;
      reader.readMessage(value,proto_commons_flow_common_pb.LocationsRequirements.deserializeBinaryFromReader);
      msg.setLocationsRequirements(value);
      break;
    case 9:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 10:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrioritySearch(value);
      break;
    case 12:
      var value = /** @type {!proto.elude.proto.BudgetTierEnum} */ (reader.readEnum());
      msg.setBudgetTier(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAdditionalDateSearch(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdditionalDateSearchFlightId(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSearchIdPrefix(value);
      break;
    case 16:
      var value = /** @type {!proto.elude.proto.HotelSearchTypeEnum} */ (reader.readEnum());
      msg.setHotelSearchType(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.addLocationIataCodes(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRerun(value);
      break;
    case 19:
      var value = new proto_commons_flow_common_pb.Budget;
      reader.readMessage(value,proto_commons_flow_common_pb.Budget.deserializeBinaryFromReader);
      msg.setAirBudget(value);
      break;
    case 20:
      var value = new proto_commons_flow_common_pb.Budget;
      reader.readMessage(value,proto_commons_flow_common_pb.Budget.deserializeBinaryFromReader);
      msg.setHotelBudget(value);
      break;
    case 21:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPreferenceIds(values[i]);
      }
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlightId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.ItineraryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.ItineraryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.ItineraryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.ItineraryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getNumResults();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTravellers();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_flow_common_pb.Travellers.serializeBinaryToWriter
    );
  }
  f = message.getBudget();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_flow_common_pb.Budget.serializeBinaryToWriter
    );
  }
  f = message.getHotelRequirements();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_commons_flow_common_pb.HotelRequirements.serializeBinaryToWriter
    );
  }
  f = message.getAirRequirements();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_commons_flow_common_pb.AirRequirements.serializeBinaryToWriter
    );
  }
  f = message.getDates();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_commons_flow_common_pb.Dates.serializeBinaryToWriter
    );
  }
  f = message.getLocationsRequirements();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_commons_flow_common_pb.LocationsRequirements.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getPrioritySearch();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getBudgetTier();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getAdditionalDateSearch();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getAdditionalDateSearchFlightId();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getSearchIdPrefix();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getHotelSearchType();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getLocationIataCodesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      17,
      f
    );
  }
  f = message.getRerun();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getAirBudget();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto_commons_flow_common_pb.Budget.serializeBinaryToWriter
    );
  }
  f = message.getHotelBudget();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto_commons_flow_common_pb.Budget.serializeBinaryToWriter
    );
  }
  f = message.getPreferenceIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      21,
      f
    );
  }
  f = message.getFlightId();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.ItineraryRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.ItineraryRequest} returns this
*/
proto.elude.proto.ItineraryRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ItineraryRequest} returns this
 */
proto.elude.proto.ItineraryRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ItineraryRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 num_results = 2;
 * @return {number}
 */
proto.elude.proto.ItineraryRequest.prototype.getNumResults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.ItineraryRequest} returns this
 */
proto.elude.proto.ItineraryRequest.prototype.setNumResults = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Travellers travellers = 3;
 * @return {?proto.elude.proto.Travellers}
 */
proto.elude.proto.ItineraryRequest.prototype.getTravellers = function() {
  return /** @type{?proto.elude.proto.Travellers} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.Travellers, 3));
};


/**
 * @param {?proto.elude.proto.Travellers|undefined} value
 * @return {!proto.elude.proto.ItineraryRequest} returns this
*/
proto.elude.proto.ItineraryRequest.prototype.setTravellers = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ItineraryRequest} returns this
 */
proto.elude.proto.ItineraryRequest.prototype.clearTravellers = function() {
  return this.setTravellers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ItineraryRequest.prototype.hasTravellers = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Budget budget = 4;
 * @return {?proto.elude.proto.Budget}
 */
proto.elude.proto.ItineraryRequest.prototype.getBudget = function() {
  return /** @type{?proto.elude.proto.Budget} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.Budget, 4));
};


/**
 * @param {?proto.elude.proto.Budget|undefined} value
 * @return {!proto.elude.proto.ItineraryRequest} returns this
*/
proto.elude.proto.ItineraryRequest.prototype.setBudget = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ItineraryRequest} returns this
 */
proto.elude.proto.ItineraryRequest.prototype.clearBudget = function() {
  return this.setBudget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ItineraryRequest.prototype.hasBudget = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional HotelRequirements hotel_requirements = 5;
 * @return {?proto.elude.proto.HotelRequirements}
 */
proto.elude.proto.ItineraryRequest.prototype.getHotelRequirements = function() {
  return /** @type{?proto.elude.proto.HotelRequirements} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.HotelRequirements, 5));
};


/**
 * @param {?proto.elude.proto.HotelRequirements|undefined} value
 * @return {!proto.elude.proto.ItineraryRequest} returns this
*/
proto.elude.proto.ItineraryRequest.prototype.setHotelRequirements = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ItineraryRequest} returns this
 */
proto.elude.proto.ItineraryRequest.prototype.clearHotelRequirements = function() {
  return this.setHotelRequirements(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ItineraryRequest.prototype.hasHotelRequirements = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional AirRequirements air_requirements = 6;
 * @return {?proto.elude.proto.AirRequirements}
 */
proto.elude.proto.ItineraryRequest.prototype.getAirRequirements = function() {
  return /** @type{?proto.elude.proto.AirRequirements} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.AirRequirements, 6));
};


/**
 * @param {?proto.elude.proto.AirRequirements|undefined} value
 * @return {!proto.elude.proto.ItineraryRequest} returns this
*/
proto.elude.proto.ItineraryRequest.prototype.setAirRequirements = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ItineraryRequest} returns this
 */
proto.elude.proto.ItineraryRequest.prototype.clearAirRequirements = function() {
  return this.setAirRequirements(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ItineraryRequest.prototype.hasAirRequirements = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Dates dates = 7;
 * @return {?proto.elude.proto.Dates}
 */
proto.elude.proto.ItineraryRequest.prototype.getDates = function() {
  return /** @type{?proto.elude.proto.Dates} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.Dates, 7));
};


/**
 * @param {?proto.elude.proto.Dates|undefined} value
 * @return {!proto.elude.proto.ItineraryRequest} returns this
*/
proto.elude.proto.ItineraryRequest.prototype.setDates = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ItineraryRequest} returns this
 */
proto.elude.proto.ItineraryRequest.prototype.clearDates = function() {
  return this.setDates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ItineraryRequest.prototype.hasDates = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional LocationsRequirements locations_requirements = 8;
 * @return {?proto.elude.proto.LocationsRequirements}
 */
proto.elude.proto.ItineraryRequest.prototype.getLocationsRequirements = function() {
  return /** @type{?proto.elude.proto.LocationsRequirements} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.LocationsRequirements, 8));
};


/**
 * @param {?proto.elude.proto.LocationsRequirements|undefined} value
 * @return {!proto.elude.proto.ItineraryRequest} returns this
*/
proto.elude.proto.ItineraryRequest.prototype.setLocationsRequirements = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ItineraryRequest} returns this
 */
proto.elude.proto.ItineraryRequest.prototype.clearLocationsRequirements = function() {
  return this.setLocationsRequirements(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ItineraryRequest.prototype.hasLocationsRequirements = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Options options = 9;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.ItineraryRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 9));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.ItineraryRequest} returns this
*/
proto.elude.proto.ItineraryRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ItineraryRequest} returns this
 */
proto.elude.proto.ItineraryRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ItineraryRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional SessionInfo session_info = 10;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.ItineraryRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 10));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.ItineraryRequest} returns this
*/
proto.elude.proto.ItineraryRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ItineraryRequest} returns this
 */
proto.elude.proto.ItineraryRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ItineraryRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool priority_search = 11;
 * @return {boolean}
 */
proto.elude.proto.ItineraryRequest.prototype.getPrioritySearch = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.elude.proto.ItineraryRequest} returns this
 */
proto.elude.proto.ItineraryRequest.prototype.setPrioritySearch = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional BudgetTierEnum budget_tier = 12;
 * @return {!proto.elude.proto.BudgetTierEnum}
 */
proto.elude.proto.ItineraryRequest.prototype.getBudgetTier = function() {
  return /** @type {!proto.elude.proto.BudgetTierEnum} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.elude.proto.BudgetTierEnum} value
 * @return {!proto.elude.proto.ItineraryRequest} returns this
 */
proto.elude.proto.ItineraryRequest.prototype.setBudgetTier = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional bool additional_date_search = 13;
 * @return {boolean}
 */
proto.elude.proto.ItineraryRequest.prototype.getAdditionalDateSearch = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.elude.proto.ItineraryRequest} returns this
 */
proto.elude.proto.ItineraryRequest.prototype.setAdditionalDateSearch = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional int32 additional_date_search_flight_id = 14;
 * @return {number}
 */
proto.elude.proto.ItineraryRequest.prototype.getAdditionalDateSearchFlightId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.ItineraryRequest} returns this
 */
proto.elude.proto.ItineraryRequest.prototype.setAdditionalDateSearchFlightId = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 search_id_prefix = 15;
 * @return {number}
 */
proto.elude.proto.ItineraryRequest.prototype.getSearchIdPrefix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.ItineraryRequest} returns this
 */
proto.elude.proto.ItineraryRequest.prototype.setSearchIdPrefix = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional HotelSearchTypeEnum hotel_search_type = 16;
 * @return {!proto.elude.proto.HotelSearchTypeEnum}
 */
proto.elude.proto.ItineraryRequest.prototype.getHotelSearchType = function() {
  return /** @type {!proto.elude.proto.HotelSearchTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.elude.proto.HotelSearchTypeEnum} value
 * @return {!proto.elude.proto.ItineraryRequest} returns this
 */
proto.elude.proto.ItineraryRequest.prototype.setHotelSearchType = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * repeated string location_iata_codes = 17;
 * @return {!Array<string>}
 */
proto.elude.proto.ItineraryRequest.prototype.getLocationIataCodesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 17));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.elude.proto.ItineraryRequest} returns this
 */
proto.elude.proto.ItineraryRequest.prototype.setLocationIataCodesList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.ItineraryRequest} returns this
 */
proto.elude.proto.ItineraryRequest.prototype.addLocationIataCodes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.ItineraryRequest} returns this
 */
proto.elude.proto.ItineraryRequest.prototype.clearLocationIataCodesList = function() {
  return this.setLocationIataCodesList([]);
};


/**
 * optional bool rerun = 18;
 * @return {boolean}
 */
proto.elude.proto.ItineraryRequest.prototype.getRerun = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.elude.proto.ItineraryRequest} returns this
 */
proto.elude.proto.ItineraryRequest.prototype.setRerun = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional Budget air_budget = 19;
 * @return {?proto.elude.proto.Budget}
 */
proto.elude.proto.ItineraryRequest.prototype.getAirBudget = function() {
  return /** @type{?proto.elude.proto.Budget} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.Budget, 19));
};


/**
 * @param {?proto.elude.proto.Budget|undefined} value
 * @return {!proto.elude.proto.ItineraryRequest} returns this
*/
proto.elude.proto.ItineraryRequest.prototype.setAirBudget = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ItineraryRequest} returns this
 */
proto.elude.proto.ItineraryRequest.prototype.clearAirBudget = function() {
  return this.setAirBudget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ItineraryRequest.prototype.hasAirBudget = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional Budget hotel_budget = 20;
 * @return {?proto.elude.proto.Budget}
 */
proto.elude.proto.ItineraryRequest.prototype.getHotelBudget = function() {
  return /** @type{?proto.elude.proto.Budget} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.Budget, 20));
};


/**
 * @param {?proto.elude.proto.Budget|undefined} value
 * @return {!proto.elude.proto.ItineraryRequest} returns this
*/
proto.elude.proto.ItineraryRequest.prototype.setHotelBudget = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ItineraryRequest} returns this
 */
proto.elude.proto.ItineraryRequest.prototype.clearHotelBudget = function() {
  return this.setHotelBudget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ItineraryRequest.prototype.hasHotelBudget = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * repeated int32 preference_ids = 21;
 * @return {!Array<number>}
 */
proto.elude.proto.ItineraryRequest.prototype.getPreferenceIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 21));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.elude.proto.ItineraryRequest} returns this
 */
proto.elude.proto.ItineraryRequest.prototype.setPreferenceIdsList = function(value) {
  return jspb.Message.setField(this, 21, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.ItineraryRequest} returns this
 */
proto.elude.proto.ItineraryRequest.prototype.addPreferenceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 21, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.ItineraryRequest} returns this
 */
proto.elude.proto.ItineraryRequest.prototype.clearPreferenceIdsList = function() {
  return this.setPreferenceIdsList([]);
};


/**
 * optional int32 flight_id = 22;
 * @return {number}
 */
proto.elude.proto.ItineraryRequest.prototype.getFlightId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.ItineraryRequest} returns this
 */
proto.elude.proto.ItineraryRequest.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.ReservationRequest.repeatedFields_ = [8,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.ReservationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.ReservationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.ReservationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.ReservationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    hotelId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    roomId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    flightId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    paymentMethodId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    travellerIdsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    user: (f = msg.getUser()) && proto_commons_flow_common_pb.GDSUser.toObject(includeInstance, f),
    paymentMethod: (f = msg.getPaymentMethod()) && proto_models_user_pb.PaymentMethod.toObject(includeInstance, f),
    travellersList: jspb.Message.toObjectList(msg.getTravellersList(),
    proto_commons_flow_common_pb.GDSTraveller.toObject, includeInstance),
    hotelGds: (f = msg.getHotelGds()) && proto_commons_flow_common_pb.GDSBackend.toObject(includeInstance, f),
    flightGds: (f = msg.getFlightGds()) && proto_commons_flow_common_pb.GDSBackend.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    promotionCode: jspb.Message.getFieldWithDefault(msg, 15, ""),
    promotionCodeId: jspb.Message.getFieldWithDefault(msg, 16, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.ReservationRequest}
 */
proto.elude.proto.ReservationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.ReservationRequest;
  return proto.elude.proto.ReservationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.ReservationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.ReservationRequest}
 */
proto.elude.proto.ReservationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHotelId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRoomId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlightId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentMethodId(value);
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTravellerIds(values[i]);
      }
      break;
    case 9:
      var value = new proto_commons_flow_common_pb.GDSUser;
      reader.readMessage(value,proto_commons_flow_common_pb.GDSUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 10:
      var value = new proto_models_user_pb.PaymentMethod;
      reader.readMessage(value,proto_models_user_pb.PaymentMethod.deserializeBinaryFromReader);
      msg.setPaymentMethod(value);
      break;
    case 11:
      var value = new proto_commons_flow_common_pb.GDSTraveller;
      reader.readMessage(value,proto_commons_flow_common_pb.GDSTraveller.deserializeBinaryFromReader);
      msg.addTravellers(value);
      break;
    case 12:
      var value = new proto_commons_flow_common_pb.GDSBackend;
      reader.readMessage(value,proto_commons_flow_common_pb.GDSBackend.deserializeBinaryFromReader);
      msg.setHotelGds(value);
      break;
    case 13:
      var value = new proto_commons_flow_common_pb.GDSBackend;
      reader.readMessage(value,proto_commons_flow_common_pb.GDSBackend.deserializeBinaryFromReader);
      msg.setFlightGds(value);
      break;
    case 14:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromotionCode(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPromotionCodeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.ReservationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.ReservationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.ReservationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.ReservationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getHotelId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getRoomId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getFlightId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getPaymentMethodId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getTravellerIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      8,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_commons_flow_common_pb.GDSUser.serializeBinaryToWriter
    );
  }
  f = message.getPaymentMethod();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto_models_user_pb.PaymentMethod.serializeBinaryToWriter
    );
  }
  f = message.getTravellersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto_commons_flow_common_pb.GDSTraveller.serializeBinaryToWriter
    );
  }
  f = message.getHotelGds();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_commons_flow_common_pb.GDSBackend.serializeBinaryToWriter
    );
  }
  f = message.getFlightGds();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto_commons_flow_common_pb.GDSBackend.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getPromotionCode();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getPromotionCodeId();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.ReservationRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.ReservationRequest} returns this
*/
proto.elude.proto.ReservationRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ReservationRequest} returns this
 */
proto.elude.proto.ReservationRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ReservationRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Options options = 2;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.ReservationRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 2));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.ReservationRequest} returns this
*/
proto.elude.proto.ReservationRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ReservationRequest} returns this
 */
proto.elude.proto.ReservationRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ReservationRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 hotel_id = 3;
 * @return {number}
 */
proto.elude.proto.ReservationRequest.prototype.getHotelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.ReservationRequest} returns this
 */
proto.elude.proto.ReservationRequest.prototype.setHotelId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 room_id = 4;
 * @return {number}
 */
proto.elude.proto.ReservationRequest.prototype.getRoomId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.ReservationRequest} returns this
 */
proto.elude.proto.ReservationRequest.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 flight_id = 5;
 * @return {number}
 */
proto.elude.proto.ReservationRequest.prototype.getFlightId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.ReservationRequest} returns this
 */
proto.elude.proto.ReservationRequest.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 payment_method_id = 7;
 * @return {number}
 */
proto.elude.proto.ReservationRequest.prototype.getPaymentMethodId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.ReservationRequest} returns this
 */
proto.elude.proto.ReservationRequest.prototype.setPaymentMethodId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated int32 traveller_ids = 8;
 * @return {!Array<number>}
 */
proto.elude.proto.ReservationRequest.prototype.getTravellerIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.elude.proto.ReservationRequest} returns this
 */
proto.elude.proto.ReservationRequest.prototype.setTravellerIdsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.ReservationRequest} returns this
 */
proto.elude.proto.ReservationRequest.prototype.addTravellerIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.ReservationRequest} returns this
 */
proto.elude.proto.ReservationRequest.prototype.clearTravellerIdsList = function() {
  return this.setTravellerIdsList([]);
};


/**
 * optional GDSUser user = 9;
 * @return {?proto.elude.proto.GDSUser}
 */
proto.elude.proto.ReservationRequest.prototype.getUser = function() {
  return /** @type{?proto.elude.proto.GDSUser} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.GDSUser, 9));
};


/**
 * @param {?proto.elude.proto.GDSUser|undefined} value
 * @return {!proto.elude.proto.ReservationRequest} returns this
*/
proto.elude.proto.ReservationRequest.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ReservationRequest} returns this
 */
proto.elude.proto.ReservationRequest.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ReservationRequest.prototype.hasUser = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional PaymentMethod payment_method = 10;
 * @return {?proto.elude.proto.PaymentMethod}
 */
proto.elude.proto.ReservationRequest.prototype.getPaymentMethod = function() {
  return /** @type{?proto.elude.proto.PaymentMethod} */ (
    jspb.Message.getWrapperField(this, proto_models_user_pb.PaymentMethod, 10));
};


/**
 * @param {?proto.elude.proto.PaymentMethod|undefined} value
 * @return {!proto.elude.proto.ReservationRequest} returns this
*/
proto.elude.proto.ReservationRequest.prototype.setPaymentMethod = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ReservationRequest} returns this
 */
proto.elude.proto.ReservationRequest.prototype.clearPaymentMethod = function() {
  return this.setPaymentMethod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ReservationRequest.prototype.hasPaymentMethod = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated GDSTraveller travellers = 11;
 * @return {!Array<!proto.elude.proto.GDSTraveller>}
 */
proto.elude.proto.ReservationRequest.prototype.getTravellersList = function() {
  return /** @type{!Array<!proto.elude.proto.GDSTraveller>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_commons_flow_common_pb.GDSTraveller, 11));
};


/**
 * @param {!Array<!proto.elude.proto.GDSTraveller>} value
 * @return {!proto.elude.proto.ReservationRequest} returns this
*/
proto.elude.proto.ReservationRequest.prototype.setTravellersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.elude.proto.GDSTraveller=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.GDSTraveller}
 */
proto.elude.proto.ReservationRequest.prototype.addTravellers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.elude.proto.GDSTraveller, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.ReservationRequest} returns this
 */
proto.elude.proto.ReservationRequest.prototype.clearTravellersList = function() {
  return this.setTravellersList([]);
};


/**
 * optional GDSBackend hotel_gds = 12;
 * @return {?proto.elude.proto.GDSBackend}
 */
proto.elude.proto.ReservationRequest.prototype.getHotelGds = function() {
  return /** @type{?proto.elude.proto.GDSBackend} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.GDSBackend, 12));
};


/**
 * @param {?proto.elude.proto.GDSBackend|undefined} value
 * @return {!proto.elude.proto.ReservationRequest} returns this
*/
proto.elude.proto.ReservationRequest.prototype.setHotelGds = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ReservationRequest} returns this
 */
proto.elude.proto.ReservationRequest.prototype.clearHotelGds = function() {
  return this.setHotelGds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ReservationRequest.prototype.hasHotelGds = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional GDSBackend flight_gds = 13;
 * @return {?proto.elude.proto.GDSBackend}
 */
proto.elude.proto.ReservationRequest.prototype.getFlightGds = function() {
  return /** @type{?proto.elude.proto.GDSBackend} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.GDSBackend, 13));
};


/**
 * @param {?proto.elude.proto.GDSBackend|undefined} value
 * @return {!proto.elude.proto.ReservationRequest} returns this
*/
proto.elude.proto.ReservationRequest.prototype.setFlightGds = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ReservationRequest} returns this
 */
proto.elude.proto.ReservationRequest.prototype.clearFlightGds = function() {
  return this.setFlightGds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ReservationRequest.prototype.hasFlightGds = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional SessionInfo session_info = 14;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.ReservationRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 14));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.ReservationRequest} returns this
*/
proto.elude.proto.ReservationRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ReservationRequest} returns this
 */
proto.elude.proto.ReservationRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ReservationRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string promotion_code = 15;
 * @return {string}
 */
proto.elude.proto.ReservationRequest.prototype.getPromotionCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.ReservationRequest} returns this
 */
proto.elude.proto.ReservationRequest.prototype.setPromotionCode = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional int32 promotion_code_id = 16;
 * @return {number}
 */
proto.elude.proto.ReservationRequest.prototype.getPromotionCodeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.ReservationRequest} returns this
 */
proto.elude.proto.ReservationRequest.prototype.setPromotionCodeId = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.CancelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.CancelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.CancelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CancelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    bookingId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    hotelReservationIdentifier: jspb.Message.getFieldWithDefault(msg, 4, ""),
    airReservationIdentifier: jspb.Message.getFieldWithDefault(msg, 5, ""),
    hotelGds: (f = msg.getHotelGds()) && proto_commons_flow_common_pb.GDSBackend.toObject(includeInstance, f),
    flightGds: (f = msg.getFlightGds()) && proto_commons_flow_common_pb.GDSBackend.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    voidTickets: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.CancelRequest}
 */
proto.elude.proto.CancelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.CancelRequest;
  return proto.elude.proto.CancelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.CancelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.CancelRequest}
 */
proto.elude.proto.CancelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBookingId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelReservationIdentifier(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAirReservationIdentifier(value);
      break;
    case 6:
      var value = new proto_commons_flow_common_pb.GDSBackend;
      reader.readMessage(value,proto_commons_flow_common_pb.GDSBackend.deserializeBinaryFromReader);
      msg.setHotelGds(value);
      break;
    case 7:
      var value = new proto_commons_flow_common_pb.GDSBackend;
      reader.readMessage(value,proto_commons_flow_common_pb.GDSBackend.deserializeBinaryFromReader);
      msg.setFlightGds(value);
      break;
    case 8:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVoidTickets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.CancelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.CancelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.CancelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CancelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getBookingId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getHotelReservationIdentifier();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAirReservationIdentifier();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getHotelGds();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_commons_flow_common_pb.GDSBackend.serializeBinaryToWriter
    );
  }
  f = message.getFlightGds();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_commons_flow_common_pb.GDSBackend.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getVoidTickets();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.CancelRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.CancelRequest} returns this
*/
proto.elude.proto.CancelRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CancelRequest} returns this
 */
proto.elude.proto.CancelRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CancelRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Options options = 2;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.CancelRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 2));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.CancelRequest} returns this
*/
proto.elude.proto.CancelRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CancelRequest} returns this
 */
proto.elude.proto.CancelRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CancelRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 booking_id = 3;
 * @return {number}
 */
proto.elude.proto.CancelRequest.prototype.getBookingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.CancelRequest} returns this
 */
proto.elude.proto.CancelRequest.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string hotel_reservation_identifier = 4;
 * @return {string}
 */
proto.elude.proto.CancelRequest.prototype.getHotelReservationIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.CancelRequest} returns this
 */
proto.elude.proto.CancelRequest.prototype.setHotelReservationIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string air_reservation_identifier = 5;
 * @return {string}
 */
proto.elude.proto.CancelRequest.prototype.getAirReservationIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.CancelRequest} returns this
 */
proto.elude.proto.CancelRequest.prototype.setAirReservationIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional GDSBackend hotel_gds = 6;
 * @return {?proto.elude.proto.GDSBackend}
 */
proto.elude.proto.CancelRequest.prototype.getHotelGds = function() {
  return /** @type{?proto.elude.proto.GDSBackend} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.GDSBackend, 6));
};


/**
 * @param {?proto.elude.proto.GDSBackend|undefined} value
 * @return {!proto.elude.proto.CancelRequest} returns this
*/
proto.elude.proto.CancelRequest.prototype.setHotelGds = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CancelRequest} returns this
 */
proto.elude.proto.CancelRequest.prototype.clearHotelGds = function() {
  return this.setHotelGds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CancelRequest.prototype.hasHotelGds = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional GDSBackend flight_gds = 7;
 * @return {?proto.elude.proto.GDSBackend}
 */
proto.elude.proto.CancelRequest.prototype.getFlightGds = function() {
  return /** @type{?proto.elude.proto.GDSBackend} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.GDSBackend, 7));
};


/**
 * @param {?proto.elude.proto.GDSBackend|undefined} value
 * @return {!proto.elude.proto.CancelRequest} returns this
*/
proto.elude.proto.CancelRequest.prototype.setFlightGds = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CancelRequest} returns this
 */
proto.elude.proto.CancelRequest.prototype.clearFlightGds = function() {
  return this.setFlightGds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CancelRequest.prototype.hasFlightGds = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional SessionInfo session_info = 8;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.CancelRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 8));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.CancelRequest} returns this
*/
proto.elude.proto.CancelRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CancelRequest} returns this
 */
proto.elude.proto.CancelRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CancelRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool void_tickets = 9;
 * @return {boolean}
 */
proto.elude.proto.CancelRequest.prototype.getVoidTickets = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.elude.proto.CancelRequest} returns this
 */
proto.elude.proto.CancelRequest.prototype.setVoidTickets = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.TicketRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.TicketRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.TicketRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.TicketRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.TicketRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    gds: (f = msg.getGds()) && proto_commons_flow_common_pb.GDSBackend.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    airReservationIdentifier: jspb.Message.getFieldWithDefault(msg, 5, ""),
    priceQuotesList: jspb.Message.toObjectList(msg.getPriceQuotesList(),
    proto_commons_flow_common_pb.PriceQuote.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.TicketRequest}
 */
proto.elude.proto.TicketRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.TicketRequest;
  return proto.elude.proto.TicketRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.TicketRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.TicketRequest}
 */
proto.elude.proto.TicketRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 3:
      var value = new proto_commons_flow_common_pb.GDSBackend;
      reader.readMessage(value,proto_commons_flow_common_pb.GDSBackend.deserializeBinaryFromReader);
      msg.setGds(value);
      break;
    case 4:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAirReservationIdentifier(value);
      break;
    case 6:
      var value = new proto_commons_flow_common_pb.PriceQuote;
      reader.readMessage(value,proto_commons_flow_common_pb.PriceQuote.deserializeBinaryFromReader);
      msg.addPriceQuotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.TicketRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.TicketRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.TicketRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.TicketRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getGds();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_flow_common_pb.GDSBackend.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getAirReservationIdentifier();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPriceQuotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto_commons_flow_common_pb.PriceQuote.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.TicketRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.TicketRequest} returns this
*/
proto.elude.proto.TicketRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.TicketRequest} returns this
 */
proto.elude.proto.TicketRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.TicketRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Options options = 2;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.TicketRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 2));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.TicketRequest} returns this
*/
proto.elude.proto.TicketRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.TicketRequest} returns this
 */
proto.elude.proto.TicketRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.TicketRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional GDSBackend gds = 3;
 * @return {?proto.elude.proto.GDSBackend}
 */
proto.elude.proto.TicketRequest.prototype.getGds = function() {
  return /** @type{?proto.elude.proto.GDSBackend} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.GDSBackend, 3));
};


/**
 * @param {?proto.elude.proto.GDSBackend|undefined} value
 * @return {!proto.elude.proto.TicketRequest} returns this
*/
proto.elude.proto.TicketRequest.prototype.setGds = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.TicketRequest} returns this
 */
proto.elude.proto.TicketRequest.prototype.clearGds = function() {
  return this.setGds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.TicketRequest.prototype.hasGds = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SessionInfo session_info = 4;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.TicketRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 4));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.TicketRequest} returns this
*/
proto.elude.proto.TicketRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.TicketRequest} returns this
 */
proto.elude.proto.TicketRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.TicketRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string air_reservation_identifier = 5;
 * @return {string}
 */
proto.elude.proto.TicketRequest.prototype.getAirReservationIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.TicketRequest} returns this
 */
proto.elude.proto.TicketRequest.prototype.setAirReservationIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated PriceQuote price_quotes = 6;
 * @return {!Array<!proto.elude.proto.PriceQuote>}
 */
proto.elude.proto.TicketRequest.prototype.getPriceQuotesList = function() {
  return /** @type{!Array<!proto.elude.proto.PriceQuote>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_commons_flow_common_pb.PriceQuote, 6));
};


/**
 * @param {!Array<!proto.elude.proto.PriceQuote>} value
 * @return {!proto.elude.proto.TicketRequest} returns this
*/
proto.elude.proto.TicketRequest.prototype.setPriceQuotesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.elude.proto.PriceQuote=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.PriceQuote}
 */
proto.elude.proto.TicketRequest.prototype.addPriceQuotes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.elude.proto.PriceQuote, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.TicketRequest} returns this
 */
proto.elude.proto.TicketRequest.prototype.clearPriceQuotesList = function() {
  return this.setPriceQuotesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.VoidTicketRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.VoidTicketRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.VoidTicketRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.VoidTicketRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    gds: (f = msg.getGds()) && proto_commons_flow_common_pb.GDSBackend.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    airReservationIdentifier: jspb.Message.getFieldWithDefault(msg, 5, ""),
    ticketIdentifier: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.VoidTicketRequest}
 */
proto.elude.proto.VoidTicketRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.VoidTicketRequest;
  return proto.elude.proto.VoidTicketRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.VoidTicketRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.VoidTicketRequest}
 */
proto.elude.proto.VoidTicketRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 3:
      var value = new proto_commons_flow_common_pb.GDSBackend;
      reader.readMessage(value,proto_commons_flow_common_pb.GDSBackend.deserializeBinaryFromReader);
      msg.setGds(value);
      break;
    case 4:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAirReservationIdentifier(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTicketIdentifier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.VoidTicketRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.VoidTicketRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.VoidTicketRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.VoidTicketRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getGds();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_flow_common_pb.GDSBackend.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getAirReservationIdentifier();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTicketIdentifier();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.VoidTicketRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.VoidTicketRequest} returns this
*/
proto.elude.proto.VoidTicketRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.VoidTicketRequest} returns this
 */
proto.elude.proto.VoidTicketRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.VoidTicketRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Options options = 2;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.VoidTicketRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 2));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.VoidTicketRequest} returns this
*/
proto.elude.proto.VoidTicketRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.VoidTicketRequest} returns this
 */
proto.elude.proto.VoidTicketRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.VoidTicketRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional GDSBackend gds = 3;
 * @return {?proto.elude.proto.GDSBackend}
 */
proto.elude.proto.VoidTicketRequest.prototype.getGds = function() {
  return /** @type{?proto.elude.proto.GDSBackend} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.GDSBackend, 3));
};


/**
 * @param {?proto.elude.proto.GDSBackend|undefined} value
 * @return {!proto.elude.proto.VoidTicketRequest} returns this
*/
proto.elude.proto.VoidTicketRequest.prototype.setGds = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.VoidTicketRequest} returns this
 */
proto.elude.proto.VoidTicketRequest.prototype.clearGds = function() {
  return this.setGds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.VoidTicketRequest.prototype.hasGds = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SessionInfo session_info = 4;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.VoidTicketRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 4));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.VoidTicketRequest} returns this
*/
proto.elude.proto.VoidTicketRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.VoidTicketRequest} returns this
 */
proto.elude.proto.VoidTicketRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.VoidTicketRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string air_reservation_identifier = 5;
 * @return {string}
 */
proto.elude.proto.VoidTicketRequest.prototype.getAirReservationIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.VoidTicketRequest} returns this
 */
proto.elude.proto.VoidTicketRequest.prototype.setAirReservationIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string ticket_identifier = 6;
 * @return {string}
 */
proto.elude.proto.VoidTicketRequest.prototype.getTicketIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.VoidTicketRequest} returns this
 */
proto.elude.proto.VoidTicketRequest.prototype.setTicketIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetPriceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetPriceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetPriceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetPriceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    hotelId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    roomId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    flightId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    hotelGds: (f = msg.getHotelGds()) && proto_commons_flow_common_pb.GDSBackend.toObject(includeInstance, f),
    flightGds: (f = msg.getFlightGds()) && proto_commons_flow_common_pb.GDSBackend.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetPriceRequest}
 */
proto.elude.proto.GetPriceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetPriceRequest;
  return proto.elude.proto.GetPriceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetPriceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetPriceRequest}
 */
proto.elude.proto.GetPriceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHotelId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRoomId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlightId(value);
      break;
    case 6:
      var value = new proto_commons_flow_common_pb.GDSBackend;
      reader.readMessage(value,proto_commons_flow_common_pb.GDSBackend.deserializeBinaryFromReader);
      msg.setHotelGds(value);
      break;
    case 7:
      var value = new proto_commons_flow_common_pb.GDSBackend;
      reader.readMessage(value,proto_commons_flow_common_pb.GDSBackend.deserializeBinaryFromReader);
      msg.setFlightGds(value);
      break;
    case 8:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetPriceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetPriceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetPriceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetPriceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getHotelId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getRoomId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getFlightId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getHotelGds();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_commons_flow_common_pb.GDSBackend.serializeBinaryToWriter
    );
  }
  f = message.getFlightGds();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_commons_flow_common_pb.GDSBackend.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetPriceRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetPriceRequest} returns this
*/
proto.elude.proto.GetPriceRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetPriceRequest} returns this
 */
proto.elude.proto.GetPriceRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetPriceRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Options options = 2;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetPriceRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 2));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetPriceRequest} returns this
*/
proto.elude.proto.GetPriceRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetPriceRequest} returns this
 */
proto.elude.proto.GetPriceRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetPriceRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 hotel_id = 3;
 * @return {number}
 */
proto.elude.proto.GetPriceRequest.prototype.getHotelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetPriceRequest} returns this
 */
proto.elude.proto.GetPriceRequest.prototype.setHotelId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 room_id = 4;
 * @return {number}
 */
proto.elude.proto.GetPriceRequest.prototype.getRoomId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetPriceRequest} returns this
 */
proto.elude.proto.GetPriceRequest.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 flight_id = 5;
 * @return {number}
 */
proto.elude.proto.GetPriceRequest.prototype.getFlightId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetPriceRequest} returns this
 */
proto.elude.proto.GetPriceRequest.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional GDSBackend hotel_gds = 6;
 * @return {?proto.elude.proto.GDSBackend}
 */
proto.elude.proto.GetPriceRequest.prototype.getHotelGds = function() {
  return /** @type{?proto.elude.proto.GDSBackend} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.GDSBackend, 6));
};


/**
 * @param {?proto.elude.proto.GDSBackend|undefined} value
 * @return {!proto.elude.proto.GetPriceRequest} returns this
*/
proto.elude.proto.GetPriceRequest.prototype.setHotelGds = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetPriceRequest} returns this
 */
proto.elude.proto.GetPriceRequest.prototype.clearHotelGds = function() {
  return this.setHotelGds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetPriceRequest.prototype.hasHotelGds = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional GDSBackend flight_gds = 7;
 * @return {?proto.elude.proto.GDSBackend}
 */
proto.elude.proto.GetPriceRequest.prototype.getFlightGds = function() {
  return /** @type{?proto.elude.proto.GDSBackend} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.GDSBackend, 7));
};


/**
 * @param {?proto.elude.proto.GDSBackend|undefined} value
 * @return {!proto.elude.proto.GetPriceRequest} returns this
*/
proto.elude.proto.GetPriceRequest.prototype.setFlightGds = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetPriceRequest} returns this
 */
proto.elude.proto.GetPriceRequest.prototype.clearFlightGds = function() {
  return this.setFlightGds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetPriceRequest.prototype.hasFlightGds = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional SessionInfo session_info = 8;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetPriceRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 8));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetPriceRequest} returns this
*/
proto.elude.proto.GetPriceRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetPriceRequest} returns this
 */
proto.elude.proto.GetPriceRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetPriceRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.RetrieveRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.RetrieveRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.RetrieveRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.RetrieveRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    bookingId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    hotelReservationIdentifier: jspb.Message.getFieldWithDefault(msg, 4, ""),
    airReservationIdentifier: jspb.Message.getFieldWithDefault(msg, 5, ""),
    hotelGds: (f = msg.getHotelGds()) && proto_commons_flow_common_pb.GDSBackend.toObject(includeInstance, f),
    flightGds: (f = msg.getFlightGds()) && proto_commons_flow_common_pb.GDSBackend.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.RetrieveRequest}
 */
proto.elude.proto.RetrieveRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.RetrieveRequest;
  return proto.elude.proto.RetrieveRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.RetrieveRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.RetrieveRequest}
 */
proto.elude.proto.RetrieveRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBookingId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelReservationIdentifier(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAirReservationIdentifier(value);
      break;
    case 6:
      var value = new proto_commons_flow_common_pb.GDSBackend;
      reader.readMessage(value,proto_commons_flow_common_pb.GDSBackend.deserializeBinaryFromReader);
      msg.setHotelGds(value);
      break;
    case 7:
      var value = new proto_commons_flow_common_pb.GDSBackend;
      reader.readMessage(value,proto_commons_flow_common_pb.GDSBackend.deserializeBinaryFromReader);
      msg.setFlightGds(value);
      break;
    case 8:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.RetrieveRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.RetrieveRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.RetrieveRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.RetrieveRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getBookingId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getHotelReservationIdentifier();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAirReservationIdentifier();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getHotelGds();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_commons_flow_common_pb.GDSBackend.serializeBinaryToWriter
    );
  }
  f = message.getFlightGds();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_commons_flow_common_pb.GDSBackend.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.RetrieveRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.RetrieveRequest} returns this
*/
proto.elude.proto.RetrieveRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RetrieveRequest} returns this
 */
proto.elude.proto.RetrieveRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RetrieveRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Options options = 2;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.RetrieveRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 2));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.RetrieveRequest} returns this
*/
proto.elude.proto.RetrieveRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RetrieveRequest} returns this
 */
proto.elude.proto.RetrieveRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RetrieveRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 booking_id = 3;
 * @return {number}
 */
proto.elude.proto.RetrieveRequest.prototype.getBookingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.RetrieveRequest} returns this
 */
proto.elude.proto.RetrieveRequest.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string hotel_reservation_identifier = 4;
 * @return {string}
 */
proto.elude.proto.RetrieveRequest.prototype.getHotelReservationIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.RetrieveRequest} returns this
 */
proto.elude.proto.RetrieveRequest.prototype.setHotelReservationIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string air_reservation_identifier = 5;
 * @return {string}
 */
proto.elude.proto.RetrieveRequest.prototype.getAirReservationIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.RetrieveRequest} returns this
 */
proto.elude.proto.RetrieveRequest.prototype.setAirReservationIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional GDSBackend hotel_gds = 6;
 * @return {?proto.elude.proto.GDSBackend}
 */
proto.elude.proto.RetrieveRequest.prototype.getHotelGds = function() {
  return /** @type{?proto.elude.proto.GDSBackend} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.GDSBackend, 6));
};


/**
 * @param {?proto.elude.proto.GDSBackend|undefined} value
 * @return {!proto.elude.proto.RetrieveRequest} returns this
*/
proto.elude.proto.RetrieveRequest.prototype.setHotelGds = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RetrieveRequest} returns this
 */
proto.elude.proto.RetrieveRequest.prototype.clearHotelGds = function() {
  return this.setHotelGds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RetrieveRequest.prototype.hasHotelGds = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional GDSBackend flight_gds = 7;
 * @return {?proto.elude.proto.GDSBackend}
 */
proto.elude.proto.RetrieveRequest.prototype.getFlightGds = function() {
  return /** @type{?proto.elude.proto.GDSBackend} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.GDSBackend, 7));
};


/**
 * @param {?proto.elude.proto.GDSBackend|undefined} value
 * @return {!proto.elude.proto.RetrieveRequest} returns this
*/
proto.elude.proto.RetrieveRequest.prototype.setFlightGds = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RetrieveRequest} returns this
 */
proto.elude.proto.RetrieveRequest.prototype.clearFlightGds = function() {
  return this.setFlightGds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RetrieveRequest.prototype.hasFlightGds = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional SessionInfo session_info = 8;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.RetrieveRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 8));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.RetrieveRequest} returns this
*/
proto.elude.proto.RetrieveRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RetrieveRequest} returns this
 */
proto.elude.proto.RetrieveRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RetrieveRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetAbandonTripSearchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetAbandonTripSearchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetAbandonTripSearchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetAbandonTripSearchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    itineraryRequest: (f = msg.getItineraryRequest()) && proto.elude.proto.ItineraryRequest.toObject(includeInstance, f),
    flightId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    hotelId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    flightGds: (f = msg.getFlightGds()) && proto_commons_flow_common_pb.GDSBackend.toObject(includeInstance, f),
    hotelGds: (f = msg.getHotelGds()) && proto_commons_flow_common_pb.GDSBackend.toObject(includeInstance, f),
    abandonedStage: jspb.Message.getFieldWithDefault(msg, 8, 0),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetAbandonTripSearchRequest}
 */
proto.elude.proto.GetAbandonTripSearchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetAbandonTripSearchRequest;
  return proto.elude.proto.GetAbandonTripSearchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetAbandonTripSearchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetAbandonTripSearchRequest}
 */
proto.elude.proto.GetAbandonTripSearchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto.elude.proto.ItineraryRequest;
      reader.readMessage(value,proto.elude.proto.ItineraryRequest.deserializeBinaryFromReader);
      msg.setItineraryRequest(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlightId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHotelId(value);
      break;
    case 6:
      var value = new proto_commons_flow_common_pb.GDSBackend;
      reader.readMessage(value,proto_commons_flow_common_pb.GDSBackend.deserializeBinaryFromReader);
      msg.setFlightGds(value);
      break;
    case 7:
      var value = new proto_commons_flow_common_pb.GDSBackend;
      reader.readMessage(value,proto_commons_flow_common_pb.GDSBackend.deserializeBinaryFromReader);
      msg.setHotelGds(value);
      break;
    case 8:
      var value = /** @type {!proto.elude.proto.AbandonedStageEnum} */ (reader.readEnum());
      msg.setAbandonedStage(value);
      break;
    case 9:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetAbandonTripSearchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetAbandonTripSearchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetAbandonTripSearchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetAbandonTripSearchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getItineraryRequest();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.elude.proto.ItineraryRequest.serializeBinaryToWriter
    );
  }
  f = message.getFlightId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getHotelId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getFlightGds();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_commons_flow_common_pb.GDSBackend.serializeBinaryToWriter
    );
  }
  f = message.getHotelGds();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_commons_flow_common_pb.GDSBackend.serializeBinaryToWriter
    );
  }
  f = message.getAbandonedStage();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetAbandonTripSearchRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetAbandonTripSearchRequest} returns this
*/
proto.elude.proto.GetAbandonTripSearchRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAbandonTripSearchRequest} returns this
 */
proto.elude.proto.GetAbandonTripSearchRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAbandonTripSearchRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetAbandonTripSearchRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetAbandonTripSearchRequest} returns this
*/
proto.elude.proto.GetAbandonTripSearchRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAbandonTripSearchRequest} returns this
 */
proto.elude.proto.GetAbandonTripSearchRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAbandonTripSearchRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ItineraryRequest itinerary_request = 3;
 * @return {?proto.elude.proto.ItineraryRequest}
 */
proto.elude.proto.GetAbandonTripSearchRequest.prototype.getItineraryRequest = function() {
  return /** @type{?proto.elude.proto.ItineraryRequest} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.ItineraryRequest, 3));
};


/**
 * @param {?proto.elude.proto.ItineraryRequest|undefined} value
 * @return {!proto.elude.proto.GetAbandonTripSearchRequest} returns this
*/
proto.elude.proto.GetAbandonTripSearchRequest.prototype.setItineraryRequest = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAbandonTripSearchRequest} returns this
 */
proto.elude.proto.GetAbandonTripSearchRequest.prototype.clearItineraryRequest = function() {
  return this.setItineraryRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAbandonTripSearchRequest.prototype.hasItineraryRequest = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 flight_id = 4;
 * @return {number}
 */
proto.elude.proto.GetAbandonTripSearchRequest.prototype.getFlightId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetAbandonTripSearchRequest} returns this
 */
proto.elude.proto.GetAbandonTripSearchRequest.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 hotel_id = 5;
 * @return {number}
 */
proto.elude.proto.GetAbandonTripSearchRequest.prototype.getHotelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetAbandonTripSearchRequest} returns this
 */
proto.elude.proto.GetAbandonTripSearchRequest.prototype.setHotelId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional GDSBackend flight_gds = 6;
 * @return {?proto.elude.proto.GDSBackend}
 */
proto.elude.proto.GetAbandonTripSearchRequest.prototype.getFlightGds = function() {
  return /** @type{?proto.elude.proto.GDSBackend} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.GDSBackend, 6));
};


/**
 * @param {?proto.elude.proto.GDSBackend|undefined} value
 * @return {!proto.elude.proto.GetAbandonTripSearchRequest} returns this
*/
proto.elude.proto.GetAbandonTripSearchRequest.prototype.setFlightGds = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAbandonTripSearchRequest} returns this
 */
proto.elude.proto.GetAbandonTripSearchRequest.prototype.clearFlightGds = function() {
  return this.setFlightGds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAbandonTripSearchRequest.prototype.hasFlightGds = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional GDSBackend hotel_gds = 7;
 * @return {?proto.elude.proto.GDSBackend}
 */
proto.elude.proto.GetAbandonTripSearchRequest.prototype.getHotelGds = function() {
  return /** @type{?proto.elude.proto.GDSBackend} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.GDSBackend, 7));
};


/**
 * @param {?proto.elude.proto.GDSBackend|undefined} value
 * @return {!proto.elude.proto.GetAbandonTripSearchRequest} returns this
*/
proto.elude.proto.GetAbandonTripSearchRequest.prototype.setHotelGds = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAbandonTripSearchRequest} returns this
 */
proto.elude.proto.GetAbandonTripSearchRequest.prototype.clearHotelGds = function() {
  return this.setHotelGds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAbandonTripSearchRequest.prototype.hasHotelGds = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional AbandonedStageEnum abandoned_stage = 8;
 * @return {!proto.elude.proto.AbandonedStageEnum}
 */
proto.elude.proto.GetAbandonTripSearchRequest.prototype.getAbandonedStage = function() {
  return /** @type {!proto.elude.proto.AbandonedStageEnum} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.elude.proto.AbandonedStageEnum} value
 * @return {!proto.elude.proto.GetAbandonTripSearchRequest} returns this
 */
proto.elude.proto.GetAbandonTripSearchRequest.prototype.setAbandonedStage = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional Options options = 9;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetAbandonTripSearchRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 9));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetAbandonTripSearchRequest} returns this
*/
proto.elude.proto.GetAbandonTripSearchRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAbandonTripSearchRequest} returns this
 */
proto.elude.proto.GetAbandonTripSearchRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAbandonTripSearchRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.RevalidateItineraryRequest.repeatedFields_ = [8,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.RevalidateItineraryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.RevalidateItineraryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.RevalidateItineraryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.RevalidateItineraryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    hotelId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    roomId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    flightId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    hotelGds: (f = msg.getHotelGds()) && proto_commons_flow_common_pb.GDSBackend.toObject(includeInstance, f),
    flightGds: (f = msg.getFlightGds()) && proto_commons_flow_common_pb.GDSBackend.toObject(includeInstance, f),
    travellersList: jspb.Message.toObjectList(msg.getTravellersList(),
    proto_commons_flow_common_pb.GDSTraveller.toObject, includeInstance),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    travellerIdsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.RevalidateItineraryRequest}
 */
proto.elude.proto.RevalidateItineraryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.RevalidateItineraryRequest;
  return proto.elude.proto.RevalidateItineraryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.RevalidateItineraryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.RevalidateItineraryRequest}
 */
proto.elude.proto.RevalidateItineraryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHotelId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRoomId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlightId(value);
      break;
    case 6:
      var value = new proto_commons_flow_common_pb.GDSBackend;
      reader.readMessage(value,proto_commons_flow_common_pb.GDSBackend.deserializeBinaryFromReader);
      msg.setHotelGds(value);
      break;
    case 7:
      var value = new proto_commons_flow_common_pb.GDSBackend;
      reader.readMessage(value,proto_commons_flow_common_pb.GDSBackend.deserializeBinaryFromReader);
      msg.setFlightGds(value);
      break;
    case 8:
      var value = new proto_commons_flow_common_pb.GDSTraveller;
      reader.readMessage(value,proto_commons_flow_common_pb.GDSTraveller.deserializeBinaryFromReader);
      msg.addTravellers(value);
      break;
    case 9:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 10:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTravellerIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.RevalidateItineraryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.RevalidateItineraryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.RevalidateItineraryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.RevalidateItineraryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getHotelId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getRoomId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getFlightId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getHotelGds();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_commons_flow_common_pb.GDSBackend.serializeBinaryToWriter
    );
  }
  f = message.getFlightGds();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_commons_flow_common_pb.GDSBackend.serializeBinaryToWriter
    );
  }
  f = message.getTravellersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto_commons_flow_common_pb.GDSTraveller.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getTravellerIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      10,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.RevalidateItineraryRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.RevalidateItineraryRequest} returns this
*/
proto.elude.proto.RevalidateItineraryRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RevalidateItineraryRequest} returns this
 */
proto.elude.proto.RevalidateItineraryRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RevalidateItineraryRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Options options = 2;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.RevalidateItineraryRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 2));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.RevalidateItineraryRequest} returns this
*/
proto.elude.proto.RevalidateItineraryRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RevalidateItineraryRequest} returns this
 */
proto.elude.proto.RevalidateItineraryRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RevalidateItineraryRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 hotel_id = 3;
 * @return {number}
 */
proto.elude.proto.RevalidateItineraryRequest.prototype.getHotelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.RevalidateItineraryRequest} returns this
 */
proto.elude.proto.RevalidateItineraryRequest.prototype.setHotelId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 room_id = 4;
 * @return {number}
 */
proto.elude.proto.RevalidateItineraryRequest.prototype.getRoomId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.RevalidateItineraryRequest} returns this
 */
proto.elude.proto.RevalidateItineraryRequest.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 flight_id = 5;
 * @return {number}
 */
proto.elude.proto.RevalidateItineraryRequest.prototype.getFlightId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.RevalidateItineraryRequest} returns this
 */
proto.elude.proto.RevalidateItineraryRequest.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional GDSBackend hotel_gds = 6;
 * @return {?proto.elude.proto.GDSBackend}
 */
proto.elude.proto.RevalidateItineraryRequest.prototype.getHotelGds = function() {
  return /** @type{?proto.elude.proto.GDSBackend} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.GDSBackend, 6));
};


/**
 * @param {?proto.elude.proto.GDSBackend|undefined} value
 * @return {!proto.elude.proto.RevalidateItineraryRequest} returns this
*/
proto.elude.proto.RevalidateItineraryRequest.prototype.setHotelGds = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RevalidateItineraryRequest} returns this
 */
proto.elude.proto.RevalidateItineraryRequest.prototype.clearHotelGds = function() {
  return this.setHotelGds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RevalidateItineraryRequest.prototype.hasHotelGds = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional GDSBackend flight_gds = 7;
 * @return {?proto.elude.proto.GDSBackend}
 */
proto.elude.proto.RevalidateItineraryRequest.prototype.getFlightGds = function() {
  return /** @type{?proto.elude.proto.GDSBackend} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.GDSBackend, 7));
};


/**
 * @param {?proto.elude.proto.GDSBackend|undefined} value
 * @return {!proto.elude.proto.RevalidateItineraryRequest} returns this
*/
proto.elude.proto.RevalidateItineraryRequest.prototype.setFlightGds = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RevalidateItineraryRequest} returns this
 */
proto.elude.proto.RevalidateItineraryRequest.prototype.clearFlightGds = function() {
  return this.setFlightGds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RevalidateItineraryRequest.prototype.hasFlightGds = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated GDSTraveller travellers = 8;
 * @return {!Array<!proto.elude.proto.GDSTraveller>}
 */
proto.elude.proto.RevalidateItineraryRequest.prototype.getTravellersList = function() {
  return /** @type{!Array<!proto.elude.proto.GDSTraveller>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_commons_flow_common_pb.GDSTraveller, 8));
};


/**
 * @param {!Array<!proto.elude.proto.GDSTraveller>} value
 * @return {!proto.elude.proto.RevalidateItineraryRequest} returns this
*/
proto.elude.proto.RevalidateItineraryRequest.prototype.setTravellersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.elude.proto.GDSTraveller=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.GDSTraveller}
 */
proto.elude.proto.RevalidateItineraryRequest.prototype.addTravellers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.elude.proto.GDSTraveller, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.RevalidateItineraryRequest} returns this
 */
proto.elude.proto.RevalidateItineraryRequest.prototype.clearTravellersList = function() {
  return this.setTravellersList([]);
};


/**
 * optional SessionInfo session_info = 9;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.RevalidateItineraryRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 9));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.RevalidateItineraryRequest} returns this
*/
proto.elude.proto.RevalidateItineraryRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RevalidateItineraryRequest} returns this
 */
proto.elude.proto.RevalidateItineraryRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RevalidateItineraryRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated int32 traveller_ids = 10;
 * @return {!Array<number>}
 */
proto.elude.proto.RevalidateItineraryRequest.prototype.getTravellerIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.elude.proto.RevalidateItineraryRequest} returns this
 */
proto.elude.proto.RevalidateItineraryRequest.prototype.setTravellerIdsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.RevalidateItineraryRequest} returns this
 */
proto.elude.proto.RevalidateItineraryRequest.prototype.addTravellerIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.RevalidateItineraryRequest} returns this
 */
proto.elude.proto.RevalidateItineraryRequest.prototype.clearTravellerIdsList = function() {
  return this.setTravellerIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.RetrieveItineraryPriceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.RetrieveItineraryPriceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.RetrieveItineraryPriceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.RetrieveItineraryPriceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    hotelId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    roomId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    flightId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    hotelGds: (f = msg.getHotelGds()) && proto_commons_flow_common_pb.GDSBackend.toObject(includeInstance, f),
    flightGds: (f = msg.getFlightGds()) && proto_commons_flow_common_pb.GDSBackend.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.RetrieveItineraryPriceRequest}
 */
proto.elude.proto.RetrieveItineraryPriceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.RetrieveItineraryPriceRequest;
  return proto.elude.proto.RetrieveItineraryPriceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.RetrieveItineraryPriceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.RetrieveItineraryPriceRequest}
 */
proto.elude.proto.RetrieveItineraryPriceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHotelId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRoomId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlightId(value);
      break;
    case 6:
      var value = new proto_commons_flow_common_pb.GDSBackend;
      reader.readMessage(value,proto_commons_flow_common_pb.GDSBackend.deserializeBinaryFromReader);
      msg.setHotelGds(value);
      break;
    case 7:
      var value = new proto_commons_flow_common_pb.GDSBackend;
      reader.readMessage(value,proto_commons_flow_common_pb.GDSBackend.deserializeBinaryFromReader);
      msg.setFlightGds(value);
      break;
    case 8:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.RetrieveItineraryPriceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.RetrieveItineraryPriceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.RetrieveItineraryPriceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.RetrieveItineraryPriceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getHotelId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getRoomId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getFlightId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getHotelGds();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_commons_flow_common_pb.GDSBackend.serializeBinaryToWriter
    );
  }
  f = message.getFlightGds();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_commons_flow_common_pb.GDSBackend.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.RetrieveItineraryPriceRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.RetrieveItineraryPriceRequest} returns this
*/
proto.elude.proto.RetrieveItineraryPriceRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RetrieveItineraryPriceRequest} returns this
 */
proto.elude.proto.RetrieveItineraryPriceRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RetrieveItineraryPriceRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Options options = 2;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.RetrieveItineraryPriceRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 2));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.RetrieveItineraryPriceRequest} returns this
*/
proto.elude.proto.RetrieveItineraryPriceRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RetrieveItineraryPriceRequest} returns this
 */
proto.elude.proto.RetrieveItineraryPriceRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RetrieveItineraryPriceRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 hotel_id = 3;
 * @return {number}
 */
proto.elude.proto.RetrieveItineraryPriceRequest.prototype.getHotelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.RetrieveItineraryPriceRequest} returns this
 */
proto.elude.proto.RetrieveItineraryPriceRequest.prototype.setHotelId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 room_id = 4;
 * @return {number}
 */
proto.elude.proto.RetrieveItineraryPriceRequest.prototype.getRoomId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.RetrieveItineraryPriceRequest} returns this
 */
proto.elude.proto.RetrieveItineraryPriceRequest.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 flight_id = 5;
 * @return {number}
 */
proto.elude.proto.RetrieveItineraryPriceRequest.prototype.getFlightId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.RetrieveItineraryPriceRequest} returns this
 */
proto.elude.proto.RetrieveItineraryPriceRequest.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional GDSBackend hotel_gds = 6;
 * @return {?proto.elude.proto.GDSBackend}
 */
proto.elude.proto.RetrieveItineraryPriceRequest.prototype.getHotelGds = function() {
  return /** @type{?proto.elude.proto.GDSBackend} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.GDSBackend, 6));
};


/**
 * @param {?proto.elude.proto.GDSBackend|undefined} value
 * @return {!proto.elude.proto.RetrieveItineraryPriceRequest} returns this
*/
proto.elude.proto.RetrieveItineraryPriceRequest.prototype.setHotelGds = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RetrieveItineraryPriceRequest} returns this
 */
proto.elude.proto.RetrieveItineraryPriceRequest.prototype.clearHotelGds = function() {
  return this.setHotelGds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RetrieveItineraryPriceRequest.prototype.hasHotelGds = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional GDSBackend flight_gds = 7;
 * @return {?proto.elude.proto.GDSBackend}
 */
proto.elude.proto.RetrieveItineraryPriceRequest.prototype.getFlightGds = function() {
  return /** @type{?proto.elude.proto.GDSBackend} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.GDSBackend, 7));
};


/**
 * @param {?proto.elude.proto.GDSBackend|undefined} value
 * @return {!proto.elude.proto.RetrieveItineraryPriceRequest} returns this
*/
proto.elude.proto.RetrieveItineraryPriceRequest.prototype.setFlightGds = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RetrieveItineraryPriceRequest} returns this
 */
proto.elude.proto.RetrieveItineraryPriceRequest.prototype.clearFlightGds = function() {
  return this.setFlightGds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RetrieveItineraryPriceRequest.prototype.hasFlightGds = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional SessionInfo session_info = 8;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.RetrieveItineraryPriceRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 8));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.RetrieveItineraryPriceRequest} returns this
*/
proto.elude.proto.RetrieveItineraryPriceRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RetrieveItineraryPriceRequest} returns this
 */
proto.elude.proto.RetrieveItineraryPriceRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RetrieveItineraryPriceRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.HotelDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.HotelDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.HotelDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.HotelDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    gds: (f = msg.getGds()) && proto_commons_flow_common_pb.GDSBackend.toObject(includeInstance, f),
    hotelId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.HotelDetailsRequest}
 */
proto.elude.proto.HotelDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.HotelDetailsRequest;
  return proto.elude.proto.HotelDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.HotelDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.HotelDetailsRequest}
 */
proto.elude.proto.HotelDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_flow_common_pb.GDSBackend;
      reader.readMessage(value,proto_commons_flow_common_pb.GDSBackend.deserializeBinaryFromReader);
      msg.setGds(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHotelId(value);
      break;
    case 5:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.HotelDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.HotelDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.HotelDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.HotelDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getGds();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_flow_common_pb.GDSBackend.serializeBinaryToWriter
    );
  }
  f = message.getHotelId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.HotelDetailsRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.HotelDetailsRequest} returns this
*/
proto.elude.proto.HotelDetailsRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.HotelDetailsRequest} returns this
 */
proto.elude.proto.HotelDetailsRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.HotelDetailsRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.HotelDetailsRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.HotelDetailsRequest} returns this
*/
proto.elude.proto.HotelDetailsRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.HotelDetailsRequest} returns this
 */
proto.elude.proto.HotelDetailsRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.HotelDetailsRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional GDSBackend gds = 3;
 * @return {?proto.elude.proto.GDSBackend}
 */
proto.elude.proto.HotelDetailsRequest.prototype.getGds = function() {
  return /** @type{?proto.elude.proto.GDSBackend} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.GDSBackend, 3));
};


/**
 * @param {?proto.elude.proto.GDSBackend|undefined} value
 * @return {!proto.elude.proto.HotelDetailsRequest} returns this
*/
proto.elude.proto.HotelDetailsRequest.prototype.setGds = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.HotelDetailsRequest} returns this
 */
proto.elude.proto.HotelDetailsRequest.prototype.clearGds = function() {
  return this.setGds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.HotelDetailsRequest.prototype.hasGds = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 hotel_id = 4;
 * @return {number}
 */
proto.elude.proto.HotelDetailsRequest.prototype.getHotelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.HotelDetailsRequest} returns this
 */
proto.elude.proto.HotelDetailsRequest.prototype.setHotelId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional Options options = 5;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.HotelDetailsRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 5));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.HotelDetailsRequest} returns this
*/
proto.elude.proto.HotelDetailsRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.HotelDetailsRequest} returns this
 */
proto.elude.proto.HotelDetailsRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.HotelDetailsRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.AbandonTripRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.AbandonTripRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.AbandonTripRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.AbandonTripRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    dates: (f = msg.getDates()) && proto_commons_flow_common_pb.Dates.toObject(includeInstance, f),
    travellers: (f = msg.getTravellers()) && proto_commons_flow_common_pb.Travellers.toObject(includeInstance, f),
    budget: jspb.Message.getFieldWithDefault(msg, 7, 0),
    flightId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    hotelId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    roomId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    abandonedStage: jspb.Message.getFieldWithDefault(msg, 11, 0),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    airRequirements: (f = msg.getAirRequirements()) && proto_commons_flow_common_pb.AirRequirements.toObject(includeInstance, f),
    hotelRequirements: (f = msg.getHotelRequirements()) && proto_commons_flow_common_pb.HotelRequirements.toObject(includeInstance, f),
    locationsRequirements: (f = msg.getLocationsRequirements()) && proto_commons_flow_common_pb.LocationsRequirements.toObject(includeInstance, f),
    budgetTier: jspb.Message.getFieldWithDefault(msg, 16, 0),
    searchType: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.AbandonTripRequest}
 */
proto.elude.proto.AbandonTripRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.AbandonTripRequest;
  return proto.elude.proto.AbandonTripRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.AbandonTripRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.AbandonTripRequest}
 */
proto.elude.proto.AbandonTripRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 5:
      var value = new proto_commons_flow_common_pb.Dates;
      reader.readMessage(value,proto_commons_flow_common_pb.Dates.deserializeBinaryFromReader);
      msg.setDates(value);
      break;
    case 6:
      var value = new proto_commons_flow_common_pb.Travellers;
      reader.readMessage(value,proto_commons_flow_common_pb.Travellers.deserializeBinaryFromReader);
      msg.setTravellers(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBudget(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlightId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHotelId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRoomId(value);
      break;
    case 11:
      var value = /** @type {!proto.elude.proto.AbandonedStageEnum} */ (reader.readEnum());
      msg.setAbandonedStage(value);
      break;
    case 12:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 13:
      var value = new proto_commons_flow_common_pb.AirRequirements;
      reader.readMessage(value,proto_commons_flow_common_pb.AirRequirements.deserializeBinaryFromReader);
      msg.setAirRequirements(value);
      break;
    case 14:
      var value = new proto_commons_flow_common_pb.HotelRequirements;
      reader.readMessage(value,proto_commons_flow_common_pb.HotelRequirements.deserializeBinaryFromReader);
      msg.setHotelRequirements(value);
      break;
    case 15:
      var value = new proto_commons_flow_common_pb.LocationsRequirements;
      reader.readMessage(value,proto_commons_flow_common_pb.LocationsRequirements.deserializeBinaryFromReader);
      msg.setLocationsRequirements(value);
      break;
    case 16:
      var value = /** @type {!proto.elude.proto.BudgetTierEnum} */ (reader.readEnum());
      msg.setBudgetTier(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.AbandonTripRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.AbandonTripRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.AbandonTripRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.AbandonTripRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getDates();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_commons_flow_common_pb.Dates.serializeBinaryToWriter
    );
  }
  f = message.getTravellers();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_commons_flow_common_pb.Travellers.serializeBinaryToWriter
    );
  }
  f = message.getBudget();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getFlightId();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getHotelId();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getRoomId();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getAbandonedStage();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getAirRequirements();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto_commons_flow_common_pb.AirRequirements.serializeBinaryToWriter
    );
  }
  f = message.getHotelRequirements();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto_commons_flow_common_pb.HotelRequirements.serializeBinaryToWriter
    );
  }
  f = message.getLocationsRequirements();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto_commons_flow_common_pb.LocationsRequirements.serializeBinaryToWriter
    );
  }
  f = message.getBudgetTier();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getSearchType();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.AbandonTripRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.AbandonTripRequest} returns this
*/
proto.elude.proto.AbandonTripRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.AbandonTripRequest} returns this
 */
proto.elude.proto.AbandonTripRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.AbandonTripRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.AbandonTripRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.AbandonTripRequest} returns this
*/
proto.elude.proto.AbandonTripRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.AbandonTripRequest} returns this
 */
proto.elude.proto.AbandonTripRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.AbandonTripRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Dates dates = 5;
 * @return {?proto.elude.proto.Dates}
 */
proto.elude.proto.AbandonTripRequest.prototype.getDates = function() {
  return /** @type{?proto.elude.proto.Dates} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.Dates, 5));
};


/**
 * @param {?proto.elude.proto.Dates|undefined} value
 * @return {!proto.elude.proto.AbandonTripRequest} returns this
*/
proto.elude.proto.AbandonTripRequest.prototype.setDates = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.AbandonTripRequest} returns this
 */
proto.elude.proto.AbandonTripRequest.prototype.clearDates = function() {
  return this.setDates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.AbandonTripRequest.prototype.hasDates = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Travellers travellers = 6;
 * @return {?proto.elude.proto.Travellers}
 */
proto.elude.proto.AbandonTripRequest.prototype.getTravellers = function() {
  return /** @type{?proto.elude.proto.Travellers} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.Travellers, 6));
};


/**
 * @param {?proto.elude.proto.Travellers|undefined} value
 * @return {!proto.elude.proto.AbandonTripRequest} returns this
*/
proto.elude.proto.AbandonTripRequest.prototype.setTravellers = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.AbandonTripRequest} returns this
 */
proto.elude.proto.AbandonTripRequest.prototype.clearTravellers = function() {
  return this.setTravellers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.AbandonTripRequest.prototype.hasTravellers = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 budget = 7;
 * @return {number}
 */
proto.elude.proto.AbandonTripRequest.prototype.getBudget = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.AbandonTripRequest} returns this
 */
proto.elude.proto.AbandonTripRequest.prototype.setBudget = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 flight_id = 8;
 * @return {number}
 */
proto.elude.proto.AbandonTripRequest.prototype.getFlightId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.AbandonTripRequest} returns this
 */
proto.elude.proto.AbandonTripRequest.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 hotel_id = 9;
 * @return {number}
 */
proto.elude.proto.AbandonTripRequest.prototype.getHotelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.AbandonTripRequest} returns this
 */
proto.elude.proto.AbandonTripRequest.prototype.setHotelId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 room_id = 10;
 * @return {number}
 */
proto.elude.proto.AbandonTripRequest.prototype.getRoomId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.AbandonTripRequest} returns this
 */
proto.elude.proto.AbandonTripRequest.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional AbandonedStageEnum abandoned_stage = 11;
 * @return {!proto.elude.proto.AbandonedStageEnum}
 */
proto.elude.proto.AbandonTripRequest.prototype.getAbandonedStage = function() {
  return /** @type {!proto.elude.proto.AbandonedStageEnum} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.elude.proto.AbandonedStageEnum} value
 * @return {!proto.elude.proto.AbandonTripRequest} returns this
 */
proto.elude.proto.AbandonTripRequest.prototype.setAbandonedStage = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional Options options = 12;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.AbandonTripRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 12));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.AbandonTripRequest} returns this
*/
proto.elude.proto.AbandonTripRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.AbandonTripRequest} returns this
 */
proto.elude.proto.AbandonTripRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.AbandonTripRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional AirRequirements air_requirements = 13;
 * @return {?proto.elude.proto.AirRequirements}
 */
proto.elude.proto.AbandonTripRequest.prototype.getAirRequirements = function() {
  return /** @type{?proto.elude.proto.AirRequirements} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.AirRequirements, 13));
};


/**
 * @param {?proto.elude.proto.AirRequirements|undefined} value
 * @return {!proto.elude.proto.AbandonTripRequest} returns this
*/
proto.elude.proto.AbandonTripRequest.prototype.setAirRequirements = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.AbandonTripRequest} returns this
 */
proto.elude.proto.AbandonTripRequest.prototype.clearAirRequirements = function() {
  return this.setAirRequirements(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.AbandonTripRequest.prototype.hasAirRequirements = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional HotelRequirements hotel_requirements = 14;
 * @return {?proto.elude.proto.HotelRequirements}
 */
proto.elude.proto.AbandonTripRequest.prototype.getHotelRequirements = function() {
  return /** @type{?proto.elude.proto.HotelRequirements} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.HotelRequirements, 14));
};


/**
 * @param {?proto.elude.proto.HotelRequirements|undefined} value
 * @return {!proto.elude.proto.AbandonTripRequest} returns this
*/
proto.elude.proto.AbandonTripRequest.prototype.setHotelRequirements = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.AbandonTripRequest} returns this
 */
proto.elude.proto.AbandonTripRequest.prototype.clearHotelRequirements = function() {
  return this.setHotelRequirements(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.AbandonTripRequest.prototype.hasHotelRequirements = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional LocationsRequirements locations_requirements = 15;
 * @return {?proto.elude.proto.LocationsRequirements}
 */
proto.elude.proto.AbandonTripRequest.prototype.getLocationsRequirements = function() {
  return /** @type{?proto.elude.proto.LocationsRequirements} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.LocationsRequirements, 15));
};


/**
 * @param {?proto.elude.proto.LocationsRequirements|undefined} value
 * @return {!proto.elude.proto.AbandonTripRequest} returns this
*/
proto.elude.proto.AbandonTripRequest.prototype.setLocationsRequirements = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.AbandonTripRequest} returns this
 */
proto.elude.proto.AbandonTripRequest.prototype.clearLocationsRequirements = function() {
  return this.setLocationsRequirements(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.AbandonTripRequest.prototype.hasLocationsRequirements = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional BudgetTierEnum budget_tier = 16;
 * @return {!proto.elude.proto.BudgetTierEnum}
 */
proto.elude.proto.AbandonTripRequest.prototype.getBudgetTier = function() {
  return /** @type {!proto.elude.proto.BudgetTierEnum} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.elude.proto.BudgetTierEnum} value
 * @return {!proto.elude.proto.AbandonTripRequest} returns this
 */
proto.elude.proto.AbandonTripRequest.prototype.setBudgetTier = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional string search_type = 17;
 * @return {string}
 */
proto.elude.proto.AbandonTripRequest.prototype.getSearchType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.AbandonTripRequest} returns this
 */
proto.elude.proto.AbandonTripRequest.prototype.setSearchType = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.BuildHotelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.BuildHotelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.BuildHotelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.BuildHotelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    hotel: (f = msg.getHotel()) && proto_commons_flow_common_pb.GDSHotel.toObject(includeInstance, f),
    travellers: (f = msg.getTravellers()) && proto_commons_flow_common_pb.Travellers.toObject(includeInstance, f),
    budget: (f = msg.getBudget()) && proto_commons_flow_common_pb.Budget.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.BuildHotelRequest}
 */
proto.elude.proto.BuildHotelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.BuildHotelRequest;
  return proto.elude.proto.BuildHotelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.BuildHotelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.BuildHotelRequest}
 */
proto.elude.proto.BuildHotelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_commons_flow_common_pb.GDSHotel;
      reader.readMessage(value,proto_commons_flow_common_pb.GDSHotel.deserializeBinaryFromReader);
      msg.setHotel(value);
      break;
    case 3:
      var value = new proto_commons_flow_common_pb.Travellers;
      reader.readMessage(value,proto_commons_flow_common_pb.Travellers.deserializeBinaryFromReader);
      msg.setTravellers(value);
      break;
    case 4:
      var value = new proto_commons_flow_common_pb.Budget;
      reader.readMessage(value,proto_commons_flow_common_pb.Budget.deserializeBinaryFromReader);
      msg.setBudget(value);
      break;
    case 5:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 6:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.BuildHotelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.BuildHotelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.BuildHotelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.BuildHotelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getHotel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_flow_common_pb.GDSHotel.serializeBinaryToWriter
    );
  }
  f = message.getTravellers();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_flow_common_pb.Travellers.serializeBinaryToWriter
    );
  }
  f = message.getBudget();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_flow_common_pb.Budget.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.BuildHotelRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.BuildHotelRequest} returns this
*/
proto.elude.proto.BuildHotelRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.BuildHotelRequest} returns this
 */
proto.elude.proto.BuildHotelRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.BuildHotelRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GDSHotel hotel = 2;
 * @return {?proto.elude.proto.GDSHotel}
 */
proto.elude.proto.BuildHotelRequest.prototype.getHotel = function() {
  return /** @type{?proto.elude.proto.GDSHotel} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.GDSHotel, 2));
};


/**
 * @param {?proto.elude.proto.GDSHotel|undefined} value
 * @return {!proto.elude.proto.BuildHotelRequest} returns this
*/
proto.elude.proto.BuildHotelRequest.prototype.setHotel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.BuildHotelRequest} returns this
 */
proto.elude.proto.BuildHotelRequest.prototype.clearHotel = function() {
  return this.setHotel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.BuildHotelRequest.prototype.hasHotel = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Travellers travellers = 3;
 * @return {?proto.elude.proto.Travellers}
 */
proto.elude.proto.BuildHotelRequest.prototype.getTravellers = function() {
  return /** @type{?proto.elude.proto.Travellers} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.Travellers, 3));
};


/**
 * @param {?proto.elude.proto.Travellers|undefined} value
 * @return {!proto.elude.proto.BuildHotelRequest} returns this
*/
proto.elude.proto.BuildHotelRequest.prototype.setTravellers = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.BuildHotelRequest} returns this
 */
proto.elude.proto.BuildHotelRequest.prototype.clearTravellers = function() {
  return this.setTravellers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.BuildHotelRequest.prototype.hasTravellers = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Budget budget = 4;
 * @return {?proto.elude.proto.Budget}
 */
proto.elude.proto.BuildHotelRequest.prototype.getBudget = function() {
  return /** @type{?proto.elude.proto.Budget} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.Budget, 4));
};


/**
 * @param {?proto.elude.proto.Budget|undefined} value
 * @return {!proto.elude.proto.BuildHotelRequest} returns this
*/
proto.elude.proto.BuildHotelRequest.prototype.setBudget = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.BuildHotelRequest} returns this
 */
proto.elude.proto.BuildHotelRequest.prototype.clearBudget = function() {
  return this.setBudget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.BuildHotelRequest.prototype.hasBudget = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Status status = 5;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.BuildHotelRequest.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 5));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.BuildHotelRequest} returns this
*/
proto.elude.proto.BuildHotelRequest.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.BuildHotelRequest} returns this
 */
proto.elude.proto.BuildHotelRequest.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.BuildHotelRequest.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Options options = 6;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.BuildHotelRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 6));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.BuildHotelRequest} returns this
*/
proto.elude.proto.BuildHotelRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.BuildHotelRequest} returns this
 */
proto.elude.proto.BuildHotelRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.BuildHotelRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.SetSearchCacheRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.SetSearchCacheRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.SetSearchCacheRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.SetSearchCacheRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    flightGds: (f = msg.getFlightGds()) && proto_commons_flow_common_pb.GDSBackend.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.SetSearchCacheRequest}
 */
proto.elude.proto.SetSearchCacheRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.SetSearchCacheRequest;
  return proto.elude.proto.SetSearchCacheRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.SetSearchCacheRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.SetSearchCacheRequest}
 */
proto.elude.proto.SetSearchCacheRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_commons_flow_common_pb.GDSBackend;
      reader.readMessage(value,proto_commons_flow_common_pb.GDSBackend.deserializeBinaryFromReader);
      msg.setFlightGds(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.SetSearchCacheRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.SetSearchCacheRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.SetSearchCacheRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.SetSearchCacheRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getFlightGds();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_flow_common_pb.GDSBackend.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.SetSearchCacheRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.SetSearchCacheRequest} returns this
*/
proto.elude.proto.SetSearchCacheRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.SetSearchCacheRequest} returns this
 */
proto.elude.proto.SetSearchCacheRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.SetSearchCacheRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GDSBackend flight_gds = 2;
 * @return {?proto.elude.proto.GDSBackend}
 */
proto.elude.proto.SetSearchCacheRequest.prototype.getFlightGds = function() {
  return /** @type{?proto.elude.proto.GDSBackend} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.GDSBackend, 2));
};


/**
 * @param {?proto.elude.proto.GDSBackend|undefined} value
 * @return {!proto.elude.proto.SetSearchCacheRequest} returns this
*/
proto.elude.proto.SetSearchCacheRequest.prototype.setFlightGds = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.SetSearchCacheRequest} returns this
 */
proto.elude.proto.SetSearchCacheRequest.prototype.clearFlightGds = function() {
  return this.setFlightGds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.SetSearchCacheRequest.prototype.hasFlightGds = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.SetSearchCacheRequest.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.SetSearchCacheRequest} returns this
*/
proto.elude.proto.SetSearchCacheRequest.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.SetSearchCacheRequest} returns this
 */
proto.elude.proto.SetSearchCacheRequest.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.SetSearchCacheRequest.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.SetSearchCacheRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.SetSearchCacheRequest} returns this
*/
proto.elude.proto.SetSearchCacheRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.SetSearchCacheRequest} returns this
 */
proto.elude.proto.SetSearchCacheRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.SetSearchCacheRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.SearchReturnFlightsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.SearchReturnFlightsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.SearchReturnFlightsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.SearchReturnFlightsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    flightId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.SearchReturnFlightsRequest}
 */
proto.elude.proto.SearchReturnFlightsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.SearchReturnFlightsRequest;
  return proto.elude.proto.SearchReturnFlightsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.SearchReturnFlightsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.SearchReturnFlightsRequest}
 */
proto.elude.proto.SearchReturnFlightsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlightId(value);
      break;
    case 4:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.SearchReturnFlightsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.SearchReturnFlightsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.SearchReturnFlightsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.SearchReturnFlightsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getFlightId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.SearchReturnFlightsRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.SearchReturnFlightsRequest} returns this
*/
proto.elude.proto.SearchReturnFlightsRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.SearchReturnFlightsRequest} returns this
 */
proto.elude.proto.SearchReturnFlightsRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.SearchReturnFlightsRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Options options = 2;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.SearchReturnFlightsRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 2));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.SearchReturnFlightsRequest} returns this
*/
proto.elude.proto.SearchReturnFlightsRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.SearchReturnFlightsRequest} returns this
 */
proto.elude.proto.SearchReturnFlightsRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.SearchReturnFlightsRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 flight_id = 3;
 * @return {number}
 */
proto.elude.proto.SearchReturnFlightsRequest.prototype.getFlightId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.SearchReturnFlightsRequest} returns this
 */
proto.elude.proto.SearchReturnFlightsRequest.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional SessionInfo session_info = 4;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.SearchReturnFlightsRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 4));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.SearchReturnFlightsRequest} returns this
*/
proto.elude.proto.SearchReturnFlightsRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.SearchReturnFlightsRequest} returns this
 */
proto.elude.proto.SearchReturnFlightsRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.SearchReturnFlightsRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 4) != null;
};


goog.object.extend(exports, proto.elude.proto);
